import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { MessagesService } from 'src/app/shared/services/messages.service';

@Component({
  selector: 'app-saved-responses-dialog',
  templateUrl: './saved-responses-dialog.component.html',
  styleUrls: ['./saved-responses-dialog.component.scss']
})
export class SavedResponsesDialogComponent implements OnInit {

 
  searchTerm: any = '';
  msgTemplates: any = [];
  selectedTemplate: any = {};
  subscription: Subscription = new Subscription();

  isList: boolean = true;
  isPreview: boolean = false;
  isMostUsed: boolean = false;
  isEditTemp: boolean = false;
  
  constructor(
    private _WhatsService: WhatsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _MessagesService: MessagesService,
    public _MatDialogRef: MatDialogRef<SavedResponsesDialogComponent>,
  ) { }


  ngOnInit(): void {
    this.getTemplates();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  updateMessage(id ,payload): void {
     
  }
 

  errorHandller(error: HttpErrorResponse): void {

  }


  addNewResponse(): void {
    this.isList = false;
    this.isEditTemp = false;
    this.isPreview = false;
  }


  preview(template: any): void {
    this.isPreview = true;
    this.selectedTemplate = template;
  }



  sortBy(): void {
    this.isMostUsed = !this.isMostUsed
  }


  onSearchChange(e): void {
    this.searchTerm = e;
    this.getTemplates();
  }
  

  getTemplates() {
    this._WhatsService.getMessageTemplate({
      search_word: this.searchTerm,
    }).subscribe(res => {
      this.msgTemplates = res?.items?.data;
      
    })
  }


  
  confirmDeleteTemp(temp: any): void {
    this._MessagesService.showConfirmDeleteMessage().then( res => {
      
      if (res?.isConfirmed) {
        this.deleteTemp(temp);
      }
    })
  }


  deleteTemp(temp: any): void {
    this.subscription.add(
      this._WhatsService.deleteTemplate(temp.id).subscribe(res => {
        this.getTemplates();
      })
    )
  }

  afterAddNewTemplate(): void {
    this.isList = true;
    this.getTemplates();
  }


  onBack(): void {
    
    this.isList = true;
    this.isPreview = false;
  }

  editTemp(template: any): void {
    this.isList = false;
    this.isEditTemp = true;
    this.selectedTemplate = template;
  }

}
