export enum Tokens {
  GOOGLESHEET = "جوجل شيت",
  WORDPRESS = "ووردبريس",
  SALLA = "سلة",
  HOST = "هوست",
  BOOt = "بوت",
}

export enum StorageTokens {
  SALLATOKEN = "sallaToken",
  GOOGLESHEET = "googleSheetToken",
  WORDPRESS = "wordpressToken",
  GOOGLESHEETACCESS = "googleSheetAccessToken",
  HOSTINGTOKEN = "hostingAccessToken",
}
