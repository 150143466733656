import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class UserService {
  baseURL: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  /**
   * Store File To Whats Gallery
   * @returns
   */
  setTelegramUser(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-telegram-username`
    return this._http.patch(url, body)
  }

  /**
   * Create Api Token
   * @returns
   */
  addApiToken(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens`
    return this._http.post(url, { ...body })
  }

  getDashboardStatistics(): Observable<any> {
    const url = `${this.baseURL}/account/profile/dashboard`
    return this._http.get(url)
  }

  /**
   * Update Api Token
   * @returns
   */
  updateApiToken(body, id): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens/${id}/update`
    return this._http.patch(url, body)
  }

  /**
   * Get Api Token
   * @returns
   */
  getApiTokens(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens`;
    return this._http.patch(url,body);
  }

  /**
   * Delete Api Token
   * @returns
   */
  deleteApiToken(id): Observable<any> {
    const url = `${this.baseURL}/account/profile/tokens/${id}`
    return this._http.delete(url)
  }

  setIdNumber(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-id-number`
    return this._http.patch(url, body)
  }

  setAllowsIps(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/set-allowed-ips`
    return this._http.post(url, body)
  }

  acceptTermsAndConditions(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/accept-agreements`
    return this._http.post(url, body)
  }

  // User Invoice
  /**
   * Get user Invoice data
   * @returns
   */
  getUserInvoiceData(params?): Observable<any> {
    const url = `${this.baseURL}/account/profile/invoice_data`
    return this._http.get(url, {
      params,
    })
  }

  /**
   * Update user Invoice data
   * @returns
   */
  setUserInvoiceData(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/invoice_data`
    return this._http.patch(url, body)
  }

  // Update User Data

  changeUserData(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data`
    return this._http.post(url, body)
  }

  changeUserName(body): Observable<any> {
    const url = `${this.baseURL}/account/profile/update-name`
    return this._http.post(url, body)
  }

  verifyUserData(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/verify`
    return this._http.post(url, body)
  }

  rejectUserDataChangeRequest(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/reject`
    return this._http.post(url, body)
  }

  resendMailVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify`
    return this._http.post(url, body)
  }

  resendPhoneVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify-user-sms`
    return this._http.post(url, body)
  }

  resendWhatsVerification(body): Observable<any> {
    const url = `${this.baseURL}/account-change-data/resend-verify-user-whatsapp`
    return this._http.post(url, body)
  }

  getWhatsappTokens(params?): Observable<any> {
    const url = `${this.baseURL}/account/whatsapp/numbers/list-tokens`
    return this._http.get(url, {
      params,
    })
  }

  getUserDevicesList() {
    const url = `${this.baseURL}/account/devices`
    return this._http.get(url);
  }

  deleteUserDevice(key) {
    const url = `${this.baseURL}/account/devices/${key}`
    return this._http.delete(url);
  }
}