<div class="example-container ag-grid-container" [ngClass]="{'global-table': GlobalTable,'details' : masterDetail}">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row justify-content-start align-items-center w-100" style="gap:4px">
            <div style="gap:8px" class="d-flex flex-row justify-content-start align-items-center w-100">
                <div class="mb-1" *ngIf="showPageSizeChange">
                    <div class="d-flex align-items-center nom_rows" >
                        <span class="ml-2" >عدد الصفوف</span>
                        <ng-select class="custom w-100px" appendTo="body" 
                        [(ngModel)]="pageOptions.defaultPageSize"
                        [clearable]="false"
                        (change)="onPageSizeChanged($event)" >
                            <ng-option value="15">15</ng-option>
                            <ng-option value="25">25</ng-option>
                            <ng-option value="50">50</ng-option>
                            <ng-option value="100">100</ng-option>
                            <ng-option value="500">500</ng-option>
                            <ng-option value="1000">1000</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <ag-grid-angular style="overflow-y: auto" [ngStyle]="{
        'height': domLayout == 'normal' ? height : '',
        'max-height': maxHeight
        }"
        [headerHeight]="headerHeight"
        [domLayout]="domLayout" class="ag-theme-alpine" [rowData]="rowData" (gridReady)="onGridReady($event)"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [animateRows]="true" [rowDragManaged]="true"
        [masterDetail]="masterDetail" [detailCellRendererParams]="detailCellRendererParams"
        [detailCellRenderer]="detailCellRenderer" [detailRowAutoHeight]="detailRowAutoHeight"
        [copyHeadersToClipboard]="false" [enableRangeSelection]="enableRangeSelection" [getRowStyle]="rowStyle"
        [getRowClass]="rowClass" [rowSelection]="rowSelection" [suppressRowClickSelection]="disableSelectOnClick"
        (rowDragEnd)="onDragEnd($event)" (ColumnVisibleEvent)="onSelectionChanged($event)"
        (columnVisible)="onColumnVisibleChange($event)" [frameworkComponents]="frameworkComponents"
        [rowClassRules]="rowClassRules" [sideBar]="sideBar" (selectionChanged)="onSelectedRowChanged($event)"
        [stopEditingWhenCellsLoseFocus]="true" [suppressClipboardApi]="true" [enableRtl]="true"
        (filterChanged)="onFilterChanged($event)" [serverSideStoreType]="serverSideStoreType"
        [rowModelType]="rowModelType" [serverSideFilteringAlwaysResets]="true" [treeData]="treeData"
        [getDataPath]="getDataPath" [autoGroupColumnDef]="autoGroupColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded" (sortChanged)="onSortChange($event)" 
        [excludeChildrenWhenTreeDataFiltering]="excludeChildrenWhenTreeDataFiltering" [suppressCellSelection]="true">

        <!-- For Languages [enableRtl]="translation.getSelectedLanguage() == 'ar'" -->

        <!-- (selectionChanged)="onSelectionChanged($event)" 
        This new event to get selected row after every row selection 
        We will handel this new function here and test if has side effects  
    -->
    <!-- [alwaysShowHorizontalScroll]="true" to show scroll bar -->
        <!-- [rowGroupPanelShow]="rowGroupPanelShow"
    [pivotPanelShow]="pivotPanelShow"  -->
    </ag-grid-angular>
    <app-ag-grid-pagination [isPageable]="pagination" [pageOptions]="pageOptions" (pageEvent)="paginationEvent($event)">
    </app-ag-grid-pagination>
</div>

<!-- 
[enableRangeSelection]="true"
[copyHeadersToClipboard]="true" -->