import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { MessageTypes } from 'src/app/modules/whats-app/enums/message-types.enum';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { HttpErrorResponseStatus } from 'src/app/shared/models/shared';
import { HelperService } from 'src/app/shared/services/helpers.service';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { SendFileComponent } from './components/send-file/send-file.component';
import { SendImageComponent } from './components/send-image/send-image.component';
import { SendVideoComponent } from './components/send-video/send-video.component';
import { SendVoiceComponent } from './components/send-voice/send-voice.component';
import { WhatsappSendSmsComponent } from './components/whatsapp-send-sms/whatsapp-send-sms.component';

@Component({
  selector: 'add-new-messages',
  templateUrl: './add-new-messages.component.html',
  styleUrls: ['./add-new-messages.component.scss']
})
export class AddNewMessagesComponent implements OnInit {


  // Inputs 
  @Input() numberId;
  @Output() afterAdd: EventEmitter<any> = new EventEmitter()



  // Components 
  @ViewChild("sendFile") SendFileComponent: SendFileComponent;
  @ViewChild("sendAudio") SendVoiceComponent: SendVoiceComponent;
  @ViewChild("sendImage") SendImageComponent: SendImageComponent;
  @ViewChild("sendVideo") SendVideoComponent: SendVideoComponent;
  @ViewChild("sendSms") WhatsappSendSmsComponent: WhatsappSendSmsComponent;


  // Props 
  activeTab = 1;
  message: any = '';
  msgTypes: any[] = [
    {
      id: 1,
      label: 'إرسال نص',
      templateName: 'sendSMS',
      icon: 'fa fa-text-height',
    },
    {
      id: 1,
      label: 'إرسال ملف',
      icon: 'fas fa-file',
      templateName: 'sendFile'
    },
    {
      id: 1,
      label: 'إرسال ملف صوتي',
      icon: 'fas fa-volume-up',
      templateName: 'sendVoice'
    },
    {
      id: 1,
      label: 'إرسال صورة',
      icon: 'fas fa-images',
      templateName: 'sendImage'
    },
    {
      id: 1,
      label: 'إرسال فيديو',
      icon: 'fas fa-video',
      templateName: 'sendVideo'
    },
  ];
  subGroupsNumbersData = [];
  chatareaPreviewData: any = {
    messages: [],
    sender: '',
  };

  currentDate = moment();
  allMessages: any[] = [];

  // Filters
  time_frame;
  tempWhatsNumberId;
  templateName;
  subscription: Subscription = new Subscription();


  constructor(
    private _Helpers: HelperService,
    private _MessagesService: MessagesService,
    private _WhatsService: WhatsService,
  ) { }

  ngOnInit(): void {
    this.tempWhatsNumberId = this.numberId;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  insert(): void {

    this.message = null;

    switch (this.activeTab) {
      case MessageTypes.SMS:
        this.SMSMessageHandler();
        break;
      case MessageTypes.File:
        this.sendFileHandler();
        break;

      case MessageTypes.Voice:
        this.SMSAudioHandler();
        break;

      case MessageTypes.Image:
        this.sendImageHandler();
        break;

      case MessageTypes.Video:
        this.sendVideoHandler();
        break;

      default:
        break;

    }

  }


  sendeDataToPreviewer(): void {
    this.chatareaPreviewData.templateName = this.templateName;
    this.chatareaPreviewData = {
      templateName: this.templateName,
      messages: this.chatareaPreviewData.messages
    }
  }


  sendFileHandler(): void {
    this.message = this.SendFileComponent.message;
    const file = this.SendFileComponent.selectedFile;
    const fileURL = this.SendFileComponent.fileURL;
    const filename = this.SendFileComponent.fileName;

    const selectedFileType = this.SendFileComponent.selectedFileType;
    if ((!file && !fileURL) || !filename) {
      this.showInvalidDataErrorMessage();
      return
    }


    if (fileURL) {

      if (this.isInValidURL(fileURL)) return

      const data = this.prepareMessageObject('file', { body: fileURL, filename }, null, selectedFileType);


      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
      return
    }

    this._Helpers.convertFileToBase64(file).then((result: string) => {


      const data = this.prepareMessageObject('file', { body: result, isUploaded: true, filename }, null, selectedFileType);
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
    })
  }



  isInValidURL(url: string): boolean {
    if (this._Helpers.urlValidator(url).invalidUrl) {
      this._MessagesService.showErrorMessage('يرجى ادخال رابط صحيحا.');
      return true
    }
    return false
  }


  SMSAudioHandler(): void {
    this.message = this.SendVoiceComponent.message;
    const audioURL = this.SendVoiceComponent.audioURL;
    const file = this.SendVoiceComponent.selectedFile;
    const filename = this.SendVoiceComponent.fileName;

    if (!file && !audioURL) {
      this.showInvalidDataErrorMessage();
      return
    }
    if (audioURL) {
      if (this.isInValidURL(audioURL)) return
      const data = this.prepareMessageObject('audio', { body: audioURL, filename }, null);
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
      return
    }

    this._Helpers.convertFileToBase64(file).then((result: string) => {
      const data = this.prepareMessageObject('audio', { body: result, filename }, null);
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
    })
  }



  sendImageHandler(): void {
    this.message = this.SendImageComponent.message;
    const file = this.SendImageComponent.selectedFile;
    const imgURL = this.SendImageComponent.imgURL;
    if (!file && !imgURL) {
      this.showInvalidDataErrorMessage();
      return
    }

    if (imgURL) {
      if (this.isInValidURL(imgURL)) return
      const data = this.prepareMessageObject('image', { body: imgURL }, imgURL);
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
      return
    }

    this._Helpers.convertFileToBase64(file).then((result: string) => {
      const data = this.prepareMessageObject('image', { body: result }, result);
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
    })
  }


  sendVideoHandler(): void {
    this.message = this.SendVideoComponent.message;
    const file = this.SendVideoComponent.selectedFile;
    const videoURL = this.SendVideoComponent.videoURL;
    if (!file && !videoURL) {
      this.showInvalidDataErrorMessage();
      return
    }

    if (videoURL) {
      if (this.isInValidURL(videoURL)) return
      const data = this.prepareMessageObject('video', { body: videoURL });
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
      return
    }

    this._Helpers.convertFileToBase64(file).then((result: string) => {

      const data = this.prepareMessageObject('video', { body: result });
      this.chatareaPreviewData.messages.push(data);
      this.sendeDataToPreviewer();
    })
  }



  SMSMessageHandler(): void {
    this.message = this.WhatsappSendSmsComponent.message;

    if (!this.message?.message.length) {
      this.showInvalidDataErrorMessage();
      return
    }

    const data = this.prepareMessageObject('text');

    this.chatareaPreviewData.messages.push(data);
    this.sendeDataToPreviewer();
  }



  prepareMessageObject(
    type: string,
    text?: any,
    imagPath?: string,
    fileType?: any,
  ): any {

    return {
      "text": {
        "body": this.message?.message,
        lines: this.message?.messageLines,
        caption: this.message?.message,
        ...text
      },
      imagPath,
      fileType,
      messageTime: moment().format("hh:mm A"),
      "type": type,
      "sent_status": "send",
    }
  }

  showInvalidDataErrorMessage(): void {
    this._MessagesService.showErrorMessage('يرجى ملء جميع الحقول المطلوبة.')
  }


  prepareDataBeforeSent(numbers?): any {
    const data = {
      name: this.templateName,
      messages: [],
    };

    this.chatareaPreviewData.messages.forEach(el => {

      let message: any = {
        data: {},
        numbers: []
      };
      switch (el?.type) {


        case 'image':
        case 'video':
        case 'file':
          message.type = 'file';
          message.sendMediaAsDocument = true,
          message.otherType = el?.type;
            message.url = el?.text.body;
          message.filename = el?.text?.filename || el?.type;
          message.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        case 'location':
          message.type = 'location';
          message.otherType = el?.type;
          message.lat = el?.text?.lat;
          message.lng = el?.text?.lng;
          message.address = el?.address;
          break;

        case 'text':
          message.type = 'text';
          message.otherType = el?.type;
          message.body = el?.text?.body;
          break;

        case 'audio':
          message.type = 'file';
          message.otherType = el?.type;
          message.url = el?.text.body;
          message.sendAudioAsVoice = true
          message.filename = el?.text?.filename || el?.type;
          message.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        default:
          break;
      }


      data.messages.push(message);
    })
    return data;
  }


  // Start::Shared Message Functions
  validMessages() {
    if (!this.templateName) {
      this._MessagesService.showErrorMessage(' يجب ادخال اسم القالب');
      return false;
    }
    if (!this.chatareaPreviewData.messages?.length) {
      this._MessagesService.showErrorMessage('يجب ادخال وإدراج الرسالة')
      return false;
    }



    return true;
  }


  smsCheckNumbersErrorHandler(error: HttpErrorResponse): void {
    if (error.status != HttpErrorResponseStatus.unprocessableEntity) {
      this._MessagesService.showErrorMessage(error?.error?.message ? error?.error?.message : 'حدث خطأ ما');
    }
  }


  smsSendMessage(payload: any): void {
    this._WhatsService.sendMessage(this.numberId, payload).subscribe(res => {
      this.showConfirmDialog();
      this._MessagesService.showSuccessMessage(res?.message ? res?.message : 'تمت العملية بنجاح')
    }, (error: HttpErrorResponse) => {
      this.smsSendMessageErrorHandler(error)
    })
  }


  smsSendMessageErrorHandler(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpErrorResponseStatus.unprocessableEntity:
        this._MessagesService.showErrorMessage(error?.error[Object.keys(error?.error)[0]])
        break;

      default:
        this._MessagesService.showErrorMessage(error?.error?.message ? error?.error?.message : 'حدث خطأ ما');
        break;
    }
  }


  showConfirmDialog(): void {
    this._MessagesService.showConfirmDeleteMessage('هل تريد الارسال لنفس الارقام رسالة اخرى؟', 'نعم', 'لا').then(res => {
      if (!res?.isConfirmed) {
        this.resetAftersendMessage();
      }
    });
  }

  resetAftersendMessage(): void {
    this.message = '';
    this.chatareaPreviewData.messages = [];
    this.SendFileComponent?.clearMessage();
    this.SendVoiceComponent?.clearMessage();
    this.SendImageComponent?.clearMessage();
    this.SendVideoComponent?.clearMessage();
    this.WhatsappSendSmsComponent?.clearMessage();
    this.SendFileComponent ? this.SendFileComponent.fileURL = null : null;
    this.SendFileComponent ? this.SendFileComponent.fileName = null : null;
    this.SendFileComponent ? this.SendFileComponent.selectedFileType = null : null;
    this.SendVoiceComponent ? this.SendVoiceComponent.audioURL = null : null;
    this.SendImageComponent ? this.SendImageComponent.imgURL = null : null;
    this.SendVideoComponent ? this.SendVideoComponent.videoURL = null : null;
  }



  saveTemp(): void {
    if (!this.validMessages()) return
    const payload = this.prepareDataBeforeSent();
    this.addTemplate(payload)

  }

  addTemplate(payload: any) {
    this.subscription.add(
      this._WhatsService.addMessageTemplate({
        text: JSON.stringify(payload?.messages),
        name: payload?.name,
        all_users: true,
        whatsapp_number_id: this.numberId
      }).subscribe(res => {
        this._MessagesService.showSuccessMessage('تم الاضافه بنجاح');
        this.afterAdd.emit()
      })
    )
  }


}
