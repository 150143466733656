<section class="chat_area" #chatArea (scroll)="onChatAreaScroll($event)">

    <div class="custom-grabbing chat_area__message-wrapper" cdkDropList 
    (cdkDropListDropped)="drop($event)" *ngIf="messagesArr.length || officialTemplate; else no_messages">
    
    <ng-container *ngIf="!officialTemplate">
        <ng-container *ngFor="let item of messagesArr; trackBy:trackBy; let index = index">
         
            <ng-container *ngIf="item?.type == 'text'">
                <div [ngClass]="{
                    'chat_area__message--owner': item?.sent_status == 'send'
                }" class="chat_area__message mb-3" cdkDragPreviewContainer=".chat_area__message-wrapper" cdkDrag cdkDragBoundary=".chat_area__message-wrapper">

                    <!-- <span class="chat_area__message__name">Name</span> -->
                    <span class="chat_area__message__text d-flex flex-column" *ngIf="item?.text?.lines?.length">
                        <span class="d-flex" [ngClass]="{'text-left': item?.dir == 'ltr','text-right': item?.dir == 'rtl'}" 
                        *ngFor="let item of item?.text?.lines" 
                        [ngStyle]="{'direction': item?.dir == 'ltr' ? 'ltr' : item?.dir == 'rtl' ? 'rtl' : 'ltr' }" [innerText]="item?.text" >
                        </span>
                    </span>

                    <span class="chat_area__message__text d-flex" *ngIf="!item?.text?.lines?.length"
                        [ngStyle]="{'direction': item?.dir == 'ltr' ? 'ltr' : item?.dir == 'rtl' ? 'rtl' : 'ltr' }" [innerText]="item?.text?.body" >
                    </span>

                    <span class="chat_area__message__time" *ngIf="item?.sent_status != 'send'">{{item?.messageTime ?
                        item?.messageTime : '&nbsp;' }}</span>

                    <span class="chat_area__message__time" *ngIf="item?.sent_status == 'send'">
                        <span>{{item?.messageTime ? item?.messageTime : '&nbsp;' }}</span>
                    </span>

                    <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                </div>
            </ng-container>

            <!-- location -->
            <ng-container *ngIf="item?.type == 'location'">
                <div [ngClass]="{
                            'chat_area__message--owner': item?.sent_status == 'send'
                        }" class="chat_area__message mb-3" cdkDragPreviewContainer=".chat_area__message-wrapper" cdkDrag cdkDragBoundary=".chat_area__message-wrapper">
                    <a class="location"
                        href="{{'https://www.google.com/maps/?q='+item?.text?.lat+','+item?.text?.lng}}"
                        target="_blank" style="min-width: 256px;">
                        <app-google-map-view [center]="{lat:item?.text?.lat,lng:item?.text?.lng}"
                            width="100%" height="200px" [zoom]="15" [markerOptions]="{draggable : false}"
                            [markerPosition]="{lat:item?.text?.lat,lng:item?.text?.lng}"
                            [enableEdit]="false">
                        </app-google-map-view>
                        <p class="mb-0 mt-2 chat_area__message__text">
                            {{item?.text?.address}}
                        </p>
                    </a>
                    <span class="chat_area__message__time" *ngIf="item?.sent_status == 'send'">
                        <span>{{item?.messageTime ? item?.messageTime : '&nbsp;' }}</span>
                    </span>
                    <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                </div>
            </ng-container>


            <!-- begin:: if is image  -->
            <ng-container *ngIf="item?.type == 'image'">
                <div [ngClass]="{
                        'chat_area__message--owner': item?.sent_status == 'send'
                    }" class="chat_area__message mb-3" cdkDragPreviewContainer=".chat_area__message-wrapper" cdkDrag cdkDragBoundary=".chat_area__message-wrapper">
                    <image-card [imagPath]="item?.imagPath" [data]="item"></image-card>
                    <span class="chat_area__message__time"
                        *ngIf="item?.sent_status != 'send'">{{item?.messageTime ? item?.messageTime :
                        '&nbsp;'
                        }}</span>

                    <span class="chat_area__message__time justify-content-between mt-1"
                        *ngIf="item?.sent_status == 'send'">
                        <div class="start d-flex align-items-center">
                            <span>{{item?.messageTime ? item?.messageTime : '&nbsp;' }}</span>
                        </div>
                    </span>
                    <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                </div>
            </ng-container>
            <!-- end:: if is image  -->


            
            <ng-container *ngIf="item?.type == 'video'">
                <div [ngClass]="{
                            'chat_area__message--owner': item?.sent_status == 'send'
                        }" class="chat_area__message mb-3" cdkDragPreviewContainer=".chat_area__message-wrapper" cdkDrag cdkDragBoundary=".chat_area__message-wrapper">
                    <video controls class="w-100">
                        <source [src]="item?.text?.body" type="video/mp4">
                        <source [src]="item?.text?.body" type="video/ogg">
                        Your browser does not support HTML5 video.
                    </video>
                    <p class="mb-0 mt-2 chat_area__message__text">
                        {{item?.text?.caption}}
                    </p>
                    <span class="chat_area__message__time mt-1" *ngIf="item?.sent_status == 'send'">
                        <span>{{item?.messageTime ? item?.messageTime : '&nbsp;' }}</span>
                    </span>
                    <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                </div>
            </ng-container>


            <!-- begin:: if is file  -->
            <ng-container
                *ngIf="item?.type == 'file'">

                 <div [ngClass]="{
                         'chat_area__message--owner': item?.sent_status == 'send'
                     }" class="chat_area__message mb-3" cdkDragPreviewContainer=".chat_area__message-wrapper" cdkDrag cdkDragBoundary=".chat_area__message-wrapper">
                     <file-card [type]="item.fileType" [data]="item"></file-card>
                     <span class="chat_area__message__time justify-content-between">
                         <div class="start d-flex align-items-center"
                             *ngIf="item?.sent_status == 'send'">
                             <span>
                                {{item?.messageTime ? item?.messageTime : '&nbsp;' }}
                             </span>
                         </div>
                         <div class="end d-flex align-items-center" dir="ltr">
                             <span>{{item.fileType}}</span>
                             <span class="mx-1">•</span>
                             <span>12 kB</span>
                         </div>
                     </span>
                     <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                 </div>
            </ng-container>
            <!-- end:: if is file  -->


            <ng-container *ngIf="item?.type == 'audio'">
                <div [ngClass]="{
                            'chat_area__message--owner': item?.sent_status == 'send'
                        }" class="chat_area__message mb-3">
                    <audio controls class="w-100" style="min-width: 220px;">
                        <source [src]="item?.text?.body" type="audio/ogg">
                        <source [src]="item?.text?.body" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                    <p class="mb-0 mt-2 chat_area__message__text">
                        {{item?.text?.caption}}
                    </p>
                    <span class="chat_area__message__time mt-1" *ngIf="item?.sent_status == 'send'">
                        <span>{{item?.messageTime ? item?.messageTime : '&nbsp;' }}</span>
                    </span>
                    <ng-container *ngTemplateOutlet="messageActions; context: {index: index}"></ng-container>
                </div>
            </ng-container>



        </ng-container>
    </ng-container>
    
    <ng-container *ngIf="officialTemplate">

        <app-whats-official-template
            [template]="officialTemplate"
        >
        </app-whats-official-template>

    </ng-container>

    </div>

    <!-- begin:: scroll to button  -->
    <button (click)="scrollToBottom()" *ngIf="shouldScroll" mat-icon-button
        class="mx-1 position-fixed bg-white shadow scroll-btn">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <!-- end:: scroll to button  -->

    <!-- if there are no messages show this template -->
    <ng-template #no_messages>
        <div class="note m-auto d-flex flex-column align-items-center justify-content-center">
            <div class="mb-1">
                <i class="fas fa-comments text-primary font-size-xl"></i>
            </div>
            <div class="mb-1 font-size-lg text-primary font-weight-bolder">لا توجد رسائل</div>
            <div>من فضلك قم بإدخال الرسالة ثم النقر علس ادراج لكي تتمكن من معاينة الرسالة قبل الإرسال</div>
        </div>
    </ng-template>

</section>


<!-- Templates  -->
<ng-template #messageActions let-index="index">
    <div class="chat_area__message__actions d-flex align-items-start top-0 px-2">
        <button (click)="confirmDeleteMessage(index)" class="btn mx-1 p-0">
            <i class="fas fa-trash-alt text-danger"></i>
        </button>
        <button class="btn custom-grabbing x-1 p-0">
            <i class="fas fa-grip-horizontal text-primary"></i>
        </button>
    
    </div>
</ng-template>
