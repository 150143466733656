<div class="mat-dalog-header bg-primary border-bottom py-5 px-8 mb-8 d-flex justify-content-between">
    <h3 class="mb-0 text-white font-weight-bolder" mat-dialog-title>
        قوالب الإرسال
    </h3>
    <div class="end d-flex align-items-center">
        <button mat-button mat-dialog-close class="min-w-auto line-height-normal p-2 popup-close-btn px-0">
            <i class="fas fa-times text-white"></i>
        </button>
    </div>
</div>

<mat-dialog-content class="mb-5 px-8">

    <div class="row">
        <!-- Start:: Form -->
        <div class="col-md-12">
            <div class="row">
                <ng-container *ngIf="dataLoaded">
                    <div class="form-group col-md-12" *ngIf="total">
                        <label for="exampleFormControlTextarea1" class="ml-auto font-weight-bolder">نص البحث:
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" name="apiKey" placeholder="نص البحث"
                            [(ngModel)]="filters.search_word" appDebounceInputChange [debounceTime]="500"
                            (valueChanged)="getTableItems()" />
                    </div>

                    <!-- List Grid -->

                    <div class="vc-ag-grid global-data-table table-header w-100"
                        *ngIf="tableData?.length; else noDataFound ">
                        <app-ag-grid #agGrid [rowData]="tableItems" [frameworkComponents]="frameworkComponents"
                            [rowColors]="{ even: '#fff', odd: '#ebf7ff' }" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [domLayout]="'autoHeight'" [GlobalTable]="true"
                            [enableRangeSelection]="false" [disableSelectOnClick]="true" [sizeColumnsToFit]="true"
                            [pagination]="true" [pageOptions]="pageOptions" (pageEvent)="pageEvent($event)">
                        </app-ag-grid>
                        <!-- [sizeColumnsToFit]="true" -->
                    </div>

                    <ng-template #noDataFound>
                        <app-no-data-found class="w-50 m-auto"></app-no-data-found>
                    </ng-template>
                </ng-container>

            </div>
        </div>

    </div>
</mat-dialog-content>