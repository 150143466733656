import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageTypes } from "../../models/shared";

@Component({
  selector: 'app-wrnning-messages',
  templateUrl: './wrnning-messages.component.html',
  styleUrls: ['./wrnning-messages.component.scss']
})
export class WrnningMessagesComponent implements OnInit {
  @Input() type = '';
  @Input() message = '';
  @Input() html = '';
  @Input() showClose = true;
  @Input() addErrorClasses = true;
  @Output() show:EventEmitter<any> = new EventEmitter() ;

  types = MessageTypes;
  constructor() { }

  ngOnInit(): void {
  }

}
