import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageAreaComponent } from 'src/app/shared/shared-modules/message-area/message-area.component';

@Component({
  selector: 'app-whatsapp-send-sms',
  templateUrl: './whatsapp-send-sms.component.html',
  styleUrls: ['./whatsapp-send-sms.component.scss']
})
export class WhatsappSendSmsComponent implements OnInit {

  // Components 
  @ViewChild("messageArea") MessageAreaComponent: MessageAreaComponent;

  message: any;
  isClear: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }



  clearMessage(): void {
    this.message = null;
    this.MessageAreaComponent.clear();
  }

}
