import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapViewComponent } from "./components/google-map-view/google-map-view.component";
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [GoogleMapViewComponent],
  imports: [
    CommonModule,
    GoogleMapsModule
  ],
  exports : [GoogleMapViewComponent,GoogleMapsModule]
})
export class GoogleMapModule { }
