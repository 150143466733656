import { Injectable } from '@angular/core';
import { NgxPermissionsService } from "ngx-permissions";
import { PermissionsEnums } from "../enums/permissions.enum";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private isInitialized : BehaviorSubject<boolean> = new BehaviorSubject(false);
  isInitialized$ = this.isInitialized.asObservable();

  constructor(
    private permissionsService: NgxPermissionsService
  ) { }

  initializeUserPermissions(permissionList: any) {
    this.permissionsService.loadPermissions(permissionList);
    this.isInitialized.next(true);
  }

  checkIfPermissionsInitialized() {
    return this.permissionsService.permissions$;
  }

  getPermissionList(userData: any) {
    
    let permissionList = [];
    const permissionMap = {
      can_change_data: PermissionsEnums.CanChangeData,
      can_use_api: PermissionsEnums.CanUseApi,
      can_use_whatsapp : PermissionsEnums.CanUseWhatsapp,
      send_message_direct: PermissionsEnums.CanSendMessageDirect,
      email : PermissionsEnums.MainUser,
      main_account : PermissionsEnums.subAccount,
      add_sub_account_allowed: PermissionsEnums.CanCreateSubAccount,
    };
    const list = Object.entries(permissionMap)
        .filter(([key, _]) => {
          return userData[key]
        })
        .map(([_, permission]) => permission);
    permissionList.push(...list);

    const acceptedEmails = [
      "gad@4jawaly.com",
      "ebtesam.mohamed@4jawaly.com",
      "fatma.ahmed@4jawaly.com"
    ]

    if(acceptedEmails.includes(userData.email)) {
      permissionList.push(PermissionsEnums.canShowDev);
    }
    
    return permissionList;
  }

  checkIfUserHasPermission(permission: string) {
    return this.permissionsService.hasPermission(permission);
  }

}
