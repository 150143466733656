import { Component, Input, OnInit } from '@angular/core';
import { TemplateComponentsTypes } from "src/app/modules/whats-app/enums/whats-templates";

@Component({
  selector: 'app-whats-official-template',
  templateUrl: './whats-official-template.component.html',
  styleUrls: ['./whats-official-template.component.scss']
})
export class WhatsOfficialTemplateComponent implements OnInit {
  @Input() set template(temp){
    if(temp){
      this.messageTemplateComponents = this.sortComponents(temp?.components);
      this.msgTemplate = temp;
      this.templateDir = this.msgTemplate?.language === 'ar' ? 'rtl' : 'ltr';
    }
  }

  message: any
  msgTemplate: any;
  templateDir: any;
  templateContent: any;
  messageTemplateComponents:any;
  componentTypes = TemplateComponentsTypes;

  constructor() { }

  ngOnInit(): void {
  }

  sortComponents(components){
    return components.sort((a, b) => {
      const order = {
          "HEADER": 1,
          "BODY": 2,
          "FOOTER": 3,
          "BUTTONS": 4
      };
      
      return order[a.type] - order[b.type];
    });
  }

}
