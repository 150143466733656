import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserDbService {
    setItem(key: string, value: any) {
        return localStorage.setItem(key, JSON.stringify(value))
    }

    getItem(key: string):any {
        if (localStorage.getItem(key)){
            let val = '';
            try {
                val = JSON.parse(localStorage.getItem(key));
            } catch (error) {
                val = localStorage.getItem(key);
            }
            return val;
        } 
        return null;
    }
    
    removeItem(key: string) {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }
}
