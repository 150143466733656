<ng-container *ngIf="isList && !isPreview">
    <div class="mat-dalog-header bg-primary border-bottom py-5 px-8 mb-8 d-flex justify-content-between">
        <h6 class="mb-0 text-white font-weight-bolder" mat-dialog-title>
            الردود المحفوظة
        </h6>
        <div class="end d-flex align-items-center">
            <button mat-button mat-dialog-close class="min-w-auto line-height-normal px-2 popup-close-btn">
                <i class="fas fa-times text-white"></i>
            </button>
        </div>
    </div>

    <mat-dialog-content class="mb-5 px-8">


        <div class="filter d-flex align-items-center justify-content-between mb-8">
            <div class="start flex-grow-1">
                <input class="form-control py-5"
                type="text" name="search" appDebounceInputChange [debounceTime]="500"
                (valueChanged)="onSearchChange($event)" placeholder="بحث">
            </div>
            <!-- <div class="end">
                <button 
                    [ngClass]="{
                        'btn-primary': isMostUsed,
                        'btn-outline-primary': !isMostUsed
                    }"
                    (click)="sortBy()" class="btn font-weight-bolder px-4 border border-primary sort-btn" mat-button>
                    <span class="font-size-sm d-none d-md-inline ml-2">الأكثر استخداما</span>
                    <i class="fas fa-sort-amount-down-alt p-0"></i>
                </button>
            </div> -->
        </div>

        <ul class="list-unstyled p-0 responses-list">
            <ng-container *ngFor="let item of msgTemplates">
                <li class="media">
                    <button (click)="preview(item)" class="start p-0 btn flex-grow-1 d-flex">
                        <i class="fas fa-images ml-5" style="font-size: 2.8rem;"></i>

                        <div class="media-body text-right">
                            <h6 class="mt-0 mb-1 font-weight-bolder">{{ item?.name }}</h6>
                            <div class="msg"></div>
                        </div>
                    </button>
                    <div class="action">
                        <div ngbDropdown class="d-inline-block" container="body">
                            <button (click)="$event.stopPropagation()"
                                class="btn h-100 d-flex align-items-center justify-content-between" id="dropdownBasic1"
                                ngbDropdownToggle>
                                <i class="ki ki-bold-more-ver"></i>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <button (click)="editTemp(item)" class="dropdown-item">تعديل</button>
                                <button (click)="confirmDeleteTemp(item)" class="dropdown-item">حذف</button>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>


    </mat-dialog-content>


    <mat-dialog-actions align="end" class="px-8 py-3 border-top m-0">
        <button mat-dialog-close class="btn font-weight-bolder px-10 py-1" mat-button>
            إلغاء
        </button>
        <button (click)="addNewResponse()" class="btn btn-primary font-weight-bolder px-10 py-1" mat-button>
            إضافة جديد
        </button>
    </mat-dialog-actions>

</ng-container>



<ng-container *ngIf="!isList && !isEditTemp">
    <add-edit-response (backToList)="onBack()" (afterAdd)="afterAddNewTemplate()" [number]="data.number" [numberId]="data.numberId"></add-edit-response>
</ng-container>


<ng-container *ngIf="isPreview">
    <preview-and-send (backToList)="onBack()" [template]="selectedTemplate" [number]="data.number" [numberId]="data.numberId"></preview-and-send>
</ng-container>

<ng-container *ngIf="isEditTemp && !isList">
    <edit-message-template (afterUpdate)="afterAddNewTemplate()" (backToList)="onBack()" [template]="selectedTemplate" [number]="data.number" [numberId]="data.numberId"></edit-message-template>
</ng-container>