   
    <div class="form-group">
        <label class="w-100 text-right font-weight-bolder">
            إسم القالب
            <span class="text-danger">*</span>
        </label>
        <input [(ngModel)]="templateName" type="text" class="form-control" name="lng">
    </div>

   <!-- MSG Types Navs  -->
   <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="auk-nav-tabs auk-nav-tabs--main nav-tabs border-0 px-0">
    <ng-container *ngFor="let item of msgTypes; let index = index">
        <li [ngbNavItem]="index+1">
            <a ngbNavLink class="border-0 font-weight-bolder d-flex align-items-center mb-3">
                <i [class]="item.icon" class="ml-3 mb-0"></i>
                <div> {{ item.label }} </div>
            </a>

            <ng-template ngbNavContent>
                <div class="nav-temp" [ngSwitch]="item.templateName">
                    <ng-container *ngSwitchCase="'sendSMS'">
                        <app-whatsapp-send-sms #sendSms></app-whatsapp-send-sms>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sendImage'">
                        <send-image #sendImage></send-image>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sendVideo'">
                        <send-video #sendVideo></send-video>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sendFile'">
                        <send-file #sendFile></send-file>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sendVoice'">
                        <send-voice #sendAudio></send-voice>
                    </ng-container>
                </div>
            </ng-template>

        </li>
    </ng-container>
</ul>

<div [ngbNavOutlet]="nav" class="my-8"></div>
<div class="end d-flex justify-content-end">
        
    <button 
        type="button"
        (click)="insert()"
        class="btn btn-primary mr-auto d-block bg-primary px-15 font-weight-bolder ml-2">
        إدراج
    </button>

    <button (click)="saveTemp()" class="btn btn-primary d-block bg-primary px-15 font-weight-bolder" mat-button>
        حفظ        
    </button>
</div>

<div class="message-preview">
    
    <div class="form-group">
        <label class="w-100 text-right font-weight-bolder">
            معاينة الرسالة
        </label>
    </div>

    <chatarea-preview
        style="height: 400px"
        class="d-block rounded overflow-hidden"
        [(chatareaPreviewData)]="chatareaPreviewData"
    ></chatarea-preview>
</div>


