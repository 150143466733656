import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from "src/app/shared/config/constant";
import { BrowserDbService } from "src/app/shared/services/browser-db.service";
import { environment } from "src/environments/environment";

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(
        private browserDbService: BrowserDbService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const isLoggedIn = this.browserDbService.getItem(Constant.token);
        const isSms = request.url.startsWith(environment.sma.apiUrl) || 
        request.url.startsWith(environment.email.apiUrl) || request.url.startsWith(environment.sallaUrl) || request.url.startsWith(environment.gust.apiUrl);
        let token = this.browserDbService.getItem(Constant.smsAuthData);

        if (request.url.startsWith(environment.sallaUrl)) {
            token = this.browserDbService.getItem(Constant.SallaToken);
        }

        if(!isLoggedIn || !token || !isSms) return next.handle(request);
        // this._Sms.getSmsAuthenticationData().pipe(
        //     tap(res => {
        //         
        //         if(res){
        //             request = request.clone({
        //                 setHeaders: {
        //                     Authorization: `Basic ${res}`,
        //                 },
        //             });
        //         }
        //     }),
        //     switchMap((res) => {
        //         return next.handle(request);
        //     }),
        //     catchError(err => {
        //         return next.handle(request);
        //     })
        // );
        request = request.clone({
            headers: request.headers.set('Authorization', `Basic ${token}`)
        })
        return next.handle(request);
    }
}
