import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { NgxSpinnerService } from "ngx-spinner"
import { Observable, of } from "rxjs"
import { catchError } from "rxjs/operators"
import { AuthService } from "src/app/modules/auth"
import { RoutingPathsEnum } from "src/app/shared/enums/routing.enum"
import { GlobalErrorCodes } from "src/app/shared/models/shared"
import { SharedService } from "src/app/shared/services/shared.service"
import { AppInsightService } from "src/app/shared/utilities/app-insight.service"

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: "root",
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService, private router: Router,
     private _Auth: AuthService, private _shared: SharedService ,
     private _AppInsightService:AppInsightService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error, request, next)))
    // }
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    // this.credentialsService.setCredentials();
    return of(true)
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>, request, next): Observable<HttpEvent<any>> {

    this._AppInsightService.logHttpException(response , request.body);
    
    this.spinner.hide()
    /**
     * pass validation errors to next > core form to handle it
     */
    if (response instanceof HttpErrorResponse) {
      
      if (response.status === 422) {
        throw response
      }

      /**
       * logout when unauthenticated
       */
      if (response.status === 401) {
        // if(!response.url.includes(environment.whats.newBoot)){
        //   this._Auth.logout()
        // }
        throw response
      }

      if (response.status === 500) {
        throw response
      }
      if (response.status === 404) {
        throw response
      }
      if (response.error?.form_code) {
        this._shared.currentServerErrorCode.next(response.error?.form_code)
      }
      if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.setMobileCode) {
        this.router.navigate(["auth/active-code"], {
          queryParams: {
            type: "phone",
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.setEmailCode) {
        this.router.navigate(["auth/active-code"], {
          queryParams: {
            type: "email",
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.ipNotValid) {
        this.router.navigate(["not-valid-ip"])
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.AcceptAgreement) {
        this.router.navigate([RoutingPathsEnum.SmsSenders])
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.setMobileCodeSubAccount) {
        this.router.navigate(["auth/active-code"], {
          queryParams: {
            type: "phone",
            subAccount: true,
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.SubUerChangePassword) {
        this.router.navigate(["user/user-information"], {
          queryParams: {},
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.NeedVerifyGoogleAuth) {
        this.router.navigate(["auth/google-auth"])
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.userNotFound) {
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.NeedToAddDevice) {
        this.router.navigate(["auth/add-new-device"])
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.AddDeviceVerifyMobile) {
        this.router.navigate(["auth/add-new-device-active"], {
          queryParams: {
            type: "phone",
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.AddDeviceVerifyEmail) {
        this.router.navigate(["auth/add-new-device-active"], {
          queryParams: {
            type: "email",
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.AddDeviceVerifyGoogle) {
        this.router.navigate(["auth/add-new-device-active"], {
          queryParams: {
            type: "google",
          },
        })
      } else if (response.error?.code == 302 && response.error?.form_code == GlobalErrorCodes.AddDeviceVerifySubMobile) {
        this.router.navigate(["auth/add-new-device-active"], {
          queryParams: {
            type: "phone",
            subAccount: true,
          },
        })
      }
    }

    throw response
  }
}
