<ng-container *ngIf="!data?.text?.isUploaded else uploadedFile">
    <a download [href]="data?.text?.body" target="_blank" class="btn card file-card border-0 p-0 bg-transparent">
       <ng-container *ngTemplateOutlet="fileCard"></ng-container>
    </a>
</ng-container>



<ng-template #uploadedFile>
    <button (click)="onFileClick(data?.text?.body)" target="_blank" class="btn card file-card border-0 p-0 bg-transparent">
        <ng-container *ngTemplateOutlet="fileCard"></ng-container>
     </button>
</ng-template>


<ng-template #fileCard>
    <div class="file-card__content rounded p-4 d-flex align-items-center w-100" dir="ltr">
        <div [class]="type" class="icon"></div>
        <div class="label text-truncate mx-3">{{data?.text?.filename || data?.api_caption}}</div>
        <div class="action">
            <button class="btn p-0">
                <svg viewBox="0 0 34 34" width="30" height="30" class=""><path fill="currentColor" d="M17 2c8.3 0 15 6.7 15 15s-6.7 15-15 15S2 25.3 2 17 8.7 2 17 2m0-1C8.2 1 1 8.2 1 17s7.2 16 16 16 16-7.2 16-16S25.8 1 17 1z"></path><path fill="currentColor" d="M22.4 17.5h-3.2v-6.8c0-.4-.3-.7-.7-.7h-3.2c-.4 0-.7.3-.7.7v6.8h-3.2c-.6 0-.8.4-.4.8l5 5.3c.5.7 1 .5 1.5 0l5-5.3c.7-.5.5-.8-.1-.8z"></path></svg>
            </button>
        </div>
    </div>
    <span class="chat_area__message__text mt-2 d-block text-right word-break">{{data?.text?.caption ? data?.text?.caption : ''}}</span>
</ng-template>

