import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from "./components/loading/loading.component";
import { SectionLoadingComponent } from "./components/section-loading/section-loading.component";
import { AnimatedSectionComponent } from './components/animated-section/animated-section.component';



@NgModule({
  declarations: [
    LoadingComponent,
    SectionLoadingComponent,
    AnimatedSectionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingComponent,
    SectionLoadingComponent,
    AnimatedSectionComponent
  ]
})
export class LoadingModule { }
