import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NumbersOnlyDirective } from "./number.only.directive"
import { RemoveArabicLettersDirective } from "./remove-arabic-letters.directive"
import { PreventArabicPasteDirective } from "./remove-arabic-letters-clipbord.directive"

@NgModule({
  declarations: [NumbersOnlyDirective, RemoveArabicLettersDirective, PreventArabicPasteDirective],
  imports: [CommonModule],
  exports: [NumbersOnlyDirective, RemoveArabicLettersDirective, PreventArabicPasteDirective],
})
export class SharedDirectivesModule {}
