import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ReCaptchaV3Service } from "ng-recaptcha"
import { NgxSpinnerService } from "ngx-spinner"
import { ToastrService } from "ngx-toastr"
import { EMPTY, Observable, of, throwError } from "rxjs"
import { catchError, map, switchMap, tap } from "rxjs/operators"
import { WebSocketSubject, webSocket } from "rxjs/webSocket"
import { StorageTokens, Tokens } from "src/app/shared/enums/tokens.enum"
import { environment } from "src/environments/environment"
import { UserService } from "../../../user-details/services/users.service"
import { AuthResponse } from "../../interfaces/auth.interface"
import { CallbackRequest, CallbackResponse } from "../../interfaces/callback.interface"
import { FollowupSendingRequest, FollowupSendingResponse } from "../../interfaces/followup-sending.interface"
import { GetEmailsResponse } from "../../interfaces/get-emails.interface"
import { GetFilesResponse } from "../../interfaces/get-files.interface"
import { GetRowsRequest } from "../../interfaces/get-rows.interface"
import { SendMessageRequest, SendMessageResponse } from "../../interfaces/send-message.interface"
import { ToggleConnectionRequest, ToggleConnectionResponse } from "../../interfaces/toggle-connection.interface"
import { UploadGroupsRequest, UploadGroupsResponse } from "../../interfaces/upload-groups.interface"
export const WS_ENDPOINT = environment.excelWS

function removeNulls(obj) {
  Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k])
  return obj
}

@Injectable({
  providedIn: "root",
})
export class GoogleSheetService {
  private baseUrl = environment.excelApiUrl
  private api_key: string
  private api_secret: string

  httpHeaders!: HttpHeaders

  private socket$: WebSocketSubject<any>

  constructor(private _Http: HttpClient, private spinner: NgxSpinnerService, private _ReCaptchaV3Service: ReCaptchaV3Service, private _UserService: UserService, private toaster: ToastrService) {}

  public connect(): Observable<{ message: { id: number }; status: number; status_txt: string; row: number; type: "sms" | "number" }> {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket()
      return this.socket$.pipe(
        tap({
          error: (error) => console.error(error),
        }),
        catchError((_) => EMPTY)
      )
    }
    return of()
  }

  private getNewWebSocket() {
    return webSocket({ url: `${WS_ENDPOINT}?authorization=${this.getApiKeySecret()}` })
  }

  public close() {
    if (this.socket$) {
      this.socket$.complete()
      this.socket$ = null
    }
  }

  public isAuth(): boolean {
    return this.getApiKeySecret() ? true : false
  }

  public getApiKeySecret() {
    return localStorage.getItem(StorageTokens.GOOGLESHEET)
  }

  public createApiKeySecret(): Observable<boolean> {
    this.setLoading(true)
    return this._ReCaptchaV3Service.execute("importantAction").pipe(
      switchMap((token: string) => {
        const payload = {
          name: Tokens.GOOGLESHEET,
          comments: "",
          expired_at: "",
          "g-recaptcha-response": token,
          ips: [],
          admin_only: 1,
        }
        return this._UserService.addApiToken(payload).pipe(map((rsp) => rsp.item))
      }),
      map((rsp) => {
        if (rsp) {
          localStorage.setItem(StorageTokens.GOOGLESHEET, btoa(rsp.app_id + ":" + rsp.app_secret))
          return true
        }
        return false
      }),
      tap((_) => this.setLoading(false))
    )
  }

  public getAllMails(): Observable<any[]> {
    const url = `${this.baseUrl}/email-account`
    return this._Http.get<GetEmailsResponse>(url).pipe(
      map((rsp) => rsp.items),
      catchError((error) => this.handleError(error))
    )
  }

  public auth(): Observable<AuthResponse> {
    return this._Http.get<AuthResponse>(`${this.baseUrl}/email-account/google/login/url`).pipe(catchError((error) => this.handleError(error)))
  }

  public callBack(body: CallbackRequest): Observable<CallbackResponse> {
    return this._Http.post<CallbackResponse>(`${this.baseUrl}/email-account/google/login/callback`, body).pipe(
      catchError((error) => this.handleError(error)),
      tap((rsp) => this.handleSuccess(rsp))
    )
  }

  public toggleConnection(body: ToggleConnectionRequest): Observable<ToggleConnectionResponse> {
    return this._Http.patch<ToggleConnectionResponse>(`${this.baseUrl}/email-account/google/login/toggle`, removeNulls(body)).pipe(catchError((error) => this.handleError(error)))
  }

  public getFiles(id: number, fileName?: string, is_shared_with_me?: number): Observable<any[]> {
    return this._Http.get<GetFilesResponse>(`${this.baseUrl}/email-account/${id}/drive/files?name=${fileName}&is_shared_with_me=${is_shared_with_me}`).pipe(
      map((rsp) => rsp.items),
      catchError((error) => this.handleError(error))
    )
  }

  public getSheets(id: string, fileId: string): Observable<any[]> {
    return this._Http.get<any>(`${this.baseUrl}/email-account/${id}/drive/sheets?id=${fileId}`).pipe(
      map((rsp) => rsp.items),
      catchError((error) => this.handleError(error))
    )
  }

  public getFirstRow(model: GetRowsRequest): Observable<any[]> {
    return this._Http.get<any>(`${this.baseUrl}/email-account/${model.emailId}/drive/first-row?id=${model.fileId}&index=${model.sheetIdx}&start_column=${model.start_column}&start_row=${model.start_row}&end_column=${model.end_column}&end_row=${model.end_row}`).pipe(
      map((rsp) => rsp.items),
      catchError((error) => this.handleError(error))
    )
  }

  public sendMsg(model: SendMessageRequest): Observable<SendMessageResponse> {
    const { emailId, ...body } = model
    return this._Http.post<SendMessageResponse>(`${this.baseUrl}/email-account/${emailId}/sms/send`, removeNulls(body))
  }

  followUpSending(body: FollowupSendingRequest, page?): Observable<{ messages: FollowupSendingResponse }> {
    const url = `${this.baseUrl}/google/followup-sending?page=${page}`
    return this._Http.post<{ messages: FollowupSendingResponse }>(url, removeNulls(body), { headers: this.httpHeaders })
  }

  followUpLoading(body: FollowupSendingRequest, page?): Observable<{ phones: FollowupSendingResponse }> {
    const url = `${this.baseUrl}/google/followup-upload-numbers?page=${page}`
    return this._Http.post<{ phones: FollowupSendingResponse }>(url, removeNulls(body), { headers: this.httpHeaders })
  }

  public loadGroups(model: UploadGroupsRequest): Observable<UploadGroupsResponse> {
    const { emailId, ...body } = model
    const url = `${this.baseUrl}/email-account/${emailId}/numbers/send`
    return this._Http.post<UploadGroupsResponse>(url, removeNulls(body))
  }

  logSendMessages(page?) {
    return this._Http.get(`${this.baseUrl}/email-account/${0}/sms?page=${page}`)
  }

  public logLoadMessages(page?) {
    const url = `${this.baseUrl}/email-account/${0}/numbers?page=${page}`
    return this._Http.get(url)
  }

  logLoadMessagesDetails(unique_job_id: string, page?) {
    const url = `${this.baseUrl}/followup-upload-numbers?unique_job_id=${unique_job_id}`
    return this._Http.get(url, { headers: this.httpHeaders })
  }

  public toggleAutoSending(emailId: number, id: number): Observable<boolean> {
    const url = `${this.baseUrl}/email-account/${emailId}/sms/toggle`
    return this._Http.patch(url, { id }).pipe(
      map((rsp) => (rsp ? true : false)),
      catchError((error) => of(false))
    )
  }

  public toggleLoadJob(emailId: number, id: number) {
    const url = `${this.baseUrl}/email-account/${emailId}/numbers/toggle`
    return this._Http.patch(url, { id }, { headers: this.httpHeaders })
  }

  private handleError(error): Observable<never> {
    if (error?.status !== 0) {
      if (error?.error?.message) {
        this.toaster.error(error.error.message)
      } else if (error?.error) {
        Object.values(error?.error).map((msg: string) => {
          this.toaster.error(msg)
        })
      }
    }
    return throwError(() => error)
  }

  private handleSuccess(response: { message: string }): void {
    this.toaster.success(response.message)
  }

  public setLoading(state: boolean): void {
    this.spinner[state ? "show" : "hide"]()
  }
}
