import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ChangeNumberWithoutRoundPipe } from './change-number-without-round/change-number-without-round.pipe'
import { ConvertStringNumberPipe } from "./convert-string-number/convert-string-number.pipe"
import { LazyLoadDataPipe } from "./convert-string-number/filter.pipe"
import { GetControlPipe } from "./get-control/get-control.pipe"
import { ReplaceWithStarsPipe } from "./replace-with-stars/replace-with-stars.pipe"
import { ShortNumberPipe } from "./short-numbers/short-number.pipe"

@NgModule({
  declarations: [ShortNumberPipe, GetControlPipe, ConvertStringNumberPipe, LazyLoadDataPipe, ReplaceWithStarsPipe, ChangeNumberWithoutRoundPipe],
  imports: [CommonModule],
  exports: [ShortNumberPipe, GetControlPipe, ConvertStringNumberPipe, LazyLoadDataPipe, ReplaceWithStarsPipe,ChangeNumberWithoutRoundPipe],
})
export class PipesModule {}
