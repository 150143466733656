import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from "rxjs/operators";
import { environment } from '../../../../../environments/environment';
import { UserModel } from '../../_models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }



  getAccessToken(gToken): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/token`, null, {
      headers: new HttpHeaders({
        'from': gToken,
      })
    })
      .pipe(shareReplay())
  }

  loginWith(platform: any) {
    return this.http.get<any>(`${environment.apiUrl}/auth/${platform}/redirect?redirect=${location.origin}/auth/login`, {
      headers: new HttpHeaders({
        // 'from': auth.guestToken,
        // 'Authorization': auth.codeToken
      })
    })
  }

  verifyLoginWith(code, platform: any) {
    return this.http.post<any>(`${environment.apiUrl}/auth/${platform}/login`, { code, redirect: `${location.origin}/auth/login` }, {
      headers: new HttpHeaders({
        // 'from': auth.guestToken,
        // 'Authorization': auth.codeToken
      })
    });
  }

  getUserProfile(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel | any>(`${environment.apiUrl}/profile`, {
      headers: httpHeaders,
    });
  }


  login(form,loginSub:boolean = false) {
    let body = {
      email: form?.email,
      'g-recaptcha-response': form?.key
    }

    if(loginSub){
      body['mobile'] = form?.mobile;
      body['country_iso'] = form?.country_iso;
    }

    let url = !loginSub ? `${environment.apiUrl}/auth/login` : `${environment.apiUrl}/auth/sub-login`
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        'x-password' : form?.password
      })
    })
  }

  registerWithMobile(body, auth) {
    let url = `${environment.apiUrl}/register-request/mobile`
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        'from': auth.guestToken,
        'Authorization': auth.codeToken
      })
    })
  }

  resendCodeToMobile(body, auth) {
    let url = `${environment.apiUrl}/register-request/resend-mobile-code`
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        'from': auth.guestToken,
        'Authorization': auth.codeToken
      })
    })
  }

  activeMobile(body, auth) {
    let url = `${environment.apiUrl}/register-request/active-mobile`
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        'from': auth.guestToken,
        'Authorization': auth.codeToken
      })
    })
  }

  setUserData(body, auth) {
    let url = `${environment.apiUrl}/register-request/store-details`
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        'from': auth.guestToken,
        'Authorization': auth.codeToken
      })
    })
  }
}
