import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { WhatsService } from "src/app/modules/whats-app/services/whats.service";
import { HelperService } from "src/app/shared/services/helpers.service";
import { MessagesService } from "src/app/shared/services/messages.service";

@Component({
  selector: 'chatarea-preview',
  templateUrl: './chatarea-preview.component.html',
  styleUrls: ['./chatarea-preview.component.scss']
})
export class ChatareaPreviewComponent implements OnInit {

  @Input() chatareaPreviewData: any;
  @Input() selectedProjectType: any;
  @Input() selectedProjectName: any;
  subscription: Subscription = new Subscription();
  @Output() chatareaPreviewDataChange = new EventEmitter();

  @ViewChild('save_template') save_template: any;

  loading = false;
  templateName = '';

  constructor(
    public _Dialog: MatDialog,
    private _Helpers: HelperService,
    private _MessagesService: MessagesService,
    private _WhatsService: WhatsService,
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.chatareaPreviewData) {

    }

  }

  onMessagesArrChange(e: any): void {
  }


  openSaveTemplateDialog(): void {
    this._Dialog.open(this.save_template, this._Helpers.getDialogOptions('30%', {}));
  }

  saveTemp(): void {
    if (!this.validMessages()) return
    const payload = this.prepareDataBeforeSent();
    this.addTemplate(payload)
  }

  addTemplate(payload: any) {
    this.loading = true;
    this.subscription.add(
      this._WhatsService.addMessageTemplate({
        text: JSON.stringify(payload?.messages),
        name: payload?.name
      }).subscribe(res => {
        this.loading = false;
        this._MessagesService.showSuccessMessage('تم الاضافه بنجاح');
        this._Dialog.closeAll();
      }, error => {
        this.loading = false;
      })
    )
  }

  // Start::Shared Message Functions
  validMessages() {
    if (!this.templateName) {
      this._MessagesService.showErrorMessage(' يجب ادخال اسم القالب');
      return false;
    }

    if (this.templateName.length < 2) {
      this._MessagesService.showErrorMessage('يجب ان يكون اسم القالب اكثر من حرفين');
      return false;
    }

    if (this.templateName.length > 2000) {
      this._MessagesService.showErrorMessage('يجب ان لا يتجاوز محتوي القالب 2000 حرف');
      return false;
    }

    if (!this.chatareaPreviewData.messages?.length) {
      this._MessagesService.showErrorMessage('يجب ادخال وإدراج الرسالة')
      return false;
    }

    return true;
  }

  prepareDataBeforeSent(numbers?): any {
    const data = {
      name: this.templateName,
      messages: [],
    };

    this.chatareaPreviewData.messages.forEach(el => {

      let message: any = {
        data: {},
        numbers: []
      };
      switch (el?.type) {


        case 'image':
        case 'video':
        case 'file':
          message.type = 'file';
          message.otherType = el?.type;
          message.sendMediaAsDocument = true,
            message.url = el?.text.body;
          message.filename = el?.text?.filename || el?.type;
          message.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        case 'location':
          message.type = 'location';
          message.lat = el?.text?.lat;
          message.lng = el?.text?.lng;
          message.otherType = el?.type;
          message.address = el?.text?.address;
          break;

        case 'text':
          message.type = 'text';
          message.body = el?.text?.body;
          message.otherType = el?.type;
          break;

        case 'audio':
          message.type = 'file';
          message.url = el?.text.body;
          message.otherType = el?.type;
          message.sendAudioAsVoice = true
          message.filename = el?.text?.filename || el?.type;
          message.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        default:
          break;
      }


      data.messages.push(message);
    })
    return data;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
