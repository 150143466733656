export const locale = {
  lang: "en",
  data: {
    host: {
      chooseDomain: "اختيار نطاق",
      addToCard: "اضافه الي العربه",
      DomainsPrices: "اسعار النطاقات",
      transfer: "نقل",
      takenMessage: "هذا النطاق محجوز من قبل اذا كنت تمتلكه يمكنك نقله الان.",
      searchDomainNotAv: "غير متاح البحث عن هذا النطاق.",
    },
    general: {
      required: "مطلوب",
      minlength: "يجب ان يكون عدد الاحرف اكثر من ",
      google_authentication: "تحقق جوجل",
      google_message: "لزيادة الحماية ، يوصى باستخدام هذه الخدمة",
      text_authenticator: "التحقق بالرسائل النصية",
      email_verification: "التحقق بالبريد الالكتروني",
      devices_management: "إدارة الاجهزة",
      devices_message: "يمكنك تسجيل الخروج من الاجهزة الاخري المتصلة",
      management: "الادارة",
      password: "كلمة المرور",
      activity_message: "آخر تسجيل دخول",
      activity_record: "سجل الانشطة",
      password_message: "يمكنك تغيير كلمة مرور تسجيل الدخول للنظام",
      change: "تغيير",
      active: "مفعله",
      inactive: "غير مفعله",
    },
    global: {
      sorry: "اسف",
      telegram: "التليجرام",
      confirm: "تحقق",
      refresh: "تحديث",
    },
    senders: {
      support: {
        confirm_payment_sender_names: "تأكيد سداد أسماء الإرسال",
        add_package_request: "طلب اضافة باقة جديدة - مندوب مبيعات",
        confirm_payment_balance_charge: "تأكيد سداد  شحن الرصيد",
        sender_name: "اختر اسم المرسل",
        cost: "التكلفة",
        sender_name_count: "عدد أسماء الإرسال",
        sender_name_cost: "تكلفة الاسم",
        tax: "الضريبة",
        total: "الاجمالي",
        bank: "اختر البنك",
        bank_details: "تفاصيل البنك",
        depositor_bank: "البنك المحول منه",
        depositor_name: "اسم المودع",
        depositor_number: "رقم حساب المودع",
        deposit_date: "تاريخ الايداع",
        deposit_receipt: "صورة الايداع",
        ticket_details: "تفاصيل التذكرة",
        tickets: {
          sender_name: "أسماء الإرسال",
          bank: "محول الى",
          title: "تذاكر الدعم ",
          sales: "المبيعات",
          email: "البريد الالكتروني",
          add: "اضافة تذكرة",
          mobile: "الجوال",
          depositor_bank: "بنك العميل",
          depositor_name: "اسم المودع",
          ticket_status: "حالة التذكرة",
          department: "القسم",
          confirmation_payment_sender: "تأكيد سداد اسم المرسل",
          lastReply: "اخر رد",
          lastReplyTime: "وقت اخر رد",
          type: {
            pending: "غير مؤكد الدفع",
            done: "مؤكدة",
            refuse: "مرفوض",
          },
        },
      },
    },
    setting: {
      security: "الحماية",
    },
    messages: {
      subAccountChangePassword: "برجاء تغيير كلمة المرور لتتمكن من استخدام الموقع",
      renewWarning: "برجاء العلم ان دوام الهيئة من الأحد الي الخميس وبناء عليه طلبات التجديد  التي يتم ارسالها اثناء الاجازة الاسبوعية يتم تجديدها يوم الاحد",
      senderFormAlert: "يجب تصديق التفويض الخاص بكم على ورق رسمي من الغرفة التجارية اولا قبل رفعه في حال وجود سجل تجاري",
      pointPriceAlert: "يجب ان يكون تكلفة النقطه بحد اقصي  3 ارقام بعد العلامه العشريه ",
      subAccountMaxPointsErrorMessage: "يجب ان يكون عدد النقاط يساوي عدد النقاط المطلوبه في الاحتياج ({{maxPoints}}) ",
      noSmsBalannceWordPress: "الرجاء شحن رصيد لتتمكن من استخدام الخدمة",
    },
  },
}
