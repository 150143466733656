<div class="mat-dalog-header bg-primary border-bottom py-5 px-8 mb-8 d-flex justify-content-between">
    <h6 class="mb-0 text-white font-weight-bolder" mat-dialog-title>
        إنشاء رد محفوظ
    </h6>
    <div class="end d-flex align-items-center">
        <button mat-button mat-dialog-close class="min-w-auto line-height-normal px-2 popup-close-btn">
            <i class="fas fa-times text-white"></i>
        </button>
    </div>
</div>

<mat-dialog-content class="mb-5 px-8">   
    <add-new-messages [numberId]="numberId" (afterAdd)="afterAdd.emit()" #addNewMessages class="block"></add-new-messages>
</mat-dialog-content>


<mat-dialog-actions align="end" class="px-8 py-3 border-top m-0">
    <div class="d-flex justify-content-between w-100">
        <div class="end">
            <button (click)="onBack()" class="btn font-weight-bolder px-0 py-1" mat-button>
                رجوع            
            </button>
        </div>
        <div class="start">
            <button mat-dialog-close class="btn font-weight-bolder px-10 py-1" mat-button>
                إلغاء            
            </button>
            <button (click)="submit()" class="btn btn-primary font-weight-bolder px-15 py-1" mat-button>
                إرسال        
            </button>
        </div>
   </div>
</mat-dialog-actions>
