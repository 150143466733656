import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrnningMessagesComponent } from "../../components/wrnning-messages/wrnning-messages.component";



@NgModule({
  declarations: [WrnningMessagesComponent],
  imports: [
    CommonModule
  ],
  exports : [WrnningMessagesComponent]
})
export class MessagesModule { }
