import { Component, Input, OnInit } from '@angular/core';
import { TemplateHeaderType } from "src/app/modules/whats-app/enums/whats-templates";

@Component({
  selector: 'app-official-header-preview',
  templateUrl: './official-header-preview.component.html',
  styleUrls: ['./official-header-preview.component.scss']
})
export class OfficialHeaderPreviewComponent implements OnInit {
  
  @Input() number: any;
  @Input() templateDir: any;
  @Input() formData: any;
  @Input() set header(data) {
    this.selectedImgUrl = data?.image_url;
    this.selectedVideoUrl = data?.video_url;
    this.headerImgUrl = data?.url;
    this.headerData = data;
  };

  headerData;
  headerImgUrl: string = '';
  selectedImgUrl: string = '';
  selectedVideoUrl: string = '';
  headerTypes = TemplateHeaderType;

  constructor() { }

  ngOnInit(): void {
  }

}
