<div class="form-group mt-2">
    <label [for]="'normal_message_data_'+label" class="ml-auto font-weight-bolder" *ngIf="!isWhatsapp">
        {{ label }}
        <span *ngIf="required" class="text-danger">*</span>
    </label>

    <div class="textarea-info mt-2 justify-content-between d-flex align-items-center" *ngIf="isWhatsapp">
        <label [for]="'normal_message_data_'+label" class="ml-auto font-weight-bolder">
            {{ label }}
            <span *ngIf="required" class="text-danger">*</span>
        </label>

        <div class="end d-flex align-items-center mr-auto position-relative"  >
                    <span class="mx-2 px-2 " 
                (click)="showEmojiPicker = !showEmojiPicker" role="button" >
                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24"><path fill="currentColor" d="M9.153,11.603c0.795,0,1.439-0.879,1.439-1.962S9.948,7.679,9.153,7.679 S7.714,8.558,7.714,9.641S8.358,11.603,9.153,11.603z M5.949,12.965c-0.026-0.307-0.131,5.218,6.063,5.551 c6.066-0.25,6.066-5.551,6.066-5.551C12,14.381,5.949,12.965,5.949,12.965z M17.312,14.073c0,0-0.669,1.959-5.051,1.959 c-3.505,0-5.388-1.164-5.607-1.959C6.654,14.073,12.566,15.128,17.312,14.073z M11.804,1.011c-6.195,0-10.826,5.022-10.826,11.217 s4.826,10.761,11.021,10.761S23.02,18.423,23.02,12.228C23.021,6.033,17.999,1.011,11.804,1.011z M12,21.354 c-5.273,0-9.381-3.886-9.381-9.159s3.942-9.548,9.215-9.548s9.548,4.275,9.548,9.548C21.381,17.467,17.273,21.354,12,21.354z  M15.108,11.603c0.795,0,1.439-0.879,1.439-1.962s-0.644-1.962-1.439-1.962s-1.439,0.879-1.439,1.962S14.313,11.603,15.108,11.603z"></path></svg>
                </span>
                <br>
                <emoji-mart class="emoji-mart" set="{{setsEmoji}}" [style]="emojiStyle"
                *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="اختيار"></emoji-mart>
                
            <small class="ml-3">
                <span class="val">{{ messageHandeler.length }} / 1024 </span>
            </small>
        </div>
    </div>

    <textarea [dir]="meassgeDir" [class.text-left]="meassgeDir == 'ltr'" [disabled]="disabled"
        class="form-control" [id]="'normal_message_data_'+label" name="normal_message_data"
        [maxlength]="maxMeassgeLength" [(ngModel)]="message" rows="10"
        (input)="addMessage()" [ngClass]="{
            'text-left': meassgeDir == 'ltr',
            'text-right': meassgeDir == 'rtl'
        }">
    </textarea>

    <div class="textarea-info mt-2 justify-content-between d-flex align-items-center">

        <div *ngIf="showTemplates" class="start ml-2 d-flex">
            <button (click)="openShowSendingTemplatesDialog()" type="button"
                class="btn btn-secondary ml-2 text-smaller text-primary py-1 px-2">
                قوالب الإرسال
            </button>
            <button (click)="openSendingTemplatesDialog()" type="button"
                class="btn btn-secondary text-smaller text-primary py-1 px-2">
                حفظ النص
            </button>
        </div>

        <div class="end d-flex align-items-center mr-auto" *ngIf="!isWhatsapp" >
            <small class="ml-3">
                <span class="key ml-1 text-primary font-weight-bold">عدد المقاطع:</span>
                <span class="val">{{ messageHandeler.messages }} / 10 </span>
            </small>
            <small class="ml-3">
                <span class="key ml-1 text-primary font-weight-bold">عدد الاحرف:</span>
                <span class="val">{{ messageHandeler.length }}</span>
            </small>
            <small>
                <span class="key ml-1 text-primary font-weight-bold">المتبقي:</span>
                <span class="val"> {{ messageHandeler.remaining }} </span>
            </small>
        </div>

        <div class="end d-flex align-items-center mr-auto" *ngIf="isWhatsapp" >
            <ng-content select='[addMessageBtn]'></ng-content>
        </div>

    </div>

    <div *ngIf="showNotes" class="mt-5">
        <app-wrnning-messages
            html='
        <p class="m-0" >ملاحظة للرسائل الانجليزية 160 حرف مقطع اكثر من ذلك يحسب كل 153 حرف نقطة .</p>
<p class="m-0">للرسائل العربية 70 حرف نقطة واحدة اكثر من ذلك يحسب كل 67 حرف نقطة. واقصي عدد من الرسائل المتصله "عدد مقاطع الرساله الواحده" هو 15 مقاطع.</p>'
            [type]="messagesTypesEnum.info" [showClose]="false"></app-wrnning-messages>
    </div>
</div>