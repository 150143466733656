import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from 'rxjs/operators';
import { WhatsMessageType } from "src/app/shared/models/shared";
import { BrowserDbService } from 'src/app/shared/services/browser-db.service';
import { environment } from "src/environments/environment";
import { GalleryList, NumbersList, createNumber } from "../models/whats";

@Injectable({ providedIn: 'root' })
export class WhatsService {
    baseURL: string = environment.apiUrl;
    whatsOfficial: string = environment.whats.apiUrl;
    whatsNewBot: string = environment.whats.newBoot;
    updateDialogs: BehaviorSubject<boolean> = new BehaviorSubject(false);
    resetBootContent: BehaviorSubject<boolean> = new BehaviorSubject(false);
    whatsAppProjectName: BehaviorSubject<string> = new BehaviorSubject(null);

    private updateWhatsNumbersBalance: BehaviorSubject<any> = new BehaviorSubject(null);
    updateWhatsNumbersBalance$ = this.updateWhatsNumbersBalance.asObservable();

    constructor(
        private _HttpClient: HttpClient, 
        private browserDbService: BrowserDbService
    ) {}

    /**
     * Get whats number
     * @returns 
    */
    getNumbers(params): Observable<NumbersList> {
        const url = `${this.baseURL}/account/whatsapp/numbers/list`
        return this._HttpClient.get<any>(url, {
            params: params
        });
    }
    /**
     * Get whats Messages
     * @returns 
    */
    getMessages(params: any, url?: any): Observable<any> {
        return this._HttpClient.get(url ? url : `${this.baseURL}/account/whatsapp/messages?order_by_type=desc`, { params: params })
            .pipe(
                map((response: any) => {
                    response.paginate.data.reverse();
                    return response;
                })
            );
    }

    /**
     * Get whats Messages
     * @returns 
    */
    getٍSpool(params, hide?): Observable<any> {
        const url = hide ? `${this.baseURL}/account/whatsapp/messages-notes?hide=true` :
            `${this.baseURL}/account/whatsapp/messages-notes`
        return this._HttpClient.get(url, {
            params: params
        });
    }

    /**
     * Export whats Messages
     * @returns 
    */
    ExportMessages(params): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/messages`
        return this._HttpClient.get(url, {
            responseType: 'blob',
            observe: 'response',
            params: params
        });
    }

    /**
     * Store File To Whats Gallery
     * @returns 
    */
    addToGallery(body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/gallery/store`
        return this._HttpClient.post(url, body);
    }

    /**
     * Get getWhatsGallery
     * @returns 
    */
    getWhatsGallery(params): Observable<GalleryList> {
        const url = `${this.baseURL}/account/whatsapp/gallery`
        return this._HttpClient.get<any>(url, {
            params
        });
    }

    /**
     * Delete gallery item
     * @returns 
    */
    deleteWhatsGallery(uuid): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/gallery/${uuid}/delete`
        return this._HttpClient.delete(url);
    }

    /**
     * Get number packages
     * @returns 
    */
    getNumberPackages(params?): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/numbers/numbers-packages`
        return this._HttpClient.get(url, {
            params: params
        });
    }

    /**
     * Get number packages
     * @returns 
    */
    sendMessage(whatsapp_number_id, body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${whatsapp_number_id}/send-bulk`
        return this._HttpClient.post(url, body);
    }

    /**
     * Get number packages
     * @returns 
    */
    sendOneMessage(whatsapp_number_id, body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${whatsapp_number_id}`
        return this._HttpClient.post(url, body);
    }

    /**
     * Create whats number
     * @returns 
    */
    createNumber(body): Observable<createNumber> {
        const url = `${this.baseURL}/account/whatsapp/numbers/create`
        return this._HttpClient.post(url, body);
    }

    /**
     * Send to whatsApp gate
     * @returns 
    */
    whatsGate(body: any, numberId, hide?, method?): Observable<any> {
        const meth = method ? method : 'get'
        const baseUrl = `${this.baseURL}/account/whatsapp/${numberId}`;
        const url = hide ? `${this.baseURL}/account/whatsapp/${numberId}?hide=true` : baseUrl;

        return method ? this._HttpClient[meth](url, body) : this._HttpClient[meth](url, {
            params: {
                ...body
            }
        });
    }

    /**
     * Get full instance status
     * @returns 
    */
    getFullInstanceStatus(body: any, numberId): Observable<any> {
        const baseUrl = `${this.baseURL}/account/whatsapp/${numberId}?path=instances/status&params[full]=true`;
        return this._HttpClient.get(baseUrl, {
            headers: new HttpHeaders({
                'accoun-instance-id': numberId
            })
        });
    }

    /**
     * Get full instance status
     * @returns 
    */
    getContacts(numberId): Observable<any> {
        const baseUrl = `${this.baseURL}/account/whatsapp/${numberId}?path=instances/contacts`;
        return this._HttpClient.get(baseUrl, {
            headers: new HttpHeaders({
                'accoun-instance-id': numberId
            })
        });
    }

    /**
     * Get user contacts details 
     * @returns
    */
    getDialogs(numberId): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}?path=message/dialogs&params[sync]=1`;
        return this._HttpClient.get(url);
    }

    /**
     * Delete message
     * @returns
    */
    deleteWhatsMessage(numberId, phone, messageId): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}?path=message/delete`;
        // const url = `${this.baseURL}/account/whatsapp/${numberId}?path=message/delete&params[phone]=${phone}&params[messageId]=${messageId}`;
        return this._HttpClient.post(url, {
            params: {
                phone,
                messageId
            }
        });
    }

    // Packages
    /**
     * Get packages
     * @returns 
    */
    getPackagesList(params?): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/packages/list`
        return this._HttpClient.get(url, {
            params: params
        });
    }

    // Get website thumbnail
    getSiteThumbnail(url): Observable<any> {
        const endpoint = `${this.baseURL}/account/whatsapp/link-preview?url=${url}`
        return this._HttpClient.get(endpoint);
    }

    // Shared
    getMessageType(type: WhatsMessageType) {
        switch (type) {
            case 'ptt':
                return 'ملف صوتي'
            case 'link':
                return 'رابط'
            case 'forward':
                return 'رساله محوله'
            case 'location':
                return 'موقع'
            case 'text':
                return 'رساله نصيه'
            case 'file':
                return 'ملف'
            case 'contact':
                return 'جهة اتصال'
            case 'vcard':
                return 'Vcard'
            default:
                break;
        }
    }

    // Conversation
    /**
     * Get user contacts 
     * @returns
    */
    getConversations(url: string, params: any): Observable<any> {
        return this._HttpClient.get(url ? url : `${this.baseURL}/account/whatsapp/new-dialogs`, { params });
    }

    // Message Templates
    /**
     * Get Templates
     * @returns
    */
    getMessageTemplate(params): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/templates`;
        return this._HttpClient.get(url, {
            params
        });
    }

    /**
     * Add Template
     * @returns 
    */
    addMessageTemplate(body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/templates/store`
        return this._HttpClient.post(url, body);
    }

    /**
     * Delete template
     * @returns 
    */
    deleteTemplate(id): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/templates/${id}/delete`
        return this._HttpClient.delete(url);
    }

    /** 
    *  
    */
    updateWebHook(body: any): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/numbers/update-webhook`;
        return this._HttpClient.patch(url, body);
    }

    /** 
    *  
    */
    getServiceStatus(whatsapp_number_id): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/paid-status/${whatsapp_number_id}`;
        return this._HttpClient.get(url);
    }

    /** 
    *  
    */
    getUserNumberPermission(whatsapp_number_id): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/numbers/${whatsapp_number_id}/prms`;
        return this._HttpClient.get(url);
    }

    // Whats Auto Replay
    /**
     * Get number packages
     * @returns 
    */
    getAutoReplies(params?): Observable<any> {
        // const url = `${this.baseURL}/account/whatsapp/numbers/numbers-packages`
        // return this._HttpClient.get(url, {
        //     params: params
        // });
        return of([
            {
                index: '',
                name: 'Test',
                phone: '201065847554',
                last_change: 'من 7  ساعات مضت',
                status: 1,
                statusView: 'فعال',
            },
            {
                index: '',
                name: 'Test',
                phone: '201065847554',
                last_change: 'من 7  ساعات مضت',
                status: 1,
                statusView: 'فعال',
            },
            {
                index: '',
                name: 'Test',
                phone: '201065847554',
                last_change: 'من 7  ساعات مضت',
                status: 2,
                statusView: 'غير فعال',
            },
            {
                index: '',
                name: 'Test',
                phone: '201065847554',
                last_change: 'من 7  ساعات مضت',
                status: 1,
                statusView: 'فعال',
            },
        ])
    }

    /**
     * Get number packages
     * @returns 
    */
    getAutoRepliesContent(params?): Observable<any> {
        // const url = `${this.baseURL}/account/whatsapp/numbers/numbers-packages`
        // return this._HttpClient.get(url, {
        //     params: params
        // });
        return of([
            {
                index: '',
                id: 1,
                name: 'البدايه',
                show: true,
                values: {
                    addSendBoot: false,
                    addReceivedBoot: false,
                    addActionBoot: false,
                },


            },
            {
                index: '',
                id: 2,
                name: 'النهايه',
                show: true,
                values: {
                    addSendBoot: false,
                    addReceivedBoot: false,
                    addActionBoot: false,
                },

            },
        ])
    }

    /**
     * Get number packages
     * @returns 
    */
    getAutoRepliesMessageContent(params?): Observable<any> {
        // const url = `${this.baseURL}/account/whatsapp/numbers/numbers-packages`
        // return this._HttpClient.get(url, {
        //     params: params
        // });
        return of([
            {
                index: '',
                id: 2,
                name: 'إرسال  ',
                show: true,
                rowBgStatus: 2,
                messages: [
                    {
                        index: 1,
                        content: 'dssdsd',
                    },
                    {
                        index: 1,
                        content: 'xxx',
                    },

                ]
            },
            {
                index: '',
                name: 'استقبال  ',
                show: true,
                id: 3,
                rowBgStatus: 11,
                messages: [
                    {
                        index: 1,
                        content: 'dssdsd',
                    },
                    {
                        index: 1,
                        content: 'xxx',
                    },

                ]
            },
            {
                index: '',
                name: 'حدث  ',
                id: 4,
                show: true,
                rowBgStatus: 12,
                messages: [
                    {
                        index: 1,
                        content: 'sds',
                    },
                    {
                        index: 1,
                        content: 'aaa',
                    },
                ]
            },
        ])
    }

    // New Whats official API

    getOfficialTemplates(numberId, params = {}): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}?path=templates`;
        return this._HttpClient.get(url, {
            params
        });
    }

    addOfficialTemplates(numberId, body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}`;
        return this._HttpClient.post(url, body);
    }

    sendOfficialMessageTemplate(numberId, body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}?path=message/template`;
        return this._HttpClient.post(url, body);
    }

    getOfficialTemplatesFromOurDataBase(numberId: any, params: any): Observable<any> {
        return this._HttpClient.get(`${this.baseURL}/account/whatsapp/templates/${numberId}`, { params });
    }

    refreshOfficialTemplates(numberId: any): Observable<any> {
        return this._HttpClient.get(`${this.baseURL}/account/whatsapp/templates-update/${numberId}`);
    }

    deleteOfficialTemplate(numberId: any,body): Observable<any> {
        const url = `${this.baseURL}/account/whatsapp/${numberId}`;
        return this._HttpClient.post(url,body);
    }
    // deleteOfficialTemplate(numberId: any,tempId): Observable<any> {
    //     return this._HttpClient.delete(`${this.baseURL}/account/whatsapp/templates/${numberId}/${tempId}/delete`);
    // }

    updateWhatsBalance(val) {
        this.updateWhatsNumbersBalance.next(val);
    }

    getLabels(params: any): Observable<any> {
        return this._HttpClient.get(`${this.baseURL}/account/whatsapp/labels`, { params });
    }

    addLabel(data: any): Observable<any> {
        return this._HttpClient.post(`${this.baseURL}/account/whatsapp/labels/store`, data);
    }

    addLabelToChat(data: any): Observable<any> {
        return this._HttpClient.patch(`${this.baseURL}/account/whatsapp/labels/assign-label`, data);
    }

    removeLabelFromChat(data: any): Observable<any> {
        return this._HttpClient.patch(`${this.baseURL}/account/whatsapp/labels/remove-label`, data);
    }

    updateChatbotStatus(data: any,token): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `${token}`,
            'Content-Type': 'application/json',
        });
        return this._HttpClient.put(`${this.whatsNewBot}users_data/update_user_settings`, data, { headers });
    }

    getChatbotStatus(params: any,token): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `${token}`,
            'Content-Type': 'application/json',
        });
        return this._HttpClient.get(`${this.whatsNewBot}users_data/get_user_settings`, { params,headers });
    }

    getProfileData(numberId: number, params: any): Observable<any> {
        return this._HttpClient.get(`${this.baseURL}/account/whatsapp/${numberId}`, { params });
    }

    saveProfileData(numberId: number, data: any): Observable<any> {
        return this._HttpClient.post(`${this.baseURL}/account/whatsapp/${numberId}`, data);
    }

    // Onbording
    finishOnboarding(whatsapp_number_id: number, data: any): Observable<any> {
        return this._HttpClient.post(`${this.baseURL}/account/whatsapp/on-boarding/${whatsapp_number_id}`, data);
    }

}