<div class="network__status d-flex align-items-center justify-content-center " *ngIf="!networkStatus">

  <div class="network__status__icon">
    <!-- <img src="assets/img/icons/wifi.png" alt="Network Issue"> -->
  </div>

  <div class="network__status__text p-8 text-center">
    <h2 class="font-weight-bolder font-size-h1 mb-2">
      اتصل بالإنترنت
    </h2>
    <p class="font-size-h4 mb-3">
      أنت غير متصل بالإنترنت. تحقق من اتصالك.
    </p>

    <app-button class="w-24 d-block cursor-pointer m-auto"
      panelClass="btn btn-primary font-weight-bold text-uppercase px-9 py-4" (click)="retry()">
      <label class="cursor-pointer m-0">
        اعادة المحاولة
      </label>
    </app-button>
  </div>

</div>