<!-- <div class="whatsapp-chat-author mb-3" *ngIf="number" [ngClass]="{
    'text-left': templateDir == 'ltr',
    'text-right': templateDir == 'rtl'
}">
    {{number?.owner_name}}
</div> -->

<ng-container [ngSwitch]="headerData?.format">

    <ng-container *ngSwitchCase="headerTypes.Img">
        <div class="whatsapp-chat-media mb-2" *ngIf="!formData?.imgUrl && !selectedImgUrl">
            <i class="fa fa-image text-dark-50"></i>
        </div>

        <div 
            class="whatsapp-chat-media-img mb-2" 
            *ngIf="formData?.imgUrl || selectedImgUrl" 
            [ngStyle]="{'background-image': 'url(' + (formData?.imgUrl || selectedImgUrl) + ')'}" 
        >
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="headerTypes.txt">
        <div [ngClass]="{
            'text-left': templateDir == 'ltr',
            'text-right': templateDir == 'rtl'
        }">
            <strong>
                {{headerData?.text}}
            </strong>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="headerTypes.video">
        <div class="whatsapp-chat-media mb-2" *ngIf="!formData?.videoUrl && !selectedVideoUrl">
            <i class="fa fa-video text-dark-50"></i>
        </div>

        <div class="whatsapp-chat-media-video mb-2" *ngIf="formData?.videoUrl || selectedVideoUrl">
            <video width="100%" height="auto" [src]="formData?.videoUrl || selectedVideoUrl" controls>
            </video>
        </div>
    </ng-container>
</ng-container>