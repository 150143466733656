<div class="preview_buttons">
    <div class="preview_button" *ngFor="let item of buttons" >
        {{item?.text}}
        <ng-container [ngSwitch]="item?.type">
            <ng-container *ngSwitchCase="btnTypes.phone">
                <i class="fa fa-phone-alt font-size-16 me-1"></i>
            </ng-container>
            <ng-container *ngSwitchCase="btnTypes.url">
                <i class="fa fa-link font-size-16 me-1"></i>
            </ng-container>
            <ng-container *ngSwitchCase="btnTypes.otp_type_copy">
                <i class="fa fa-copy font-size-16 me-1"></i>
            </ng-container>
        </ng-container>   
    </div>
</div>