import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AukWhatsappRoutingModule } from './auk-whatsapp-routing.module';
import { AukWhatsappComponent } from './auk-whatsapp.component';
import { ChatAreaComponent } from './components/chat-area/chat-area.component';
import { ChatDefaultPageComponent } from './components/chat-area/chat-default-page/chat-default-page.component';
import { ChatRoomComponent } from './components/chat-area/chat-room/chat-room.component';
import { FileCardComponent } from './components/chat-area/chat-room/components/file-card/file-card.component';
import { ImageCardComponent } from './components/chat-area/chat-room/components/image-card/image-card.component';
import { PdfCardComponent } from './components/chat-area/chat-room/components/pdf-card/pdf-card.component';
import { ShareMenuComponent } from './components/share-menu/share-menu.component';
import { SidebarContentComponent } from './components/sidebar/sidebar-content/sidebar-content.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { DebounceInputChangeDirective } from "./directives/search.directive";
// import { AgmCoreModule } from "@agm/core";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { NgbDropdownModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomDateFormatPipe } from 'src/app/_metronic/core/pipes/custom-date-format.pipe';
import { GoogleMapModule } from "src/app/shared/shared-modules/google-map/google-map.module";
import { LoadingModule } from "src/app/shared/shared-modules/loading/loading.module";
import { MessageAreaModule } from 'src/app/shared/shared-modules/message-area/message-area.module';
import { AddNewMessagesComponent } from './components/add-new-messages/add-new-messages.component';
import { SendFileComponent } from './components/add-new-messages/components/send-file/send-file.component';
import { SendImageComponent } from './components/add-new-messages/components/send-image/send-image.component';
import { SendLocationComponent } from './components/add-new-messages/components/send-location/send-location.component';
import { SendVideoComponent } from './components/add-new-messages/components/send-video/send-video.component';
import { SendVoiceComponent } from './components/add-new-messages/components/send-voice/send-voice.component';
import { WhatsappSendSmsComponent } from './components/add-new-messages/components/whatsapp-send-sms/whatsapp-send-sms.component';
import { AddLabelComponent } from './components/chat-area/chat-room/components/add-label/add-label.component';
import { ContactCardComponent } from './components/chat-area/chat-room/components/contact-card/contact-card.component';
import { ConvertConversationComponent } from './components/chat-area/chat-room/components/convert-conversation/convert-conversation.component';
import { IsDeleverdComponent } from './components/chat-area/chat-room/components/is-deleverd/is-deleverd.component';
import { IsPendingComponent } from './components/chat-area/chat-room/components/is-pending/is-pending.component';
import { IsReadComponent } from "./components/chat-area/chat-room/components/is-read/is-read.component";
import { IsSentComponent } from './components/chat-area/chat-room/components/is-sent/is-sent.component';
import { LocationCardComponent } from './components/chat-area/chat-room/components/location-card/location-card.component';
import { WhatsInteractiveMessageComponent } from './components/chat-area/chat-room/components/whats-interactive-message/whats-interactive-message.component';
import { WhatsOfficialTemplateMessageComponent } from './components/chat-area/chat-room/components/whats-official-template-message/whats-official-template-message.component';
import { ChatareaPreviewComponent } from './components/chatarea-preview/chatarea-preview.component';
import { ChatRoomPreviewComponent } from './components/chatarea-preview/components/chat-room-preview/chat-room-preview.component';
import { WhatsOfficialTemplateComponent } from './components/chatarea-preview/components/chat-room-preview/whats-official-template/whats-official-template.component';
import { WhatsappLocationCardComponent } from './components/chatarea-preview/components/whatsapp-location-card/whatsapp-location-card.component';
import { AddEditResponseComponent } from './components/saved-responses-dialog/components/add-edit-response/add-edit-response.component';
import { EditMessageTemplateComponent } from './components/saved-responses-dialog/components/edit-message-template/edit-message-template.component';
import { OfficialButtonsPreviewComponent } from './components/saved-responses-dialog/components/official-preview/official-buttons-preview/official-buttons-preview.component';
import { OfficialHeaderPreviewComponent } from './components/saved-responses-dialog/components/official-preview/official-header-preview/official-header-preview.component';
import { OfficialPreviewComponent } from './components/saved-responses-dialog/components/official-preview/official-preview.component';
import { PreviewAndSendComponent } from './components/saved-responses-dialog/components/preview-and-send/preview-and-send.component';
import { SavedResponsesDialogComponent } from './components/saved-responses-dialog/saved-responses-dialog.component';

@NgModule({
  declarations: [
    SendFileComponent,
    SendVoiceComponent,
    SendImageComponent,
    SendVideoComponent,
    WhatsappSendSmsComponent,
    SendLocationComponent,
    SidebarComponent,
    ChatRoomComponent,
    ChatAreaComponent,
    PdfCardComponent,
    ShareMenuComponent,
    AukWhatsappComponent,
    ClickOutsideDirective,
    SidebarContentComponent,
    ChatDefaultPageComponent,
    AddLabelComponent,
    ImageCardComponent,
    FileCardComponent,
    DebounceInputChangeDirective,
    IsSentComponent,
    IsPendingComponent,
    IsReadComponent,
    IsDeleverdComponent,
    ContactCardComponent,
    ConvertConversationComponent,
    LocationCardComponent,
    ChatareaPreviewComponent,
    ChatRoomPreviewComponent,
    WhatsappLocationCardComponent,
    SavedResponsesDialogComponent,
    AddEditResponseComponent,
    PreviewAndSendComponent,
    AddNewMessagesComponent,
    EditMessageTemplateComponent,
    OfficialPreviewComponent,
    OfficialButtonsPreviewComponent,
    OfficialHeaderPreviewComponent,
    WhatsOfficialTemplateComponent,
    WhatsOfficialTemplateMessageComponent,
    WhatsInteractiveMessageComponent,
    CustomDateFormatPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    FormsModule,
    NgbNavModule,
    DragDropModule,
    NgSelectModule,
    GoogleMapModule,
    MatDialogModule,
    NgbDropdownModule,
    MessageAreaModule,
    ReactiveFormsModule,
    AukWhatsappRoutingModule,
    NgbPopoverModule,
    LoadingModule,
    InfiniteScrollModule
  ],
  exports: [
    ChatareaPreviewComponent,
    AukWhatsappComponent,
    AddEditResponseComponent,
    PreviewAndSendComponent,
    EditMessageTemplateComponent,
    OfficialPreviewComponent,
    OfficialButtonsPreviewComponent,
    OfficialHeaderPreviewComponent,
    CustomDateFormatPipe
    // ChatRoomPreviewComponent,
  ]
})
export class AukWhatsappModule { }
