export enum TemplateStatus {
    reject = "rejected",
    approve = "approved",
}

export enum TemplateCategories {
    Marking = "MARKETING",
    Authentication = "AUTHENTICATION",
    Utility = 'UTILITY'
}

export enum TemplateHeaderType {
    Img = "IMAGE",
    video = "VIDEO",
    txt = "TEXT",
    document = "DOCUMENT",
}

export enum TemplateComponentsTypes {
    Header = "HEADER",
    Body = 'BODY',
    Footer = 'FOOTER',
    Button = 'BUTTONS',
}

export enum TemplateButtonsTypes {
    phone = "PHONE_NUMBER",
    url = "URL",
    replay = 'QUICK_REPLY',
    otp = "OTP",
    otp_type_copy = "COPY_CODE",
    otp_type_copy2 = "copy_code"
}