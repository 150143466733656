import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MessagesService } from 'src/app/shared/services/messages.service';

@Component({
  selector: 'chat-room-preview',
  templateUrl: './chat-room-preview.component.html',
  styleUrls: ['./chat-room-preview.component.scss'],
})
export class ChatRoomPreviewComponent implements OnInit {
  
  @ViewChild('chatArea', { static: true }) chatArea: ElementRef;

  @Input() messagesArr: any[] = [];
  @Input() selectedProjectType: any;
  @Input() officialTemplate: any;
  @Output() messagesArrChange = new EventEmitter();


  messagesArrTemp = [
    {
      "text": {
        "body": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع"
      },
      messageTime: '11:27:29 am',
      "type": "text",
      "sent_status": "send",
    },
    // {
    //   "text": {
    //     "body": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع server to server communication server to server communication",
    //     lat: 33.5722601,
    //     lng: -7.6695644,
    //     address: 'Bd Sidi Abderrahmane, Casablanca 20250, Morocco',
    //   },
    //   messageTime: '11:27:29 am',
    //   "type": "location",
    //   "sent_status": "send",
    // },
    // {
    //   "text": {
    //     "caption": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع"
    //   },
    //   messageTime: '11:27:29 am',
    //   "type": "image",
    //   "imagPath": "https://singleclicit.visualstudio.com/_apis/GraphProfile/MemberAvatars/aad.ZjU0YTY4NjUtYTYzMC03ODFlLWJmMTUtOGE2YzNjMzNhYWIz?size=2&1684654972671",
    //   "sent_status": "send",
    // },
    // {
    //   "text": {
    //     "body": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع"
    //   },
    //   messageTime: '11:27:29 am',
    //   "type": "video",
    //   "name": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع",
    //   "sent_status": "send",
    // },
    // {
    //   "text": {
    //     "body": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع",
    //     filename: 'لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع',
    //     caption: 'لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع'
    //   },
    //   messageTime: '11:27:29 am',
    //   "type": "file",
    //   "name": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع",
    //   "sent_status": "send",
    //   fileType: 'TXT'
    // },
    // {
    //   "text": {
    //     "body": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع",
    //     filename: 'لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع',
    //     caption: 'لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع'
    //   },
    //   messageTime: '11:27:29 am',
    //   "type": "audio",
    //   "name": "لو مش هيبقي فيه تجديد تلقائي مش هنحتاج موضوع",
    //   "sent_status": "send",
    // },
  ];

  shouldScroll: boolean = false;

  constructor(
    private _MessagesService: MessagesService
  ) { }

  ngOnInit(): void {
  }

  scrollToBottom() {
    this.chatArea.nativeElement.scroll({
      top: this.chatArea.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  onChatAreaScroll(e): void {
    if (this.chatArea.nativeElement.scrollTop < -100) {
      this.shouldScroll = true
    } else {
      this.shouldScroll = false;
    }
  }

  trackBy(index, item){
    return item.id; 
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.messagesArr, event.previousIndex, event.currentIndex);
  }


  confirmDeleteMessage(index): void {
    
    this._MessagesService.showConfirmDeleteMessage().then( res => {
      
      if (res?.isConfirmed) {
        this.deleteMessage(index);
      }
    })
  }



  deleteMessage(index): void {
    this.messagesArr.splice(index, 1);
  }

}
