<!--Start:: no data found -->
<div class="info alert d-flex justify-content-center p-5 mb-0" role="alert">
    <div class="message d-flex align-items-center flex-column">
        <div class="d-flex">
            <i class="fas fa-info-circle"></i>
            <p class="mx-3">
                <span class="font-weight-bolder">
                    {{message}}
                    <ng-content></ng-content>
                </span>
            </p>
        </div>
    </div>
</div>
<!--End:: no data found -->