import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridPaginationComponent } from "./components/ag-grid/ag-grid-pagination/ag-grid-pagination.component";
import { AgGridComponent } from "./components/ag-grid/ag-grid.component";
import { AgActionBtnComponent } from "./components/ag-grid/cell-renderers/ag-action-btn/ag-action-btn.component";
import { AgAutocompleteComponent } from "./components/ag-grid/cell-renderers/ag-autocomplete/ag-autocomplete";
import { AgImageFormatterComponent } from "./components/ag-grid/cell-renderers/ag-imageFormatter/ag-image-formatter.component";
import { AgIsDefaultBtnComponent } from "./components/ag-grid/cell-renderers/ag-is-default-btn/ag-is-default-btn.component";
import { AgStatusBtnComponent } from "./components/ag-grid/cell-renderers/ag-status-btn/ag-status-btn.component";
import { AgAutocompleteStudentsComponent } from "./components/ag-grid/cell-renderers/ag-autocomplete-students/ag-autocomplete-students.component";
import { AgChangeBillClaimingComponent } from "./components/ag-grid/cell-renderers/ag-change-bill-claiming/ag-change-bill-claiming.component";
import { AgCheckboxComponent } from "./components/ag-grid/cell-renderers/ag-checkbox/ag-checkbox.component";
import { AgCustodiesBtnComponent } from "./components/ag-grid/cell-renderers/ag-custodies-btn/ag-custodies-btn.component";
import { AgDefaultBtnComponent } from "./components/ag-grid/cell-renderers/ag-default-btn/ag-default-btn.component";
import { AgLinkColumnComponent } from "./components/ag-grid/cell-renderers/ag-link-column/ag-link-column.component";
import { AgSetPresentBtnComponent } from "./components/ag-grid/cell-renderers/ag-set-present-btn/ag-set-present-btn.component";
import { AgTagesCellComponent } from "./components/ag-grid/cell-renderers/ag-tages-cell/ag-tages-cell.component";
import { CustodiesAgLinkComponent } from "./components/ag-grid/cell-renderers/custodies/custodies-ag-link/custodies-ag-link.component";
import { AgGridModule } from "ag-grid-angular";
import 'ag-grid-enterprise';
import { RouterModule } from "@angular/router";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { WhatsMessageCellComponent } from './components/ag-grid/cell-renderers/whats-message-cell/whats-message-cell.component';
import { WhatsStatusCellComponent } from './components/ag-grid/cell-renderers/whats-status-cell/whats-status-cell.component';
import { AgBadgeComponent } from './components/ag-grid/cell-renderers/ag-badge/ag-badge.component';
// import { AgmCoreModule } from "@agm/core";
import { AgGridPopoverComponent } from './components/ag-grid/cell-renderers/ag-grid-popover/ag-grid-popover.component';
import { AgTemplateContentComponent } from "src/app/modules/whats-app/components/whats-send-message/whats-send-message-new/messages-template-dialog/ag-template-content/ag-template-content.component";
import { GoogleMapModule } from "../google-map/google-map.module";
import { HeaderTemplateComponent } from './components/ag-grid/header-template/header-template.component';
import { HeaderWithSelectComponent } from './components/ag-grid/header-with-select/header-with-select.component';
import { AgGridCustomComponent } from './components/ag-grid/ag-gridCustom/ag-gridCustom.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule } from "@angular/material/button";
import { PaginationModule } from "../pagination/pagination.module";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AukWhatsappModule } from "../../../modules/auk-whatsapp/auk-whatsapp.module";


@NgModule({
    declarations: [
        AgGridComponent,
        AgGridCustomComponent,
        AgAutocompleteComponent,
        AgActionBtnComponent,
        AgImageFormatterComponent,
        AgStatusBtnComponent,
        AgIsDefaultBtnComponent,
        AgGridPaginationComponent,
        AgSetPresentBtnComponent,
        AgTagesCellComponent,
        AgChangeBillClaimingComponent,
        AgAutocompleteStudentsComponent,
        AgCustodiesBtnComponent,
        AgLinkColumnComponent,
        AgCheckboxComponent,
        CustodiesAgLinkComponent,
        AgDefaultBtnComponent,
        WhatsMessageCellComponent,
        WhatsStatusCellComponent,
        AgBadgeComponent,
        AgGridPopoverComponent,
        AgTemplateContentComponent,
        HeaderTemplateComponent,
        HeaderWithSelectComponent
    ],
    exports: [
        AgGridComponent,
        AgGridCustomComponent,
        AgAutocompleteComponent,
        AgActionBtnComponent,
        AgImageFormatterComponent,
        AgStatusBtnComponent,
        AgIsDefaultBtnComponent,
        AgGridPaginationComponent,
        AgSetPresentBtnComponent,
        AgTagesCellComponent,
        AgChangeBillClaimingComponent,
        AgAutocompleteStudentsComponent,
        AgCustodiesBtnComponent,
        AgLinkColumnComponent,
        AgCheckboxComponent,
        CustodiesAgLinkComponent,
        AgDefaultBtnComponent,
    ],
    imports: [
        CommonModule,
        AgGridModule,
        RouterModule,
        MatMenuModule,
        MatIconModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        GoogleMapModule,
        MatButtonModule,
        PaginationModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatFormFieldModule,
        NgbProgressbarModule,
        AukWhatsappModule
    ]
})
export class AgGridTableModule { }
