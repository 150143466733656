<button
  class="w-full h-11 relative font-semibold border text-sm rounded-md"
  [disabled]="disabled"
  [class]="panelClass"
  [ngClass]="{
    'bg-primary border-primary': fill == '',
    'bg-white text-primary': fill == 'primary-stroke',
    'text-white': fill == '' || fill == 'success',
    '!text-primary bg-secondary': fill == 'secondary',
    'border-none bg-green-500': fill == 'success',
    'opacity-50': disabled
  }"
>
  <ng-content select="*"></ng-content>
</button>
