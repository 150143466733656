import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { AppInsightService } from "./app-insight.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private _AppInsightService: AppInsightService) {
    }

    handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {

        } else {
            this._AppInsightService.logException(error);
        }
    }

}