export class Constant {
  // local storage key
  public static user = "user"
  public static token = "token"
  public static language = "language"
  public static smsAuthData = "smsAuthData"
  public static SallaToken = "sallaToken"
  public static currentUser = "currentUser"
  public static MainUser = "mainUser"
  public static HOST_TOKEN = "hostToken"
  public static isLoginFromAdmin = "loginAsAdmin"
  public static whatsToken = "w_t"
  public static whatsBootSecretKey = "w_b_k"
  public static whatsBootToken = "w_b_t"
  public static importNumberFromFile = "importNumberFromFile"
  

  // Shared

  public static JwalyMeetLink = "https://meet.4jawaly.com/"

  // regex
  public static regexEmail = RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$")

  public static regexPhone = RegExp(/^(01)([0-9]{9})$/)
  public static regexPassword = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
  public static notSpecialCharacters = RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g)
  public static notAllowSpace = RegExp(/(\s)/g)

  public static allowArabicLanguage = RegExp(/[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]/g)

  public static defaultMaxNumber = 2147483647
  public static notStartWithZero = RegExp("^[^1-9]")
  public static notContainsNumber = RegExp(".*\\d+.*")
  public static egyptMobile = RegExp("^01[0125][0-9]{8}$")
  public static notStartWithNumber = RegExp(/^(?![0-9_])/g)
  public static startWithZero = RegExp(/\b1*([1-9][1-9]*|0)\b/g)
  public static passportNumber = RegExp("^[a-zA-Z]{1,1}[0-9]{8,8}$")
  public static regexURL = RegExp(/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/g)
}
