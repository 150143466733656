import { LOCALE_ID, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { authInterCeptor } from "../../interceptoors/auth-interceptop/auth.interceptor"
import { ErrorHandlerInterceptor } from "../../interceptoors/error-interceptors/error.interceptor"
import { LoaderInterceptor } from "../../interceptoors/loader/loader.interceptor"
import { TranslateModule } from "@ngx-translate/core"
import { BasicAuthInterceptor } from "../../interceptoors/basic-auth-interceptoor/basic-auth-interceptor"

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslateModule.forRoot()],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: authInterCeptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoaderInterceptor,
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: "en-GB" },
  ],
  exports: [TranslateModule],
})
export class CoreModule {}
