import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { HttpErrorResponseStatus, TableActions } from "src/app/shared/models/shared";
import { HelperService } from "src/app/shared/services/helpers.service";
import { MessagesService } from "src/app/shared/services/messages.service";
import { AgActionBtnComponent } from "src/app/shared/shared-modules/ag-grid/components/ag-grid/cell-renderers/ag-action-btn/ag-action-btn.component";
import { environment } from "src/environments/environment";
import { SmsService } from "../../../services/sms.service";

@Component({
  selector: 'app-show-sending-templates-dialog',
  templateUrl: './show-sending-templates-dialog.component.html',
  styleUrls: ['./show-sending-templates-dialog.component.scss']
})
export class ShowSendingTemplatesDialogComponent implements OnInit {
  total: number = 0;
  dataLoaded: boolean = false;
  subscription: Subscription = new Subscription();
  // Ag-Grid
  columnDefs = [];
  defaultColDef = {
    filter: false,
    editable: false,
    resizable: true,
    // wrapText: true,
    cellClass: "grid-cell-centered",
    headerClass: 'grid-header-centered',
  };
  pageOptions = {
    page: 1,
    paginationSizes: [],
    defaultPageSize: environment.take,
    length: 10
  }
  tableItems: any[] = [];
  tableColumns = [];
  frameworkComponents: any;
  tableData = []
  filters: any = {
    page: 1,
    search_word: '',
    take: environment.take,
  }
  constructor(
    private _Sms: SmsService,
    private _Helpers: HelperService,
    private _Message: MessagesService,
    public _MatDialogRef: MatDialogRef<ShowSendingTemplatesDialogComponent>,
  ) {
    this.frameworkComponents = {
      agActionBtn: AgActionBtnComponent,
    };

  }

  ngOnInit(): void {
    this.getTableItems();
  }

  getTableItems(): void {
    let filters = this.filters;
    this.subscription.add(
      this._Sms.getMessageTemplates(filters)
        .subscribe((res: any) => {
          
          
          this.tableData = res?.items?.data?.map((item) => {
            return {
              ...item,
              ShowSelect: true,
            }
          });
          this.tableItems = this.tableData;
          this.pageOptions.length = res?.items?.total;
          this.total = this.filters.search_word ? this.total : res?.items?.total;
          this.dataLoaded = true;
          this.initializeAgGrid();
        }, error => {
          this.tableData = [];
          this.dataLoaded = true;
        }));
  }

  initializeAgGrid(): void {
    this.drawAgGrid(); // call drawAgGrid Function
  }

  /**
   * @description A function that draws AG Grid
   */
  drawAgGrid(): void {
    if (!this.columnDefs.length) {
      this.columnDefs = [
        {
          headerName: 'العنوان ',
          field: 'name',
          maxWidth: 250,
          wrapText: true,
          autoHeight: true,
          cellStyle: function (params) {
            let style: any = {
              'white-space': 'normal',
              'word-break': 'break-word',
            }
            return style;
          },
        },
        {
          headerName: 'نص الرسالة',
          field: 'text',
          autoHeight: true,
          minWidth: 250,
          maxWidth: 700,
          cellStyle: function (params) {
            let style: any = {
              'white-space': 'normal',
              'word-break': 'break-word',
              'line-height': 1.7
            }
            style['direction'] = params.value?.match(/[\u0600-\u06FF\u0750-\u077F]/) ? "rtl" : "ltr";
            style['text-align'] = params.value?.match(/[\u0600-\u06FF\u0750-\u077F]/) ? "right" : "left";
            return style;
          },
        },
        {
          headerName: 'الإجراءات',
          cellRenderer: 'agActionBtn',
          cellClass: 'action-cell',
          cellRendererParams: {
            actions: [
              {
                action: 'buttons',
                btnActions: [
                  {
                    name: 'اختيار',
                    action: 'select',
                    showProp: 'ShowSelect',
                    cssClass: 'btn-primary px-5'
                  },
                  {
                    name: 'حذف',
                    action: 'delete',
                    showProp: 'ShowSelect',
                    cssClass: 'btn-danger px-5'
                  },
                ]
              },
            ],
            getAction: this.rowActions.bind(this),
          },
          maxWidth: 200
        }
      ];
    }
  }

  /**
* row tabel actions
* @param data
*/
  rowActions(data) {
    if (data.action == TableActions.select) {
      this._MatDialogRef.close(data.data?.text);
    } else if (data.action == TableActions.delete) {
      this.deleteMessageTemplate(data.data?.id);
    }
  }

  deleteMessageTemplate(id) {
    this._Message.showConfirmDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        this.subscription.add(
          this._Sms.deleteMessageTemplate(id)
            .subscribe((res: any) => {
              this._Message.showSuccessMessage('تم حذف القالب بنجاح');
              this.getTableItems();
            }, error => {
              if(error.status != HttpErrorResponseStatus.unprocessableEntity){
                this._Message.showErrorMessage(error?.error?.message ? error?.error?.message : 'حدث خطأ أثناء حذف القالب');
              }
            }))
      }
    })
  }

  /**
* pagination events _____________________
* @param event
*/
  pageEvent(event) {
    if (typeof event == "object") {
      this.filters.page = event.pageIndex + 1;
      this.pageOptions.page = event.pageIndex + 1;
    } else {
      this.pageOptions.page = event;
      this.filters.page = event
    }
    this.getTableItems();
  }

}
