
<div class="d-flex justify-content-end mb-2">
    <div class="whatsapp-chat">

        <div class="start-chat">
            <div class="whatsapp-chat-body">
                <div class="whatsapp-chat-message-container">
                    <div class="whatsapp-chat-message">
                        <div class="whatsapp-chat-text">
                            <ng-container *ngFor="let item of messageTemplateComponents; let index = index">
                                <ng-container [ngSwitch]="item?.type">


                                    <!-- Start Header -->
                                    <ng-container *ngSwitchCase="componentTypes.Header">

                                        <app-official-header-preview 
                                            [header]="item" 
                                            [templateDir]="templateDir"
                                        ></app-official-header-preview>

                                    </ng-container>
                                    <!-- End Header -->

                                    <!-- Start Body -->
                                    <ng-container *ngSwitchCase="componentTypes.Body">
                                        <div style="white-space: pre-wrap;" [dir]="templateDir" [ngClass]="{
                                            'text-left': templateDir == 'ltr',
                                            'text-right': templateDir == 'rtl'
                                        }" >
                                            {{item?.text}}
                                        </div>
                                        <!-- <div class="whatsapp-chat-time">1:40</div> -->
                                    </ng-container>
                                    <!-- End Body -->

                                    <!-- Start Footer -->
                                    <ng-container *ngSwitchCase="componentTypes.Footer">
                                        <div class="font-size-12 mt-2 text-muted" [dir]="templateDir" [ngClass]="{
                                            'text-left': templateDir == 'ltr',
                                            'text-right': templateDir == 'rtl'
                                        }">
                                            {{item?.text}}
                                        </div>
                                    </ng-container>
                                    <!-- End Footer -->

                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let item of messageTemplateComponents; let index = index">
                    <ng-container [ngSwitch]="item?.type">
                        <!-- Start Button -->
                        <ng-container *ngSwitchCase="componentTypes.Button">
                            <app-official-buttons-preview [buttons]="item?.buttons"></app-official-buttons-preview>
                        </ng-container>
                        <!-- End Button -->
                    </ng-container>
                </ng-container>
            </div>
        </div>


    </div>
</div>