import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageAreaComponent } from './message-area.component';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from '../messages/messages.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';


@NgModule({
  declarations: [MessageAreaComponent],
  imports: [
    FormsModule,
    CommonModule,
    MessagesModule,
    PickerModule
  ],
  exports: [
    MessageAreaComponent
  ]
})
export class MessageAreaModule { }
