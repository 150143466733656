import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    constructor(
        private toaster: ToastrService,
    ) { }

    showErrorMessage(message, title?: string, option?) {
        this.toaster.error(message, title, option);
    }

    showSuccessMessage(message, title?: string, option?) {
        this.toaster.success(message, title, option);
    }

    showConfirmDeleteMessage(title?: string, confirmText?, cancelText?, denyButtonText?, moreActions?): Promise<any> {
        return Swal.fire({
            title: title ? title : 'هل انت متأكد من الحذف؟',
            icon: 'warning',
            showCancelButton: true,
            denyButtonText: denyButtonText,
            cancelButtonText: cancelText ? cancelText : 'الغاء',
            confirmButtonText: confirmText ? confirmText : 'حذف ',
            ...moreActions
        })
    }

    swalErrorMessage(option: { title: string, text: string }) {
        Swal.fire({
            icon: 'error',
            title: option.title,
            text: option.text,
        })
    }
}
