<div [ngSwitch]="type"  >
  
  <div *ngSwitchCase="types.error">

    <div class="danger alert d-flex m-0 py-3 px-4" role="alert">
      <div class="message w-100">
        <div class="d-flex align-items-center" [ngClass]="{'text-center justify-content-center': addErrorClasses}" >
          <i class="fas fa-times-circle"></i>
          <p class="mx-3" *ngIf="message">
            <span class="font-weight-bolder">
              {{message}}
            </span>
          </p>

          <div *ngIf="html" class="mx-3" [innerHTML]="html"></div>
        </div>
      </div>

      <div class="close d-flex align-items-center justify-content-center " (click)="show.emit(false)" *ngIf="showClose" >
        <i class="fas fa-times-circle text-danger"></i>
      </div>
    </div>

  </div>

  <div *ngSwitchCase="types.info">

    <div class="info alert d-flex py-3 px-4" role="alert">
      <div class="message d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <i class="fas fa-info-circle"></i>
          
          <p *ngIf="message" class="mx-3">
            <span class="font-weight-bolder">
              {{message}}
            </span>
          </p>

          <div *ngIf="html" class="mx-3" [innerHTML]="html"></div>

        </div>
      </div>

      <div class="close d-flex align-items-center justify-content-center " (click)="show.emit(false)" *ngIf="showClose" >
        <i class="fas fa-times-circle text-danger"></i>
      </div>
    </div>

  </div>

  <div *ngSwitchCase="types.success">

    <div class="success alert d-flex py-3 px-4" role="alert">
      <div class="message d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <i class="fas fa-check-circle"></i>
          <p class="mx-3">
            <span class="font-weight-bolder">
              {{message}}
            </span>
          </p>
        </div>
      </div>

      <div class="close d-flex align-items-center justify-content-center " (click)="show.emit(false)" *ngIf="showClose" >
        <i class="fas fa-times-circle text-danger"></i>
      </div>
    </div>

  </div>

  <div *ngSwitchCase="types.wanning">

    <div class="warning alert d-flex p-5" role="alert">
      <div class="message d-flex align-items-center">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        <p class="mx-3" *ngIf="message">
          <span class="font-weight-bolder">
            {{message}}
          </span>
        </p>
        <div *ngIf="html" class="mx-3" [innerHTML]="html"></div>
      </div>
    
      <div class="close d-flex align-items-center justify-content-center " *ngIf="showClose">
        <i class="fas fa-times-circle text-danger"></i>
      </div>
    </div>

  </div>

  <div *ngSwitchCase="types.noData">

    <div class="danger alert d-flex justify-content-center p-5" role="alert">
      <div class="message d-flex align-items-center flex-column">
        <div class="d-flex">
          <p class="mx-3">
            <span class="font-weight-bolder">
              {{message}}
            </span>
          </p>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- <div class="success alert d-flex p-5" role="alert">
  <div class="message d-flex align-items-center">
    <i class="fas fa-2x  fa-check-circle"></i>
    <p class="mx-3"><span class="font-weight-bolder">عملينا العزيز تم تاكيد استلام المبلغ
      </span></p>
  </div>

  <div class="close d-flex align-items-center justify-content-center ">
    <i class="fas fa-times-circle text-danger"></i>
  </div>
</div> -->
<!-- 


<div class="warning alert d-flex p-5" role="alert">
  <div class="message d-flex align-items-center">
    <i class="fas fa-2x fa-exclamation-triangle"></i>
    <p class="mx-3"><span class="font-weight-bolder">رساله</span> : نص</p>
  </div>

  <div class="close d-flex align-items-center justify-content-center ">
    <i class="fas fa-times-circle text-danger"></i>
  </div>
</div>

<div class="info alert d-flex p-5" role="alert">
  <div class="message d-flex align-items-center">
    <i class="fas fa-2x fa-info-circle"></i>
    <p class="mx-3"><span class="font-weight-bolder">رساله</span> : نص</p>
  </div>

  <div class="close d-flex align-items-center justify-content-center ">
    <i class="fas fa-times-circle text-danger"></i>
  </div>
</div> -->

<!-- 
  <div class="alert alert-danger" role="alert">
    This is a danger alert—check it out!
  </div>

  <div class="alert alert-warning" role="alert">
    This is a warning alert—check it out!
  </div>

  <div class="alert alert-info" role="alert">
    This is a info alert—check it out!
  </div> -->