import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { BrowserDbService } from "../services/browser-db.service";

@Injectable({
    providedIn: 'root'
})
export class AppInsightService {
    appInsights: ApplicationInsights;

    constructor(private _BrowserDbServicethis: BrowserDbService) {
        // this.appInsights = new ApplicationInsights({
        //     config: {
        //         instrumentationKey: environment.appInsight.instrumentationKey,
        //         enableAutoRouteTracking: true,
        //         autoTrackPageVisitTime: true,
        //     }
        // });

        // this.appInsights.loadAppInsights();
    }

    logHttpException(exception: any , body:any) {
        // var user = this._BrowserDbServicethis.getItem(Constant.user)
        // this.appInsights.trackException({ exception: exception }, {
        //     "UserInfo": user?.email ,
        //     "RequestBody":JSON.stringify(body),
        //     "Response":JSON.stringify(exception?.response)
        // });
    }

    logException(exception: any,moreInfo?:any) {
        // var user = this._BrowserDbServicethis.getItem(Constant.user)
        // this.appInsights.trackException({ exception: exception }, {
        //     "UserInfo": user?.email,
        //     ...moreInfo
        // });
    }

}