import { Component, Input, OnInit } from '@angular/core';
import { FileTypes } from "src/app/modules/auk-whatsapp/enums/file-types.enum";
import { FileService } from 'src/app/shared/services/file.service';

@Component({
  selector: 'file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit {

  @Input() type : FileTypes
  @Input() data : any

  constructor(
    private _FileService: FileService
  ) { }

  ngOnInit(): void {
  }



  onFileClick(body): void {
    
    this._FileService.downloadBase64File(body);
  }

}
