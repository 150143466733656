import { Injectable } from '@angular/core';
import { HelperService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private _Helpers: HelperService,

  ) { }


  openFile(blob: any): void {
    var downloadURL = window.URL.createObjectURL(blob);
    window.open(
      downloadURL, "_blank");
  }

  openFileURL(URL: any): void {
    window.open(
      URL, "_blank");
  }


  downloadFile(blob: Blob,fileName?:string): void {
    var downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download =  `file.${this._Helpers.getFileExtensionFromUrl(downloadURL)}`;
    link.click();
    // Remove element from DOM
    document.body.removeChild(link);
  }

  downloadBase64File(base64Data, fileName?) {

    const byteCharacters = atob(base64Data); // Decode the base64 data
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
  
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
  
    document.body.appendChild(downloadLink);
    downloadLink.click();
  
    document.body.removeChild(downloadLink);
  }

  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


}
