import { ElementRef, Injectable } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as moment from "moment"
import { ClipboardService } from "ngx-clipboard"

@Injectable({
  providedIn: "root",
})
export class HelperService {
  
  constructor(
    private _Router: Router,
    private _ActiveRoute: ActivatedRoute,
    private _clipboardService: ClipboardService,
  ) { }

  // Reusable function to show the uniqueObjArray
  uniqueObjArray(arr: any[], key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()]
  }

  uniqueArrayOfObject(arr: any[], key) {
    return arr.filter((thing, index, self) => {
      return (
        index ===
        self.findIndex((t) => {
          return t[key] === thing[key]
        })
      )
    })
  }

  // Delete null values of object
  deleteNullValues(obj,skipZero = false) {
    Object.keys(obj).forEach((key) => {
      // if(obj[key] == 0 && skipZero) return;
      if (!obj[key]) {
        delete obj[key]
      }
    })
    return obj
  }

  /**
   * Get fil uploaded extension
   * @param file
   * @returns
   */
  getFileExtension(file) {
    const name = file?.name
    const lastDot = name?.lastIndexOf(".")
    const ext = name?.substring(lastDot + 1)
    return ext
  }

  /**
   * Get fil uploaded extension
   * @param file
   * @returns
   */
  getFileName(file) {
    const name = file?.name
    const ext = name.split(".")[0].trim()
    return ext
  }

  urlValidator(url: string) {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    if (!urlPattern.test(url)) {
      return { invalidUrl: true }
    }
    return { invalidUrl: false }
  }

  /**
   * Change route url without reload.
   * @param  {Router} router.
   * @param  {ActivatedRoute} activateRoute.
   */
  changeRouteParams(params): void {
    this._Router.navigate([], {
      relativeTo: this._ActiveRoute,
      queryParams: params,
    })
  }

  /**
   * Check if text contain url
   */

  checkTextContainUrl(text): boolean {
    return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)
  }

  getUrlFromText(text): string {
    var urlRegex = /(https?:\/\/[^\s]+)/g
    let urlX = ""
    text.replace(urlRegex, function (url) {
      urlX = url
    })
    return urlX
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  getFileExtensionFromUrl(url) {
    return url ? url.split(/[#?]/)[0].split(".").pop().trim() : ""
  }

  convertDateToUserTimezone(date: string): string {
    return date ? new Date(date).toLocaleString("en-GB", { hour12: true }) : ""
  }

  getDateString(date: string): string {
    return date ? date?.split("T")[0] : ""
  }

  getDateAndTimeString(date: string): string {
    return date ? moment(date).format("MMMM Do YYYY, h:mm:ss a") : ""
  }

  formateDateToPost(date: string): string {
    return date ? moment(date).format("DD/MM/YYYY") : ""
  }

  tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }

  tokenAlmostExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    return currentTimeInSeconds > (expiry - 2000);
  }

  convertArabicDigitsToEnglish(str, removeComa = true): string {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
    if (typeof str === "string") {
      if (removeComa) {
        str = str.replace(/,/g, "")
      }
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

  mergeArrayWithSameProperty(arr, property, mergedProp) {
    let array = arr.reduce((obj, item) => {
      obj[item[property]] ? obj[item[property]][mergedProp].push(...item[mergedProp], item) : (obj[item[property]] = { ...item, [mergedProp]: [item] })
      return obj
    }, {})

    return array
  }

  // Whats Map message
  mapSingleMessage(el, whatsNumber?) {
    // el.messageTime = this.convertDateToUserTimezone(el?.created_at)?.split(",")[1]
    if (el?.type == "file" || el?.type == "image" || el?.type == "document") {
      const fileType = this.getFileExtensionFromUrl(el?.text?.body)
      switch (fileType) {
        case "pdf":
          el.text.icon = "fa-file-pdf"
          el.document = true
          el.fileType = fileType
          break
        case "txt":
          el.text.icon = "fa-file-alt"
          el.document = true
          el.fileType = "TXT"
          break
        case "xlsx":
          el.text.icon = "fa-file-excel"
          el.document = true
          el.fileType = "XLSX"
        case "xls":
          el.text.icon = "fa-file-excel"
          el.document = true
          el.fileType = "XLSX"
          break
        case "zip":
          el.text.icon = "fa-file-archive"
          el.document = true
          el.fileType = "ZIP"
          break
        case "rar":
          el.text.icon = "fa-file-archive"
          el.document = true
          el.fileType = "RAR"
          break
        case "doc":
          el.text.icon = "fa-file"
          el.document = true
          el.fileType = "DOC"
          break
        case "docx":
          el.text.icon = "fa-file"
          el.document = true
          el.fileType = "DOCX"
          break
        case "bin":
          el.text.icon = "fa-file-contract"
          el.document = true
          el.fileType = "BIN"
          break

        case "mpga":
          el.type = "ptt"
          break

        case "mp4":
          el.type = "video"
          break

        case "jpeg":
          el.text.img = true
          break

        case "png":
          el.text.img = true
          break

        case "jpg":
          el.text.img = true
          break

        case "gif":
          el.text.img = true
          break
        case "svg":
          el.text.img = true
          break
        case "webp":
          el.text.img = true
          break

        case "wav":
          el.type = "ptt"
          break

        case "mp3":
          el.type = "ptt"
          break

        default:
          break
      }
    } else if (el?.type == "contact") {
      if (el?.sent_status == "receive") {
        if (el?.text?.body) {
          el.text.contactName = el?.text?.body?.name?.formatted_name
          el.text.contactPhone = el?.text?.body?.phones[0]?.phone?.replace("+", "")
        }
      } else {
        if (el?.text?.contact) {
          el.text.contactName = el?.text?.contact
          el.text.contactPhone = el?.text?.contact
        } else if (el?.text?.contacts?.length == 1) {
          el.text.contactName = el?.text?.contacts[0]?.name?.formatted_name;
          el.text.contactPhone = el?.text?.contacts[0]?.phones[0]?.phone;
        } else if (el?.text?.body?.length == 1) {
          el.text.contactName = el?.text?.body[0]?.name?.formatted_name;
          el.text.contactPhone = el?.text?.body[0]?.phones[0]?.phone;
        }
      }
    } else if (el?.type == "vcard") {
      if (el?.sent_status == "receive") {
        if (el?.text?.body?.length) {
          let fname = /FN:(.*)/g
          let tel = /TEL;[^:]*:(.*)/g
          let str = el?.text?.body[0]
          let name, phone

          name = fname.exec(str)
          phone = tel.exec(str)
          el.text.contactName = name[1] //el?.text?.body[0]?.name?.formatted_name;
          el.text.contactPhone = phone[1]?.replace("+", "") //el?.text?.body[0]?.phones[0]?.phone?.replace('+', '');
        }
      } else {
        if (el?.text?.contacts?.length) {
          el.text.contactName = el?.text?.contacts[0]?.name?.formatted_name
          el.text.contactPhone = el?.text?.contacts[0]?.phones[0]?.phone
        } else if (el?.text?.contact) {
          el.text.contactName = el?.text?.contact
          el.text.contactPhone = el?.text?.contact
        }
      }
    } else if (el?.type == "location" && whatsNumber.w_type == 1) {
      if (el?.sent_status == "receive") {
        if (typeof el?.text?.body == 'string') {
          let locArr = el?.text?.body?.split(";")
          el.text.lat = +locArr[0]
          el.text.lng = +locArr[1]
        } else {
          el.text.lat = +el.text.body.latitude;
          el.text.lng = +el.text.body.longitude;
        }
      } else {
        el.text.lat = el?.text?.lat ? +el?.text?.lat : ""
        el.text.lng = el?.text?.lng ? +el?.text?.lng : ""
      }
    } else if (el?.type == "location" && whatsNumber.w_type == 2) {
      if (el?.sent_status == "receive") {
        el.text.lat = el?.text?.body?.latitude
        el.text.lng = el?.text?.body?.longitude
        el.text.address = el?.text?.body?.description
        el.text.img = `data:image/png;base64,${el?.text?.body?.img}`
      } else {
        // el.text.lat = el?.text?.lat ? +el?.text?.lat : '';
        // el.text.lng = el?.text?.lng ? +el?.text?.lng : '';
      }
    } else if (el?.type == "text" || el?.type == "chat") {
      el.dir = el?.text?.body?.match(/^[a-zA-Z]/i) ? "ltr" : el?.text?.body?.match(/^[\u0600-\u06FF]/i) ? "rtl" : ""
      let lines = el?.text?.body?.split(/\n/)?.map((line) => {
        return {
          text: line,
          dir: line?.match(/^[a-zA-Z]/i) ? "ltr" : line?.match(/^[\u0600-\u06FF]/i) ? "rtl" : "",
        }
      })
      if (lines?.length > 1) {
        el.text.lines = lines
      }

      // el.text.body = el?.text?.body?.replace(/\n/g,'<br>');
    }
    return el
  }

  /**
   * @description Check if the token is expired
   */
  checkTokenExpiration(token) {
    let should_refresh_token: any = false
    if (token) {
      let JWT_arr: any[] = token.split(".")
      let JWT_str = JWT_arr[1]
      let JWT_obj_str
      JWT_obj_str = atob(JWT_str)
      let JWT_obj = JSON.parse(JWT_obj_str)
      let exp = JWT_obj.exp
      let exp_date: any = new Date(exp * 1000)
      let current_date: any = new Date()
      should_refresh_token = exp_date - current_date < 15000
    }
    return should_refresh_token
  }

  copy(text: string) {
    this._clipboardService.copy(text)
  }

  //  Get text and convert it to lines depend on the direction
  getLines(text: string) {
    let lines = text?.split(/\n/)?.map((line) => {
      return {
        text: line,
        url: this.getUrlFromText(line),
        dir: line?.match(/^[a-zA-Z]/i) ? "ltr" : line?.match(/^[\u0600-\u06FF]/i) ? "rtl" : "",
      }
    })
    return lines
  }

  /**
   *
   * @param text
   * @returns
   */
  isContainArabic(text) {
    return text?.match(/[\u0600-\u06FF\u0750-\u077F]/)
  }

  checkIfEmail(text: string) {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return emailRegex.test(text)
  }

  convertFileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      if (!file) return resolve("")
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  // Convert number to 5k format
  convertNumberToK(num: number) {
    // if (num > 999 && num < 1000000) {
    //     return (num / 1000).toFixed(1) + 'k';
    // } else if (num > 1000000) {
    //     return (num / 1000000).toFixed(1) + 'm';
    // } else if (num < 900) {
    //     return num;
    // }

    let formatter = Intl.NumberFormat("en", {
      //@ts-ignore
      notation: "compact",
    })
    return formatter.format(num)
  }

  // Show decimal number with 3 digits after the point without rounding
  showDecimalNumber(num: number) {
    if (!num) return 0
    if (isNaN(num)) return 0
    let with3Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
    return with3Decimals
  }

  showDecimalNumberWithNDigits(num: number, digits: number = 3) {
    if (!num) return 0
    if (isNaN(num)) return 0
    let regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${digits}})?`)
    let with3Decimals = num.toString().match(regex)[0]
    return with3Decimals
  }

  getTodayDate() {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  checkWhatDayIsToday() {
    let day = new Date().getDay()
    let days = ["الاحد", "الاثنيين", "الثلثاء", "الاربعاء", "الخميس", "الجمعه", "السبت"]
    return days[day]
  }

  // Handel BackEnd Errors
  handleErrors(error: object) {
    let errorsArr = []
    for (const [key, value] of Object.entries(error)) {
      errorsArr.push({
        key,
        value,
      })
    }
    return errorsArr
  }

  // Detect if the user is using mobile device
  isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }

  chunkArray(arr: any[], size: number) {
    let chunked_arr = []
    let index = 0
    while (index < arr.length) {
      chunked_arr.push(arr.slice(index, size + index))
      index += size
    }
    return chunked_arr
  }

  isTokenExpired(token) {
    const base64Url = token.split(".")[1] // Extract the payload from the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/") // Replace URL-safe characters
    const decodedPayload = JSON.parse(atob(base64)) // Decode the base64-encoded payload

    if (decodedPayload.exp) {
      const currentTime = Math.floor(Date.now() / 1000) // Get the current time in seconds
      return decodedPayload.exp < currentTime // Compare the expiration time with the current time
    }

    return true // Treat as expired if no expiration time is found
  }

  checkIfDateExpires(expireDate) {
    if (!expireDate) return false
    const now = moment()
    const expirationDate = moment(expireDate)
    return expirationDate.isBefore(now)
  }

  getDialogOptions(width, data, moreOptions = {}) {
    return {
      width: width,
      panelClass: ["custom-dialog"],
      autoFocus: false,
      data: {
        ...data,
      },
      ...moreOptions,
    }
  }

  checkIfDateIsExpiresNotIncludingCurrentDate(expireDate) {
    if (!expireDate) return false
    const now = moment()
    const expirationDate = moment(expireDate)
    return expirationDate.isSameOrBefore(now, "day")
  }

  checkIfDateIsExpiresNotIncludingToday(date) {
    if (!date) return false
    const now = moment()
    const expirationDate = moment(date)
    return expirationDate.isBefore(now, "day")
  }

  reloadWithoutQueryParams(): void {
    const urlWithoutQueryParams = window.location.origin + window.location.pathname
    window.location.href = urlWithoutQueryParams
  }

  scrollToElement(target: ElementRef, containerEl, top: number = 0) {
    if (!target) return
    const targetElement = target.nativeElement
    // Calculate the desired scroll position
    const container = containerEl // Or use a specific scrollable container element
    const targetRect = targetElement.getBoundingClientRect()
    const scrollTop = targetRect.top + container.pageYOffset - top
    const scrollLeft = targetRect.left + container.pageXOffset

    // Scroll to the desired position
    setTimeout(() => {
      container.scrollTo({ top: scrollTop, left: scrollLeft, behavior: "smooth" })
    }, 100)
  }

  allowOnProduction() {
    const userMail = JSON.parse(localStorage.getItem("user"))?.email;
    const allowedEmails = [
      "fatma.ahmed@4jawaly.com",
      "ebtesam.mohamed@4jawaly.com",
      "gad@tech-world.ws",
      "gad@4jawaly.com",
      'eslam@php-eg.com',
      "support@4jawaly.com",
      "fatmaahmedtest@gmail.com",
      "system@4jawaly.com",
      "same7soliman.m@gmail.com",
      "osamaawadalla7@gmail.com"
    ];
    return allowedEmails.includes(userMail);
  }

  /**
  * Convert ngDatePicker date to valid date string.
  * @param  {date} obj.
  * @returns {string}
  */
  covertNgDateToString(date: any): string {
    if (date?.day && date?.month && date?.year) {
      const day = String(date.day).padStart(2, '0');
      const month = String(date.month).padStart(2, '0');
      const year = String(date.year);
      return `${day}/${month}/${year}`;
    } else {
      return null;
    }
  }

  /**
  * Change date formate that come from backend to set to ngDatePicker.
  * @param  {date} string.
  * @returns {object}
  */
  changeDateForMateToSetNew(date: string): object {
    const dateFormate = date?.split('-');
    return {
      day: dateFormate ? +dateFormate[2] : null,
      month: dateFormate ? +dateFormate[1] : null,
      year: dateFormate ? +dateFormate[0] : null,
    }
  }

  generateRandomKey(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  

}
