import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { MessagesService } from "src/app/shared/services/messages.service";
import { SmsService } from "../../services/sms.service";

@Component({
  selector: 'app-sending-templates-dialog',
  templateUrl: './sending-templates-dialog.component.html',
  styleUrls: ['./sending-templates-dialog.component.scss']
})
export class SendingTemplatesDialogComponent implements OnInit {
  body = {
    name: '',
    text: ''
  }
  formErrors: any = {};
  subscription: Subscription = new Subscription();
  constructor(
    private _Sms: SmsService,
    private _Message: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _MatDialogRef: MatDialogRef<SendingTemplatesDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.body.text = this.data.template;
  }

  saveTemplate(): void {
    if(!this.body.text || !this.body.name ) {
      this._Message.showErrorMessage('يجب ملئ الحقول');
      return;
    }
    this.subscription.add(
      this._Sms.saveMessageTemplate(this.body)
        .subscribe((res: any) => {
          this.formErrors = {};
          this._Message.showSuccessMessage('تم حفظ القالب بنجاح');
          this._MatDialogRef.close();
        },(error:HttpErrorResponse) => {
          if (error.status == 422) {
            this.formErrors = error?.error;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
