import { Direction } from '@angular/cdk/bidi';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { ShowSendingTemplatesDialogComponent } from 'src/app/modules/sender-controle/components/send-message/show-sending-templates-dialog/show-sending-templates-dialog.component';
import { SendingTemplatesDialogComponent } from 'src/app/modules/sender-controle/components/sending-templates-dialog/sending-templates-dialog.component';
import { SmsMessageService } from 'src/app/modules/sender-controle/services/sms-message.service';
import { SmsService } from 'src/app/modules/sender-controle/services/sms.service';
import { MessageTypes } from '../../models/shared';
import { HelperService } from '../../services/helpers.service';
import { MessagesService } from '../../services/messages.service';
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'auk-message-area',
  templateUrl: './message-area.component.html',
  styleUrls: ['./message-area.component.scss']
})
export class MessageAreaComponent implements OnInit {
  setsEmoji = [
    'google',
  ]
  emojiStyle = {
    position: 'absolute','z-index' : '999', bottom: '20px', right: '20px'
  }
  showEmojiPicker = false;
  @Input() required: boolean = true;
  @Input() disabled: boolean = false;
  @Input() label: string = 'نص الرسالة:';
  @Input() showTemplates: boolean = true;
  @Input() showNotes: boolean = true;
  @Input() isWhatsapp: boolean = false;
  @Input() set initValue(value: any) {

    if (value) {
      this.message = value
    }
  };

  subscription: Subscription = new Subscription();
  message = '';
  messagesTypesEnum = MessageTypes;
  charCount: number = 0;
  meassgeDir: Direction = 'rtl';

  data = {
    encoding: 'GSM_7BIT',
    length: null,
    per_message: null,
    remaining: null,
    messages: null
  }

  maxMeassgeLength = 670;

  // Inputs
  @Input() set clearData(clear) {
    if (clear) this.message = '';
    // To Clear Message When User send message
  }

  // OutPuts
  @Output() messageChange = new EventEmitter<any>();

  constructor(
    private _Sms: SmsService,
    private shared: SharedService, 
    private _MatDialog: MatDialog,
    private _Helpers: HelperService,
    private _Message: MessagesService,
    private activeRoute: ActivatedRoute, 
    private _SmsMessage: SmsMessageService,
  ) { }

  ngOnInit(): void {
    if(this.shared.whatsNumbersToSend?.length 
      && this.activeRoute.snapshot.queryParams?.project 
      && this.activeRoute.snapshot.queryParams?.text){
      this.message = this.activeRoute.snapshot.queryParams?.text;
      this.addMessage();
    }
  }

  // Messages  Functions
  addMessage() {
    const isArabic = this._Helpers.isContainArabic(this.message);
    this.meassgeDir = isArabic ? 'rtl' : 'ltr';

    if (this.isWhatsapp) {
      this.maxMeassgeLength = 1024;
    } else {
      this.maxMeassgeLength = isArabic ? 670 : 1530;
    }
    this.message = this.message.length > this.maxMeassgeLength ? this.message.slice(0, this.maxMeassgeLength) : this.message;

    // Replace any space before new line with new line
    this.message = this.message.replace(/ \n/g, '\n');
    this.messageChange.emit({
      messageLines: this._Helpers.getLines(this.message),
      message: this.message,
      messageCount: this.messageHandeler.messages
    });

  }

  get messageHandeler() {
    this.data = this._SmsMessage.count(this.message);
    return this.data;
  }


  openShowSendingTemplatesDialog() {
    const DialogRef = this._MatDialog.open(ShowSendingTemplatesDialogComponent, {
      width: "90%",
      panelClass: ['custom-dialog'],
      autoFocus: false,
    });

    this.subscription.add(
      DialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.message = res;
          this.messageChange.emit({
            messageLines: this._Helpers.getLines(this.message),
            message: this.message,
            messageCount: this.messageHandeler.messages
          });
        }
      })
    );
  }

  openSendingTemplatesDialog() {
    if (!this.message.length) return this._Message.showErrorMessage('يجب ادخال النص')
    const DialogRef = this._MatDialog.open(SendingTemplatesDialogComponent, {
      width: "600px",
      panelClass: ['custom-dialog'],
      autoFocus: false,
      data: {
        template: this.message
      }
    });
  }

  addEmoji(event) {
    const { message } = this;
    const text = `${message}${event.emoji.native}`;
    this.message = text;
    this.showEmojiPicker = false;
    this.messageChange.emit({
      messageLines: this._Helpers.getLines(this.message),
      message: this.message,
      messageCount: this.messageHandeler.messages
    });
  }


  clear(): void {
    this.message = '';
  }
}
