import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AukGridComponent } from './auk-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { PaginationModule } from "../pagination/pagination.module";
import { FloatingRadioFilterComponent } from './components/filters/floating-radio-filter/floating-radio-filter.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from "@angular/forms";
import { FloatingCheckboxFilterComponent } from './components/filters/floating-checkbox-filter/floating-checkbox-filter.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FloatingSelectFilterComponent } from './components/filters/floating-select-filter/floating-select-filter.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { AukGridTextareaComponent } from './components/cells/auk-grid-textarea/auk-grid-textarea.component';
import { DateTimeComponent } from './components/cells/date-time/date-time.component';
import { AukGridImgComponent } from './components/cells/auk-grid-img/auk-grid-img.component';
import { AukGridProgressComponent } from './components/cells/auk-grid-progress/auk-grid-progress.component';
import { NgbModule, NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { AukGridStatusBtnComponent } from "./components/cells/auk-grid-status-btn/auk-grid-status-btn.component";


@NgModule({
  declarations: [AukGridComponent, FloatingRadioFilterComponent, FloatingCheckboxFilterComponent, FloatingSelectFilterComponent, AukGridTextareaComponent, DateTimeComponent, AukGridImgComponent, AukGridProgressComponent,AukGridStatusBtnComponent],
  imports: [
    CommonModule,
    AgGridModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    PaginationModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatFormFieldModule,
    NgSelectModule,
    FormsModule,
    NgbProgressbarModule,
    NgbModule
  ],
  exports: [
    AukGridComponent
  ]
})
export class AukGridModule { }
