import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { SavedResponsesDialogComponent } from '../../saved-responses-dialog.component';
import { AddNewMessagesComponent } from '../../../add-new-messages/add-new-messages.component';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'add-edit-response',
  templateUrl: './add-edit-response.component.html',
  styleUrls: ['./add-edit-response.component.scss']
})
export class AddEditResponseComponent implements OnInit {

  
  @ViewChild("addNewMessages") AddNewMessagesComponent: AddNewMessagesComponent;

  @Output() afterAdd: EventEmitter<any> = new EventEmitter()
  @Output() backToList: EventEmitter<any> = new EventEmitter()
  
  @Input() number: any;
  @Input() numberId: any;

 
  // Props 
  message: any = '';
  subscription: Subscription = new Subscription();

  
  constructor(
    private _WhatsService: WhatsService,
    public _MessagesService: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _NgxSpinnerService: NgxSpinnerService,
    public _MatDialogRef: MatDialogRef<SavedResponsesDialogComponent>,
  ) { }

  ngOnInit(): void {

  }

 
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  submit() {

    if (!this.AddNewMessagesComponent.validMessages()) return

    const payload = this.prepareDataBeforeSent();
    
    this._NgxSpinnerService.show();
    this.subscription.add(

    this._WhatsService.sendMessage(this.numberId, payload)
    .pipe(
      finalize( () => this._NgxSpinnerService.hide())
    )
    .subscribe((res: any) => {
      // Check if there is an error
      if (!res?.messages[0]?.err_text && !res?.messages[0]?.error_numbers) {
        this._MessagesService.showSuccessMessage('تم الإرسال بنجاح');
        this._MatDialogRef.close();
      } else {
        this._MessagesService.showErrorMessage('حدث خطا');
      }

    }, (error: HttpErrorResponse) => {
      this._NgxSpinnerService.hide()
      if (error.error?.code == 400) {
        // this.numbersErrors = [];
        this._MessagesService.showErrorMessage(error.error?.message);
      }
    }))
  }


  prepareDataBeforeSent(): any {
    const data = {
      globals: {
        number_iso: environment.number_iso,
        time_frame: 10
      },
      messages: [],
    };

    
    

    this.AddNewMessagesComponent.chatareaPreviewData?.messages.forEach(el => {

      let message: any = {
        data: {},
        numbers: [this.number]
      };
      switch (el?.type) {

        
        case 'image':
        case 'video':
        case 'file':
          message.type = 'file';
          message.data.sendMediaAsDocument = true,
          message.data.body = el?.text.body;
          message.data.filename = el?.text?.filename || el?.type ;
          message.data.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        case 'location':
          message.type = 'location';
          message.data.lat = el?.text?.lat;
          message.data.lng = el?.text?.lng;
          message.data.address = el?.address;
          break;

        case 'text':
          message.type = 'text';
          message.data.body = el?.text?.body;
          break;

        case 'audio':
          message.type = 'file';
          message.data.body = el?.text.body;
          message.data.sendAudioAsVoice = true
          message.data.filename = el?.text?.filename || el?.type ;
          message.data.caption = el?.text?.caption ? el?.text?.caption : '';
          break;

        default:
          break;
      }
      
      
      data.messages.push(message);
    })
    return data;
  }
 
  errorHandller(error: HttpErrorResponse): void {
    this._MessagesService.showErrorMessage(error?.message);
  }

  
  onBack(): void {
    this.backToList.emit();
  }
  
}
