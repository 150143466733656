import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from 'src/environments/environment';
import { NavChange } from '../models/global';
import { TimeZoneList } from "../models/shared";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // New Aside Menu and Selected service open
  openedService: BehaviorSubject<string> = new BehaviorSubject(null);
  // New Aside Menu and Selected service open
  showAddSender: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showWhatsAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showCrmAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showErpAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showBuildInAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showHostAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showUserInfoAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  contentFluid: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showServiceMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pusherKey: BehaviorSubject<any> = new BehaviorSubject(null);
  paymentNumberData: BehaviorSubject<any> = new BehaviorSubject({});
  stripeSessionId: BehaviorSubject<any> = new BehaviorSubject(null);
  userBalance: BehaviorSubject<any> = new BehaviorSubject(0);
  spoolMessagesNumbers: BehaviorSubject<any> = new BehaviorSubject(0);
  pusherNewMessageData: BehaviorSubject<any> = new BehaviorSubject(null);
  getNumberConversationChat: BehaviorSubject<any> = new BehaviorSubject(null);
  disconnectCustomer: BehaviorSubject<any> = new BehaviorSubject(null);
  currenciesLits: BehaviorSubject<any> = new BehaviorSubject(null);
  userGlobalSetting: BehaviorSubject<any> = new BehaviorSubject({});
  private invalidNumbersSource = new BehaviorSubject<string[]>([]);
  invalidNumbers$ = this.invalidNumbersSource.asObservable();

  showFinancialSystemAside: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // Select header option for ag-grid
  selectHeaderAction: BehaviorSubject<any> = new BehaviorSubject(null);

  navData: BehaviorSubject<any> = new BehaviorSubject(null);
  navData$ = this.navData.asObservable();

  fiscalYears: BehaviorSubject<any> = new BehaviorSubject(null);
  fiscalYears$ = this.fiscalYears.asObservable();

  selectedCompanyId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompanyId$ = this.selectedCompanyId.asObservable();

  selectedCompany: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompany$ = this.selectedCompany.asObservable();

  selectedCompanyNumber: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompanyNumber$ = this.selectedCompanyNumber.asObservable();

  selectedRoleId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedRoleId$ = this.selectedRoleId.asObservable();

  selectedConstCenterId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedConstCenterId$ = this.selectedConstCenterId.asObservable();

  selectedFiscalYearId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedFiscalYearId$ = this.selectedFiscalYearId.asObservable();

  costCenterChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  costCenterChanged$ = this.selectedFiscalYearId.asObservable();

  userImagePath: BehaviorSubject<any> = new BehaviorSubject(null);
  userImagePath$ = this.userImagePath.asObservable();
  // Top nav change
  navChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  navChanged$: Observable<NavChange> = this.navChanged.asObservable();

  isMain: BehaviorSubject<any> = new BehaviorSubject(null);
  isMain$: Observable<NavChange> = this.isMain.asObservable();

  // General setting menu
  generalLedgerMenu: BehaviorSubject<any> = new BehaviorSubject([]);
  generalLedgerMenu$ = this.generalLedgerMenu.asObservable();
  // Get finger print menu
  FingerPrintMenu: BehaviorSubject<any> = new BehaviorSubject([]);
  FingerPrintMenu$ = this.FingerPrintMenu.asObservable();
  // Get contact settings menu
  ContactSettings: BehaviorSubject<any> = new BehaviorSubject([]);
  ContactSettings$ = this.ContactSettings.asObservable();


  serverErrors: BehaviorSubject<any> = new BehaviorSubject({});
  serverErrors$ = this.serverErrors.asObservable();

  scrollToTop: BehaviorSubject<any> = new BehaviorSubject(false);
  scrollToTop$ = this.scrollToTop.asObservable();

  toggleHeaderMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  toggleMenu = this.toggleHeaderMenu.asObservable();

  userPermissions: BehaviorSubject<any[]> = new BehaviorSubject([]);
  userPermissions$ = this.userPermissions.asObservable();

  smsTokenData: BehaviorSubject<any> = new BehaviorSubject(null);
  smsTokenData$ = this.smsTokenData.asObservable();

  userProfile: BehaviorSubject<any> = new BehaviorSubject(null);
  userProfile$ = this.userProfile.asObservable();

  userPackagesBalanceData: BehaviorSubject<any> = new BehaviorSubject(null);
  userPackagesBalanceData$ = this.userPackagesBalanceData.asObservable();

  currentServerErrorCode: BehaviorSubject<any> = new BehaviorSubject(null);

  // New WhatsApp [Pay package cycle]
  whatsPayPackageTicketData: BehaviorSubject<any> = new BehaviorSubject(null);
  whatsPayPackageTicketData$ = this.whatsPayPackageTicketData.asObservable();

  // Senders Data Subjects
  smsSendersNeedRenew: BehaviorSubject<any[]> = new BehaviorSubject([]);
  smsSendersNeedRenew$ = this.smsSendersNeedRenew.asObservable();

  smsActiveSenders: BehaviorSubject<any> = new BehaviorSubject(null);
  smsActiveSenders$ = this.smsActiveSenders.asObservable();

  // Dashboard Data Subjects
  dashboardStatics: BehaviorSubject<any> = new BehaviorSubject(null);
  dashboardStatics$ = this.dashboardStatics.asObservable();

  smsDashboardStatics: BehaviorSubject<any> = new BehaviorSubject(null);
  smsDashboardStatics$ = this.smsDashboardStatics.asObservable();

  // Official templates
  officialTemplates: BehaviorSubject<any> = new BehaviorSubject(null);
  officialTemplates$ = this.officialTemplates.asObservable();

  // WhatsAuthenticated numbers
  whatsAuthenticatedNumbers : BehaviorSubject<any> = new BehaviorSubject(null);
  whatsAuthenticatedNumbers$ = this.whatsAuthenticatedNumbers.asObservable();
  whatsNumbersToSend = [];

  constructor(
    private http: HttpClient,
  ) { }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  downloadPDF(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http
      .patch(`${environment.accountant_apiUrl}users/global/download-pdf`, data, { headers: headers, responseType: 'blob' }
      );
  }

  // get user country form ip address
  getIPAddress() {
    return of({
      countrycode: 'sa'
    })
  }

  getTimeZonesList(): Observable<any> {
    return of(TimeZoneList)

  }

  initializeUserPermission() {
    if (localStorage.getItem('userPermission')) {
      const perm = JSON.parse(`${localStorage.getItem('userPermission')}`);
      // this.permissionsService.loadPermissions(perm);
    }
  }
  // update Invalid numbers
  updateInvalidNumbers(invalidNumbers: string[]): void {
    this.invalidNumbersSource.next(invalidNumbers);
  }

  _setupTawk(type) {
    const typeLink = {
      // support: 'https://embed.tawk.to/56d2b3825e12d9736be32114/1dguiun9g',
      support: 'https://embed.tawk.to/56d2b3825e12d9736be32114/default',
      billing: 'https://embed.tawk.to/653fe8f1a84dd54dc486cd46/1he0qbsib',
      login: 'https://embed.tawk.to/653fe930f2439e1631e9ff67/1he0qdpnu'
    };
    /**
     * Hide widget if tawk is loaded
     */
    if (
      window.Tawk_API !== undefined &&
      typeof window.Tawk_API.hideWidget === 'function'
    ) {
      window.Tawk_API.hideWidget();
    }
    /**
     * Delete script tags of tawk
     */
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const tag = scripts[i];
      if (tag.getAttribute('tawk') === 'yes') {
        tag.parentNode.removeChild(tag);
      }
    }
    /**
     * Delete anything related to tawk, otherwise new widget would not be loaded
     */
    for (const name in window) {
      if (
        window.hasOwnProperty(name) &&
        (name.includes('tawk') || name.includes('Tawk'))
      ) {
        delete window[name];
      }
    }

    /**
     * Almost the same code as original
     */
    window.Tawk_API = {};
    window.Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = typeLink[type]; //Here we use correct url for locale
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s1.setAttribute('tawk', 'yes'); //This line is used to mark tawk script
      s0.parentNode.insertBefore(s1, s0);
    })();
  }
}