<div class="auk-nav-tabs-container">
    <ul ngbNav #nav4="ngbNav" [(activeId)]="active" class="auk-nav-tabs auk-nav-tabs__sub nav-tabs border-0 px-0">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="border-0 font-weight-bolder">
                رابط
            </a>
            <ng-template ngbNavContent>
                <div class="form-group">
                    <label class="w-100 text-right">
                        رابط الفبديو
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" [(ngModel)]="videoURL" class="form-control" name="f_name">
                </div>
            </ng-template>
        </li>
        
        <li [ngbNavItem]="2">
            <a ngbNavLink class="border-0 font-weight-bolder">
                تحميل فيديو
            </a>
            <ng-template ngbNavContent>
                <!-- begin:: upload file form  -->
                <div class="form-group mb-3">
                    <label class="w-100 text-right text-small text-gray font-weight-bolder">
                        الرجاء تحديد فيديو للتحميل
                        <span class="text-danger">*</span>
                    </label>
                    <div class="custom-file cursor-pointer" dir="ltr">
                    <input #file [accept]="acceptedFiles" (change)="onFileChange($event)" type="file" class="custom-file-input cursor-pointer" id="inputGroupFile04">
                        <label class="custom-file-label cursor-pointer" for="inputGroupFile04">
                            {{!selectedFile ? 'Choose file' : selectedFile.name}}
                        </label>
                    </div>
                </div>
                <!-- end:: upload file form  -->

                <div class="note text-primary d-flex align-items-center">
                    <i class="fas fa-info-circle ml-1 text-primary"></i>
                    <span>الامتداد المسموح به هو : {{acceptedFiles}}</span>
                </div>

                <!-- <div class="action d-flex justify-content-end text-small">
                    <button type="button" class="btn btn-primary bg-primary py-2 font-weight-bolder">
                        استعراض
                    </button>
                </div> -->
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav4" class="p-5"></div>
</div>


<auk-message-area [showTemplates]="false" [required]="false" #messageArea (messageChange)="message = $event" label="وصف الفيديو (اختياري)" class="mt-5 d-block"></auk-message-area>
