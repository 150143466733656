import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {GridApi} from 'ag-grid-community';
import {LicenseManager} from 'ag-grid-enterprise';
import {TranslationService} from 'src/app/modules/i18n/translation.service';
import { GlobalAgTableColumns } from "src/app/shared/models/shared";

LicenseManager.setLicenseKey('CompanyName=viewclass.com,LicensedApplication=viewclass.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-018150,ExpiryDate=19_August_2022_[v2]_MTY2MDg2MzYwMDAwMA==7c207f1bd25c85068cc50703ee09b870');

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent implements OnInit {

  @Input() pageOptions;
  @Input() serverSideStoreType;
  @Input() rowModelType = 'clientSide';
  @Input() disableSelectOnClick = false;

  // Form details row
  @Input() masterDetail = false;
  @Input() detailCellRendererParams = false;
  @Input() showPageSizeChange: boolean = false;
  @Input() detailRowAutoHeight = false;
  @Input() enableRangeSelection = false;
  @Input() detailCellRenderer;
  @Input() headerHeight;

  @Input() height: any;
  @Input() maxHeight: any;
  @Input() rowData: any[];
  @Input() columnDefs: any[];
  @Input() defaultColDef: any;
  @Input() frameworkComponents: any;
  @Input() domLayout: any = 'normal';
  @Input() GlobalTable: boolean = false;
  @Input() excludeChildrenWhenTreeDataFiltering: boolean = false;
  @Input() pagination: boolean = false;
  @Input() sizeColumnsToFit: boolean = false;
  @Input() rowColors: { even: string, odd: string };
  
  @Input() rowSelection = 'multiple';
  @Input() sideBar;
  @Input() rowGroupPanelShow;
  @Input() pivotPanelShow;
  @Input() agGridPagination = false; 
  @Input() is_hr = false;
  @Input() is_delete = false;
  @Input() treeData = false;
  @Input() getDataPath = undefined;
  @Input() autoGroupColumnDef = undefined;
  @Input() groupDefaultExpanded = undefined;



  @Output() updateData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedRows: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageSizeChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() tableStateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnApi: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter<any>();

  rowStyle: any;
  rowClass: any;
  gridApi: GridApi;
  gridColumnApi: any;
  rowClassRules;

  localeText=this.translateService.instant('AG_GRID_LOCALE');
  constructor(
    private translateService: TranslateService,
    public translation: TranslationService
  ) {
  }

  ngOnInit(): void {
    // 
    if (this.GlobalTable) {
      this.columnDefs = this.mapColumnDefs(this.columnDefs);
    }
    this.setRowColors();
  }


  setRowColors(): void {
    this.rowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return {background: this.rowColors?.even};
      } else {
        return {background: this.rowColors?.odd};
      }
    };

    this.rowClassRules = {
      // 'bg-red': function(params) {
      //   return params.data?.status === 2;
      // },
      'bg-primary' : function(params) {
        return params.data?.rowBgStatus == 0 ||  params.data?.rowBgStatus == 4 ||  params.data?.rowBgStatus == 7;
      },
      'bg-success' : function(params) {
        return params.data?.rowBgStatus == 1;
      },
      'bg-red' : function(params) {
        return params.data?.rowBgStatus == 2;
      },
      'bg-orange' : function(params) {
        return params.data?.rowBgStatus == 3;
      },
      'bg-gray' : function(params) {
        return params.data?.rowBgStatus == 5;
      },
      'bg-orange2' : function(params) {
        return params.data?.rowBgStatus == 10;
      },
      'bg-success2' : function(params) {
        return params.data?.rowBgStatus == 11;
      },
      'bg-aqua' : function(params) {
        return params.data?.rowBgStatus == 12;
      },
      // 'seven-level' : function(params) {
      //   return params.data?.rowBgStatus == 6;
      // },
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.columnApi.emit(this.gridColumnApi); 
    if(!this.sizeColumnsToFit){
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 800);
    } else {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onSelectionChanged(evt) {
    const selectedRows = this.gridApi.getSelectedRows();
    // this.gridApi.redrawRows();
    this.selectedRows.emit(this.disableSelectOnClick ? selectedRows : selectedRows[0] || null);
  }

  onPageSizeChanged(newPageSize) {
    this.pageSizeChange.emit(newPageSize);
  }

  onSelectedRowChanged(evt) {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows.emit(this.disableSelectOnClick ? selectedRows : selectedRows[0] || null);
  }

  onSortChange(evt) {
    this.sortChanged.emit(evt);
  }

  onFilterChanged(evt) {
    this.onFilterChange.emit(evt);
  }

  onDragEnd(evt) {
    const itemsToUpdate = [];

    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      itemsToUpdate.push(rowNode.data);
    });

    this.rowData = itemsToUpdate;
    this.updateData.emit(this.rowData);
  }

  // pagination
  paginationEvent(event) {
    this.pageEvent.emit(event);
  }

  onColumnVisibleChange(e){
    this.tableStateChange.emit(true)
  }

  mapColumnDefs(columns: GlobalAgTableColumns[]) {
    return columns.map(col => {
      return {
        ...col,
        headerName: col.headerName ? this.translateService.instant(col.headerName) : ''
      };
    });
  }

}