import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatTableModule } from "@angular/material/table"
import { MatButtonModule } from "@angular/material/button"
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatFormFieldModule } from "@angular/material/form-field"
import { NgSelectModule } from "@ng-select/ng-select"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component"
import { NumbersOnlyDirective } from "./directives/number-only.directive"
import { AgGridTableModule } from "./shared-modules/ag-grid/ag-grid.module"
import { ShServicesComponent } from "./components/sh-services/sh-services.component"
import { DebounceInputChangeDirective } from "./directives/input-change-debounce.directive"
import { TranslateModule } from "@ngx-translate/core"
import { MatNativeDateModule } from "@angular/material/core"
import { RouterModule } from "@angular/router"
import { ExpectedCustomerFormComponent } from "../modules/crm/components/expected-customers-lis/expected-customer-form/expected-customer-form.component"
import { ExpectedCustomersLisComponent } from "../modules/crm/components/expected-customers-lis/expected-customers-lis.component"
import { NgxIntlTelInputModule } from "ngx-intl-tel-input"
import { ArabicLettersOnlyDirective } from "./directives/arabic-letters-only.directive"
import { EnglishLetterOnlyDirective } from "./directives/english-letter-only.directive"
import { GoogleMapsModule } from "@angular/google-maps"
import { MatListModule } from "@angular/material/list"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatIconModule } from "@angular/material/icon"
import { NoDataFoundComponent } from "./components/no-data-found/no-data-found.component"
import { SharedPaymentMethodsComponent } from "./components/shared-payment-methods/shared-payment-methods.component"
import { NumbersExeclSheetViewDialogComponent } from "./components/numbers-execl-sheet-view-dialog/numbers-execl-sheet-view-dialog.component"
import { MatMenuModule } from "@angular/material/menu"
import { PhoneNumberInputComponent } from "./components/phone-number-input/phone-number-input.component"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { PipesModule } from "./pipes/pipes.module"
import { WhatsStripeErrorComponent } from "../modules/whats-app/components/whats-packages/whats-stripe-error/whats-stripe-error.component"
import { WhatsStripeSuccessComponent } from "../modules/whats-app/components/whats-packages/whats-stripe-success/whats-stripe-success.component"
import { MatTabsModule } from "@angular/material/tabs"
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component"
import { ErrorsDialogComponent } from "./components/errors-dialog/errors-dialog.component"
import { MessagesModule } from "./shared-modules/messages/messages.module"
import { RemoveSpaceInputDirective } from "./directives/remove-space-input.directive"
import { NotValidIpComponent } from "./components/not-valid-ip/not-valid-ip.component"
import { TermsAndConditionsDialogComponent } from "./components/terms-and-conditions-dialog/terms-and-conditions-dialog.component"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { DebounceClickDirective } from "./directives/debounce-click.directive"
import { ButtonComponent } from "./components/button/button.component"
import { PrintViewHeaderComponent } from "./components/print-view-header/print-view-header.component"
import { FormFocusDirective } from "./directives/form-focus.directive"
import { AukGridModule } from "./shared-modules/auk-grid/auk-grid.module"
import { LoadingModule } from "./shared-modules/loading/loading.module"
import { PayWithBankClearficationFileComponent } from "./components/pay-with-bank-clearfication-file/pay-with-bank-clearfication-file.component"
import { PayWithBankCleaficationFileInputComponent } from "./components/pay-with-bank-cleafication-file-input/pay-with-bank-cleafication-file-input.component"
import { PayWithBankCleaficationFileViewComponent } from "./components/pay-with-bank-cleafication-file-view/pay-with-bank-cleafication-file-view.component"
import { NgxPermissionsModule } from "ngx-permissions"
import { SubAccountsAutoCompleteComponent } from "./components/sub-accounts-auto-complete/sub-accounts-auto-complete.component"
import { SchedulerComponent } from "./components/message-scheduler/scheduler.component"
import { RemoveDashDirective } from "./directives/remove-dashed.directive"
import { SharedDirectivesModule } from "./shared-modules/shared-directives/shared-directives.module"

@NgModule({
  declarations: [
    ComingSoonComponent,
    NumbersOnlyDirective,
    RemoveSpaceInputDirective,
    ShServicesComponent,
    ConfirmDialogComponent,
    SchedulerComponent,
    DebounceInputChangeDirective,
    DebounceClickDirective,
    ExpectedCustomersLisComponent,
    ExpectedCustomerFormComponent,
    ArabicLettersOnlyDirective,
    EnglishLetterOnlyDirective,
    NoDataFoundComponent,
    SharedPaymentMethodsComponent,
    NumbersExeclSheetViewDialogComponent,
    PhoneNumberInputComponent,
    WhatsStripeErrorComponent,
    WhatsStripeSuccessComponent,
    ErrorsDialogComponent,
    NotValidIpComponent,
    TermsAndConditionsDialogComponent,
    ButtonComponent,
    PrintViewHeaderComponent,
    FormFocusDirective,
    PayWithBankClearficationFileComponent,
    PayWithBankCleaficationFileInputComponent,
    PayWithBankCleaficationFileViewComponent,
    SubAccountsAutoCompleteComponent,
    RemoveDashDirective,
  ],
  imports: [
    FormsModule,
    NgxPermissionsModule,
    CommonModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    MatTabsModule,
    MatDatepickerModule,
    NgSelectModule,
    NgbModule,
    AgGridTableModule,
    MatNativeDateModule,
    MatListModule,
    RouterModule,
    NgxIntlTelInputModule,
    GoogleMapsModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    PipesModule,
    MessagesModule,
    MatCheckboxModule,
    AukGridModule,
    LoadingModule,
    SharedDirectivesModule,
  ],
  exports: [
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    SchedulerComponent,
    NgSelectModule,
    PipesModule,
    NgbModule,
    AgGridTableModule,
    ComingSoonComponent,
    DebounceInputChangeDirective,
    ShServicesComponent,
    PhoneNumberInputComponent,
    NumbersOnlyDirective,
    MatNativeDateModule,
    MatListModule,
    MatTabsModule,
    RouterModule,
    ExpectedCustomersLisComponent,
    ExpectedCustomerFormComponent,
    NgxIntlTelInputModule,
    ArabicLettersOnlyDirective,
    EnglishLetterOnlyDirective,
    GoogleMapsModule,
    MatToolbarModule,
    MatIconModule,
    DebounceClickDirective,
    NoDataFoundComponent,
    SharedPaymentMethodsComponent,
    NumbersExeclSheetViewDialogComponent,
    WhatsStripeErrorComponent,
    WhatsStripeSuccessComponent,
    MessagesModule,
    RemoveSpaceInputDirective,
    ButtonComponent,
    PrintViewHeaderComponent,
    FormFocusDirective,
    LoadingModule,
    PayWithBankClearficationFileComponent,
    PayWithBankCleaficationFileInputComponent,
    PayWithBankCleaficationFileViewComponent,
    NgxPermissionsModule,
    SubAccountsAutoCompleteComponent,
    RemoveDashDirective,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class SharedModule {}
