import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { FileService } from 'src/app/shared/services/file.service';
import { HelperService } from 'src/app/shared/services/helpers.service';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { SavedResponsesDialogComponent } from '../../saved-responses-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponseStatus } from 'src/app/shared/models/shared';

@Component({
  selector: 'edit-message-template',
  templateUrl: './edit-message-template.component.html',
  styleUrls: ['./edit-message-template.component.scss']
})
export class EditMessageTemplateComponent implements OnInit {


  @Output() afterUpdate: EventEmitter<any> = new EventEmitter()
  @Output() backToList: EventEmitter<any> = new EventEmitter()

  @Input() set template(temp){
    this.msgTemplate = temp;
    this.templateContent = JSON.parse(this.msgTemplate?.text);
    
  }

  @Input() number: any;
  @Input() numberId: any;
  @Input() inAddMode: boolean = false;
  
  message: any
  templateName;
  msgTemplate: any;
  templateContent: any;
  subscription: Subscription = new Subscription();

  constructor(
    private _FileService: FileService,
    private _WhatsService: WhatsService,
    private _HelperService: HelperService,
    public _MessagesService: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _NgxSpinnerService: NgxSpinnerService,
    public _MatDialogRef: MatDialogRef<SavedResponsesDialogComponent>,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }


  onFileChange(event: any, index: number): void {
    
    if (event.target.files.length > 0) {
      let file = event.target.files[0];         
      
      if (file) {

        this._NgxSpinnerService.show();
        const body = new FormData();
        body.append('file', file);
        this.subscription.add(
          this._WhatsService.addToGallery(body).subscribe(res => {
            this._NgxSpinnerService.hide();
            this._MessagesService.showSuccessMessage('تم اضافه المرفق بنجاح');
            
            this.templateContent[index].url = res?.item?.file_url;
            this.templateContent[index].filename = this._HelperService.getFileName(file);
          },err => {
            this._NgxSpinnerService.hide();
            if(err.status != HttpErrorResponseStatus.unprocessableEntity){
              this.message.showErrorMessage(err?.error?.message ? err?.error?.message : 'حدث خطأ ما');
            }
          })
        )
      }
    }
  }



  openFile(file: any): void {
    this._FileService.openFileURL(file?.url)
  }


  submit() {

    if (!this.validMessages()) return

    const payload = this.prepareDataBeforeSent();

      this.subscription.add(
        this._WhatsService.addMessageTemplate({
          text: JSON.stringify(payload?.text),
          name: payload?.name,
          id: payload?.id
        }).subscribe(res => {
          this._MessagesService.showSuccessMessage('تم الاضافه بنجاح');
          this.afterUpdate.emit()
        })
      )
  }

  // Start::Shared Message Functions
  validMessages(){
    if (!this.msgTemplate.name){
      this._MessagesService.showErrorMessage(' يجب ادخال اسم القالب');
      return false;
    } 
    return true;
  }



  prepareDataBeforeSent(): any {
    const data = {
      ...this.msgTemplate,
      text: this.templateContent
    };
    return data;
  }


  errorHandller(error: HttpErrorResponse): void {
    this._MessagesService.showErrorMessage(error?.message);
  }


  onBack(): void {
    this.backToList.emit();
  }
}
