export enum QueryParamKeys {
    // Sms
    SmsArchiveUserId = 'user_id',
    SmsArchiveUserName = 'user_name',
    // General
    LoginAsSupportAdminToken = "login_token",
    reload = "reload",
    // Change User Data
    ConfirmChangeData = "confirm",
    RejectChangeData = "reject",
    ChangeToken = "change_token",
    Email = "email",
    Mobile = "mobile",
    WhatsMobile = "w_mobile",
    // Tickets System
    next = "next",
    ticketId = "ticketId",
    SelectedTicketId = "seTicketId",
    payhyperpay = "payhyperpay",
    is_mada = "is_mada",
    HyperPaySettiontype = "session_type",
    CancelUseSenderRequest = 'cancel_request_sender',
    RefuseUseSenderRequest = 'refuse_request_sender',
    AcceptUseSenderRequest = 'accept_request_sender',
    // Guest
    marketerId = "m",
    c_uuid = "c_uuid",
    cr_number = "cr_number",
    // Payment 
    PayFail = 'fail',
    PaySuccess = 'success',
    PayTicketId = 'ticket',
    PayKnetChargeId = 'tap_id',
    payService = 'srv',
    // Senders Page
    TabIndex = 'index',
}