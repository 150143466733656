<div class="mat-dalog-header bg-primary border-bottom py-5 px-8 mb-8 d-flex justify-content-between">
    <h3 class="mb-0 text-white font-weight-bolder" mat-dialog-title>
        حفظ قوالب الإرسال
    </h3>
    <div class="end d-flex align-items-center">
        <button mat-button mat-dialog-close class="min-w-auto line-height-normal p-2 popup-close-btn px-0">
            <i class="fas fa-times text-white"></i>
        </button>
    </div>
</div>

<mat-dialog-content class="mb-5 px-8">

    <div class="row">
        <!-- Start:: Form -->
        <div class="col-md-12">
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="exampleFormControlTextarea1"
                        class="ml-auto font-weight-bolder">عنوان القالب: 
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" name="title" placeholder="من فضلك ادخل عنوان القالب" [(ngModel)]="body.name" />
                    <ng-container *ngIf="formErrors?.name" >
                        <div class="fv-plugins-message-container" *ngFor="let error of formErrors?.name">
                            <p class="fv-help-block text-right m-0">
                                {{ error }}
                            </p>
                        </div>
                    </ng-container>
                    
                </div>
                <div class="form-group col-md-12">
                    <textarea class="form-control" dir="auto" id="exampleFormControlTextarea1" rows="6" [(ngModel)]="body.text" name="template" >
                    </textarea>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>


<mat-dialog-actions align="end" class="px-8 py-3 border-top">
    <button class="btn btn-secondary font-weight-bolder px-3" mat-button
        mat-dialog-close>الغاء</button>

    <button class="btn btn-primary font-weight-bolder px-3" mat-button
        cdkFocusInitial (click)="saveTemplate()" >حفظ</button>
</mat-dialog-actions>