import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Constant } from "src/app/shared/config/constant";
import { BrowserDbService } from "src/app/shared/services/browser-db.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class SmsService {
    private baseSmsURL = environment.sma.apiUrl;
    private baseURL = environment.apiUrl;
    smsSendMessagesArr: BehaviorSubject<any> = new BehaviorSubject({});
    smsSendMessagesNumbersArr: BehaviorSubject<any> = new BehaviorSubject([]);
    selectedPackagesToPay: BehaviorSubject<any> = new BehaviorSubject([]);
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
    })

    constructor(
        private _Http: HttpClient,
        private browserDbService: BrowserDbService
        ) { }

    getSendersList(params?: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/senders`;
        return this._Http.get<any>(url, {
            params: {
                ...params,
            },
            headers: this.headers
        });
    }

    getMessageTemplates(params?: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/templates`;
        return this._Http.get<any>(url, {
            params: {
                ...params,
            },
            headers: this.headers
        });
    }

    saveMessageTemplate(body?: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/templates/store`;
        return this._Http.post<any>(url, body, {
            headers: this.headers
        });
    }

    deleteMessageTemplate(id: number): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/templates/${id}/delete`;
        return this._Http.delete<any>(url, {
            headers: this.headers
        });
    }

    smsSendMessage(body: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/send`;
        return this._Http.post<any>(url, body, {
            headers: this.headers
        });
    }

    smsSendTrialMessage(body: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/send-trial`;
        return this._Http.post<any>(url, body, {
            headers: this.headers
        });
    }

    smsSendMessageWithParams(body: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/send-params`;
        return this._Http.post<any>(url, body, {
            headers: this.headers
        });
    }

    smsRemoveScheduleMessage(message_id): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/delete/${message_id}`;
        return this._Http.delete<any>(url, {
            headers: this.headers
        });
    }

    smsApproveScheduleMessage(message_id,params): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/approve-status-change/${message_id}`;
        return this._Http.patch<any>(url,params);
    }

    getSmsAuthenticationData(): Observable<any> {
        const url = `${environment.apiUrl}/account/profile/sms-service-app-id`;
        return this._Http.get<any>(url).pipe(
            tap(res => {
                if (res?.item) {
                    this.browserDbService.setItem(Constant.smsAuthData,btoa(res?.item?.app_id + ':' + res?.item?.app_secret));
                }
            })
        );
    }

    // Excel Sheet
    uploadExcelSheet(formData: FormData): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/excel-sheets`;
        return this._Http.post<any>(url, formData);
    }

    getExcelSheetValues(params): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/excel-sheets-values`;
        return this._Http.get<any>(url, {
            params: params,
            headers: this.headers
        });
    }

    deleteExcelSheetValues(id): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/excel-sheets-delete`;
        return this._Http.delete<any>(url, {
            headers: this.headers
        });
    }

    // Set Sender As Default
    setSenderAsDefault(id, params): Observable<any> {
        const url = `${this.baseSmsURL}account/area/senders/${id}/set-default`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }


    // Set Sender As Default
    checkNumbers(payload): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/check-numbers`;
        return this._Http.patch<any>(url, payload, {
            headers: this.headers
        });
    }


    // Set Sender As Default
    getArchive(params: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/archive`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    getMessageDetailedArchive(params: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    // Set Sender As Default
    getNumbersMessageArchive(messageId: any, params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/archive/${messageId}/numbers`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    getMassiveNumbersMessageArchive(messageId: any, params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/numbers`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params : {
                ...params,
                sms_message_id : messageId
            }
        });
    }

    // Packages
    getPackagesList(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/lists/packages-grouping`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    getUserActivePackagesList(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/packages`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params : {
                ...params,
                only_me : !params.sub_account_id ? 1 : 0,
            }
        });
    }

    getPackageDataById(id): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/packages/${id}`;
        return this._Http.get<any>(url, {
            headers: this.headers,
        });
    }

    payPackages(payload): Observable<any> {
        const url = `${this.baseSmsURL}account/sms/buy/pay`;
        return this._Http.post<any>(url, payload, {
            headers: this.headers
        });
    }

    getPackageDetailsData(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }


    // Excel Sheet
    sendFile(formData: FormData): Observable<any> {
        const url = `${this.baseSmsURL}account/area/sms/send-file`;
        return this._Http.post<any>(url, formData);
    }

    // sales
    getSalesPackages(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    getSalesPackagesDetails(id,params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/${id}/find`;
        return this._Http.get<any>(url,{
            headers: this.headers,
            params: params,
        });
    }

    SalesAssignPackageToUser(package_id, body): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/${package_id}/assign`;
        return this._Http.put(url, body);
    }

    SalesDeleteAssignPackageToUser(package_id, body): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/${package_id}/remove-assign`;
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body
        };
        return this._Http.delete(url, options);
    }

    /**
     * 
     * @param id 
     * @param status 1 = active, 2 = inactive
     * @returns 
     */
    changePackageStatus(id, status:1 | 2): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/${id}/status/${status}`;
        return this._Http.get(url,{
            headers: this.headers,
        });
    }

    /**
     * 
     * @param id 
     * @param status 1 = true, 2 = false
     * @returns 
     */
    setPackageAsDefault(id, status:1 | 2): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/${id}/for-all/${status}`;
        return this._Http.get(url,{
            headers: this.headers,
        });
    }

    // Sms Webhooks
    getSmsWebhooks(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/webhook`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    createSmsWebhooks(body): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/webhook`;
        return this._Http.patch<any>(url, body, {
            headers: this.headers
        });
    }

    // Users Does Not Have balance
    getUsersDoesNotHaveBalance(params?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/markter-packages/sub-accounts-reports`;
        return this._Http.get<any>(url, {
            headers: this.headers,
            params
        });
    }

    changeConnectStatus(body): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/set-as-contact`;
        return this._Http.patch(url,body);
    }

    setUserContactStatusComment(body): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/comment-contact`;
        return this._Http.patch(url,body);
    }

    getDashboardSmsStatistics(): Observable<any> {
        const url = `${this.baseSmsURL}account/area/me/dashboard`
        return this._Http.get(url,{
            headers : {
                'content-type': 'application/json',
            }
        });
    }

    getSmsProfileData(){
        const url = `${this.baseSmsURL}account/area/me`
        return this._Http.get(url,{
            headers : {
                'content-type': 'application/json',
            }
        });
    }

    getGeneralSmsSettings(queryParams?): Observable<any> {
        const url = `${this.baseURL}/account/general-settings`
        return this._Http.get(url,{
            headers : {
                'content-type': 'application/json',
            },
            params : queryParams
        });
    }
    exportArchiveSMS(queryParams?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/exports/all-messages`;
        return this._Http.get(url, {
            headers : {
                'content-type': 'application/json',
            },
            params : queryParams
        });
    }
    exportArchiveSMSFile(key?): Observable<any> {
        const url = `${this.baseSmsURL}account/area/account-messages-archives/` + key;
        return this._Http.post(url, {}, {headers : {
                'content-type': 'application/json',
            }});
    }
    deleteArchiveSMSFile(key?): Observable<any> {
        return this._Http.delete(`${this.baseSmsURL}account/area/account-messages-archives/` + key);
    }
    exportArchiveList(params?: any): Observable<any> {
        const url = `${this.baseSmsURL}account/area/account-messages-archives/`;
        return this._Http.get(url, { headers : {
                'content-type': 'application/json',
            }, params});
    }
}
