import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { FileService } from 'src/app/shared/services/file.service';
import { HelperService } from 'src/app/shared/services/helpers.service';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { SavedResponsesDialogComponent } from '../../saved-responses-dialog.component';

@Component({
  selector: 'preview-and-send',
  templateUrl: './preview-and-send.component.html',
  styleUrls: ['./preview-and-send.component.scss']
})
export class PreviewAndSendComponent implements OnInit {


  @Output() backToList: EventEmitter<any> = new EventEmitter();
  @Output() chooseTemplate: EventEmitter<any> = new EventEmitter();

  @Input() set template(temp) {
    this.msgTemplate = temp;
    this.templateContent = JSON.parse(this.msgTemplate?.text);

  }

  @Input() number: any;
  @Input() numberId: any;
  @Input() previewOnly: boolean = false;

  message: any
  msgTemplate: any;
  templateContent: any;
  subscription: Subscription = new Subscription();
  errorOccured: any;

  constructor(
    private _FileService: FileService,
    private _WhatsService: WhatsService,
    private _HelperService: HelperService,
    public _MessagesService: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _MatDialogRef: MatDialogRef<SavedResponsesDialogComponent>,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }


  onFileChange(event: any, index: number): void {

    if (event.target.files.length > 0) {
      let file = event.target.files[0];

      if (file) {
        this._HelperService.convertFileToBase64(file).then((result: string) => {

          this.templateContent[index].file = result;
          this.templateContent[index].fileName = this._HelperService.getFileName(file);
        })

      }
    }
  }

  openFile(file: any): void {
    this._FileService.openFileURL(file?.url)
  }

  submit(): void {
    const messages = this.prepareDataBeforeSent();
    // this.errorOccurred = false; // Initialize errorOccurred flag
  
    const observables = messages.map(message => this.sendMessage(message));
  
    forkJoin(observables).subscribe(
      (results: any[]) => {
        const hasError = results.some(result => result instanceof HttpErrorResponse);
        if (hasError) {
          const firstError = results.find(result => result instanceof HttpErrorResponse);
          if (firstError && firstError.error?.code == 400) {
            this._MessagesService.showErrorMessage(firstError.error?.message);
          }
        } else {
          this._MessagesService.showSuccessMessage('تم الإرسال بنجاح');
          this._MatDialogRef.close();
        }
      }
    );
  }
  
  sendMessage(payload: any): Observable<any> {
    return this._WhatsService.sendOneMessage(this.numberId, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return of(error);
      })
    );
  }

  prepareDataBeforeSent(): any {
    // const data = {
    //   globals: {
    //     number_iso: environment.number_iso,
    //     time_frame: 10
    //   },
    //   messages: [],
    // };

    const messages: any[] = [];

    this.templateContent.forEach(el => {

      // let message: any = {
      //   data: {},
      //   numbers: [this.number]
      // };

      let body: any = {
        path: null,
        params: {
          phone: this.number,
          body: null
        }
      };

      switch (el?.type) {
        case 'image':
        case 'video':
        case 'file':
        case 'audio':
          body.path = 'message/file';
          body.params.type = el?.filename ? this.getFileType(el?.filename) : el?.type;
          body.params.body = el?.file ? el?.file : el?.url;
          body.params.filename = el?.filename || el?.type;
          body.params.caption = el?.caption ? el?.caption : '';
          break;

        case 'location':
          body.path = 'message/location';
          body.params.lat = el?.lat;
          body.params.lng = el?.lng;
          body.params.address = el?.address;
          body.params.name = el?.address;
          break;

        case 'text':
          body.path = 'message/text';
          body.params.body = el?.body;
          break;

        default:
          break;
      }

      messages.push(body);
    });

    return messages;
  }

  getFileType(fileName: string): string {
    const parts: string[] = fileName.split('.');
    if (parts.length > 1 && parts[parts.length - 1]) {
      const part: string = parts[parts.length - 1];
      const imageExtensions: string[] = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
      const audioExtensions: string[] = ["mp3", "wav", "aac", "ogg", "m4a", "amr"];
      const documentExtensions: string[] = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "rtf"];
      const videoExtensions: string[] = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

      if (imageExtensions.includes(part.toLowerCase())) {
        return 'image';
      } else if (audioExtensions.includes(part.toLowerCase())) {
        return 'audio';
      } else if (documentExtensions.includes(part.toLowerCase())) {
        return 'document';
      } else if (videoExtensions.includes(part.toLowerCase())) {
        return 'video';
      }
    }
    return 'file';
  }

  choose() {
    this.chooseTemplate.emit(this.msgTemplate);
    this.onBack();
  }


  errorHandller(error: HttpErrorResponse): void {
    this._MessagesService.showErrorMessage(error?.message);
  }


  onBack(): void {
    this.backToList.emit();
  }
}
