<div class="d-flex align-items-center justify-content-center action-btns">
    <!--    <button class="btn mx-2" [ngStyle]="{'background-color': action?.backgroundColor, 'color': action?.color}" *ngFor="let action of actions" (click)="handleAction(action?.action)">-->
    <!--        <i class="fa fa-{{action?.action}} p-0" [ngStyle]="{'color': action?.color}"></i>-->
    <!--    </button>-->
    <ng-container *ngFor="let action of actions">
        <!-- *ngxPermissionsOnly="action?.permissions" -->
        <ng-container *ngIf="action.action != 'more' && action.action != 'buttons' ">
            <button class="mx-2 action-btn" mat-icon-button [disabled]="disabled" [ngStyle]="action.style"
                (click)="handleAction(action?.action)" *ngIf="(action?.showActionProp ? params.data[action?.showActionProp] : true)" >
                <mat-icon>{{action.action}}</mat-icon>
            </button>
        </ng-container>

        <button mat-button class="text-white p-1 btn" [matMenuTriggerFor]="menuOne" [ngStyle]="action.style"
            *ngIf="action.action == 'more' && (action?.showActionProp ? params.data[action?.showActionProp] : true)">
            {{action?.name ? action?.name : '...'}}
            <mat-menu #menuOne="matMenu">
                <ng-container *ngFor="let item of action?.moreActions">
                    <button class="parents-actions" mat-menu-item *ngIf="item?.showProp ? params.data[item?.showProp] : true"
                    (click)="handleAction(item?.action)">
                    {{item.title}}
                </button>
                </ng-container>
            </mat-menu>
        </button>

        <ng-container *ngIf="action.action == 'buttons'">
            <ng-container *ngFor="let item of action.btnActions">
                <button class="text-white btn btn-primary p-1 mx-2" [ngClass]="item?.cssClass" [ngStyle]="action.style"
                    (click)="handleAction(item?.action)" *ngIf="item?.showProp ? params.data[item?.showProp] : true"  >
                    {{item?.name}}
                </button>
                <p *ngIf="item?.showText && (item?.showProp ? !params.data[item?.showProp] : true)">
                    {{item?.textMessage}}
                </p>
            </ng-container>
        </ng-container>

    </ng-container>
</div>