import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {


  @Input() imagPath:  string = 'assets/media/users/adel.jpg'
  @Input() caption:  boolean = true;
  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

}
