import { Injectable } from '@angular/core';

export const GSM_7BIT = 'GSM_7BIT';
export const GSM_7BIT_EX = 'GSM_7BIT_EX';
export const UTF16 = 'UTF16';

export const messageLength: any = {
    GSM_7BIT: 160,
    GSM_7BIT_EX: 160,
    UTF16: 70,
};
export const multiMessageLength: any = {
    GSM_7BIT: 153,
    GSM_7BIT_EX: 153,
    UTF16: 67,
};

export interface ICounterResult {
    encoding: string;
    length: number;
    messages: number;
    per_message: number;
    remaining: number;
}

@Injectable({ providedIn: 'root' })
export class SmsMessageService {
    gsm7bitChars = `@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà`;
    gsm7bitExChar = `\\^{}\\\\\\[~\\]|€`;
    gsm7bitRegExp = RegExp(`^[${this.gsm7bitChars}]*$`);
    gsm7bitExRegExp = RegExp(`^[${this.gsm7bitChars + this.gsm7bitExChar}]*$`);
    gsm7bitExOnlyRegExp = RegExp(`^[\\${this.gsm7bitExChar}]*$`);
    constructor() { }

    detectEncoding(text: string): string {
        switch (false) {
            case text.match(this.gsm7bitRegExp) === null:
                return GSM_7BIT;
            case text.match(this.gsm7bitExRegExp) === null:
                return GSM_7BIT_EX;
            default:
                return UTF16;
        }
    }

    countGsm7bitEx(text: string): number {
        const results: string[] = [];

        for (const char of text) {
            if (char.match(this.gsm7bitExOnlyRegExp) !== null) {
                results.push(char);
            }
        }

        return results.length;
    }

    count(text: string): ICounterResult {
        const encoding = this.detectEncoding(text);
        let length = text.length;
        if (encoding === GSM_7BIT_EX) {
            length += this.countGsm7bitEx(text);
        }

        let perMessage = messageLength[encoding];
        if (length > perMessage) {
            perMessage = multiMessageLength[encoding];
        }

        const messages = Math.ceil(length / perMessage);
        let remaining = (perMessage * messages) - length;
        if (remaining === 0 && messages === 0) {
            remaining = perMessage;
        }

        return { encoding, length, per_message: perMessage, remaining, messages };
    }

}