import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkStatusComponent } from "./network-status/network-status.component";
import { SharedModule } from "../../shared.module";



@NgModule({
  declarations: [NetworkStatusComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports : [NetworkStatusComponent]
})
export class NetworkStatusModule { }
