import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { Constant } from "src/app/shared/config/constant";
import { BrowserDbService } from "src/app/shared/services/browser-db.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class LoginService {
    href = '';
    constructor(
        private httpClient: HttpClient,
        private browserDbService: BrowserDbService
    ) {
        this.href = window.location.href;
    }

    registerWithMobile(body) {
        let url = `${environment.apiUrl}/register-request/mobile`
        return this.httpClient.post(url, body)
    }

    resendCodeToMobile(body) {
        let url = `${environment.apiUrl}/register-request/resend-mobile-code`
        return this.httpClient.post(url, body)
    }

    activeMobile(body) {
        let url = `${environment.apiUrl}/register-request/active-mobile`
        return this.httpClient.post(url, body)
    }

    setUserData(body) {
        let url = `${environment.apiUrl}/register-request/store-details`
        return this.httpClient.post(url, body)
    }

    public getIPAddress() {
        return of({
            countrycode : 'sa'
        })
        // return this.httpClient.get("https://iplist.cc/api?no-token=true");
    }

    // Mobile
    changeMobile(body) {
        let url = `${environment.apiUrl}member/change/mobile/new`
        return this.httpClient.post(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    resendCodeMobile() {
        let url = `${environment.apiUrl}member/change/mobile/resend-code`
        return this.httpClient.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    setCodeMobile(body) {
        let url = `${environment.apiUrl}member/change/mobile/set-code`
        return this.httpClient.patch(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    // Email
    changeEmail(body) {
        let url = `${environment.apiUrl}member/change/email/new`
        return this.httpClient.post(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    resendCodeEmail() {
        let url = `${environment.apiUrl}member/change/email/resend-code`
        return this.httpClient.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    setCodeEmail(body) {
        let url = `${environment.apiUrl}member/change/email/set-code`
        return this.httpClient.patch(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    // Verify mobile
    verifyMobile() {
        let url = `${environment.apiUrl}member/verify/mobile/send-code`
        return this.httpClient.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    setCodeVerifyMobile(body) {
        let url = `${environment.apiUrl}member/verify/mobile/set-code`
        return this.httpClient.patch(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }
    // Verify email
    verifyEmail() {
        let url = `${environment.apiUrl}member/verify/email/send-code`
        return this.httpClient.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    setCodeVerifyEmail(body) {
        let url = `${environment.apiUrl}member/verify/email/set-code`
        return this.httpClient.patch(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.browserDbService.getItem(Constant.token)}`
            })
        });
    }

    activeTwoStep(body) {
        let url = `${environment.apiUrl}member/update-settings`
        return this.httpClient.patch(url, body, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }

    setMemberArea() {
        let url = `${environment.apiUrl}member`;
        return this.httpClient.get(url, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }


    // Add user devices 
    askUserToAddDevice(): Observable<any> {
        let url = `${environment.apiUrl}/account/devices/ask-store`
        return this.httpClient.patch(url, {}, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }

    addDevice(): Observable<any> {
        let url = `${environment.apiUrl}/account/devices/store`
        return this.httpClient.post(url,{}, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }

    addDeviceSendCode(type: 'email' | 'mobile' | 'google',isSub?): Observable<any> {
        const url = `${environment.apiUrl}/account/profile/device/${isSub ? 'sub/' : ''}verify/${type}/send-code`;
        return this.httpClient.get(url,{
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }
    
    addDeviceSetCode(body,type: 'email' | 'mobile' | 'google',isSub?): Observable<any> {
        const url = `${environment.apiUrl}/account/profile/device/${isSub ? 'sub/' : ''}verify/${type}/set-code`;
        return this.httpClient.patch(url,body,{
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            })
        });
    }


}