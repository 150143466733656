import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { FileTypes } from 'src/app/modules/auk-whatsapp/enums/file-types.enum';
import { WhatsService } from 'src/app/modules/whats-app/services/whats.service';
import { HttpErrorResponseStatus } from 'src/app/shared/models/shared';
import { HelperService } from 'src/app/shared/services/helpers.service';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { MessageAreaComponent } from 'src/app/shared/shared-modules/message-area/message-area.component';

@Component({
  selector: 'send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.scss']
})
export class SendFileComponent implements OnInit {

  // Components 
  @ViewChild("messageArea") MessageAreaComponent: MessageAreaComponent;

  message: any;
  fileURL: string = '';
  fileName: string = 'Hello';
  isClear: boolean = false;


  active: number = 1;
  selectedFile: File = null;
  selectedFileType: FileTypes;
  subscription: Subscription = new Subscription();
  acceptedFiles: any = ['.pdf', '.xlsx', '.zip', '.rar', '.doc', '.docx'].join(",") ;


  constructor(
    public _Whats: WhatsService,
    private _Helpers: HelperService,
    private _MessagesService: MessagesService,
    private _NgxSpinnerService: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
  
  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];         
      
      if (!this.acceptedFiles.split(',').includes('.'+this._Helpers.getFileExtension(file))) {        
        this._MessagesService.showErrorMessage(`يجب أن يكون الملف من النوع: ${this.acceptedFiles}`);
        return
      } else {
        if (file) {
          this.addFileToGallery(file);
        }
      }
    }
  }

  
  addFileToGallery(file) {
    this._NgxSpinnerService.show();
    const body = new FormData();
    body.append('file', file);
    this.subscription.add(
      this._Whats.addToGallery(body).subscribe(res => {
        this._NgxSpinnerService.hide();
        this._MessagesService.showSuccessMessage('تم اضافه المرفق بنجاح');
        
        this.selectedFileType = ((this._Helpers.getFileExtension(file) as string).toUpperCase() as FileTypes);
        this.fileURL = res?.item?.file_url;
        this.fileName = this._Helpers.getFileName(file);
      },err => {
        this._NgxSpinnerService.hide();
        if(err.status != HttpErrorResponseStatus.unprocessableEntity){
          this.message.showErrorMessage(err?.error?.message ? err?.error?.message : 'حدث خطأ ما');
        }
      })
    )
  }


  clearMessage(): void {
    this.message = null;
    this.MessageAreaComponent.clear();
  }


  onNavChanges(): void {
    this.fileURL = null;
    this.fileName = '';
    this.selectedFile = null;
    
    
  }



  onInsertURL(): void {
    this.selectedFileType = ((this._Helpers.getFileExtensionFromUrl(this.fileURL) as string)?.toUpperCase() as FileTypes);
  }


}
