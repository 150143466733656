import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Constant } from "src/app/shared/config/constant";
import { QueryParamKeys } from "src/app/shared/enums/query-params-kys.enum";
import { RoutingPathsEnum } from "src/app/shared/enums/routing.enum";
import { BrowserDbService } from "src/app/shared/services/browser-db.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private shared:SharedService,
    private browserDbService: BrowserDbService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectUrl = this.authService.redirectUrl.value;
    const token = this.browserDbService.getItem(Constant.token);
    let isExpired:boolean =  true;
    if(token){
      isExpired = this.shared.tokenExpired(token);
    }
    const user = this.browserDbService.getItem(Constant.user);
    if (user && !isExpired) {
      // logged in so return true
      return true;
    }

    localStorage.clear();

    if (route.queryParams[QueryParamKeys.ConfirmChangeData] && route.queryParams[QueryParamKeys.ChangeToken]) {

      this.router.navigate([RoutingPathsEnum.Gust], {
        queryParams: {
          [QueryParamKeys.ConfirmChangeData]: route.queryParams[QueryParamKeys.ConfirmChangeData],
          [QueryParamKeys.ChangeToken]: route.queryParams[QueryParamKeys.ChangeToken],
          [QueryParamKeys.Email]: route.queryParams[QueryParamKeys.Email],
          [QueryParamKeys.WhatsMobile]: route.queryParams[QueryParamKeys.WhatsMobile],
          [QueryParamKeys.Mobile]: route.queryParams[QueryParamKeys.Mobile]
        },
      });
      return false;
    } 

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/auth/login"]);

    return false;
  }
}
