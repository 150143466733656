import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-google-map-view',
  templateUrl: './google-map-view.component.html',
  styleUrls: ['./google-map-view.component.scss']
})
export class GoogleMapViewComponent implements OnInit {
  @Input() center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  @Input() zoom = 4;
  @Input() height = '300px';
  @Input() width = '100%';
  @Input() enableEdit:boolean = false;
  @Input() markerOptions: google.maps.MarkerOptions;
  @Input() markerPosition: google.maps.LatLngLiteral;
  @Output() change = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if(this.enableEdit){
      this.markerPosition = event.latLng.toJSON();
      this.center = this.markerPosition;
      this.change.emit(this.markerPosition);
      this.zoom = 12;
    }
  }
  
  mapDragend(event: google.maps.MapMouseEvent){
    if(this.markerOptions.draggable){
      this.change.emit(event.latLng.toJSON());
      this.zoom = 12;
    }
  }
}
