<div class="mat-dalog-header bg-primary border-bottom py-5 px-8 mb-8 d-flex justify-content-between" *ngIf="!inAddMode">
    <h6 class="mb-0 text-white font-weight-bolder" mat-dialog-title>
          تعديل قالب  ( {{ msgTemplate?.name }} )
    </h6>
    <div class="end d-flex align-items-center">
        <button mat-button mat-dialog-close class="min-w-auto line-height-normal px-2 popup-close-btn">
            <i class="fas fa-times text-white"></i>
        </button>
    </div>
</div>

<ng-container *ngIf="inAddMode">
    <h6 class="mb-0 font-weight-bolder" mat-dialog-title>
        تعديل قالب  ( {{ msgTemplate?.name }} )
    </h6>
</ng-container>

<mat-dialog-content class="mb-5 px-8">

    <div class="form-group">
        <label class="w-100 text-right font-weight-bolder">
            إسم القالب
            <span class="text-danger">*</span>
        </label>
        <input [(ngModel)]="msgTemplate.name" type="text" class="form-control" name="lng">
    </div>
   
    <ng-container *ngFor="let item of templateContent; let index = index">

        

        <ng-container [ngSwitch]="item?.type">
        

            <ng-container *ngSwitchCase="'text'">
                <div class="section px-8 pt-8 pb-4 mb-10 bg-light rounded normal-msg">
                    <auk-message-area
                        #messageArea 
                        [required]="true"
                        [showTemplates]="false"
                        [isWhatsapp]="true"
                        [showNotes]="false"
                        [showTemplates]="false"
                        [initValue]="item?.body"
                        label="الرسالة:" class="d-block"
                        (messageChange)="item.body = $event?.message"
                    ></auk-message-area>
                </div>
            </ng-container>
            
            
            <ng-container *ngSwitchCase="'location'">
            
                <div class="section px-8 pt-8 pb-4 mb-10 bg-light rounded location-msg">
                    <div class="form-group" dir="ltr">
                        <label class="w-100 text-right text-small font-weight-bolder">
                            العنوان 
                        </label>
                        <input class="form-control" type="text" autocomplete="off" name="address" [(ngModel)]="item.address" >
                    </div>

                    <div class="form-group">
                        <label class="w-100 text-right text-small font-weight-bolder">
                            longitude 
                            <span class="text-danger">*</span>
                        </label>
                        <input type="number" class="form-control" name="lat" [(ngModel)]="item.lat">
                    </div>

                    <div class="form-group">
                        <label class="w-100 text-right text-small font-weight-bolder">
                            latitude 
                            <span class="text-danger">*</span>
                        </label>
                        <input type="number" class="form-control" name="lng" [(ngModel)]="item.lng">
                    </div>
                </div>

            </ng-container>


            <ng-container *ngSwitchCase="'file'">
                <div class="section px-8 pt-8 pb-4 mb-10 bg-light rounded file-msg">
                    <!-- begin:: upload file form  -->
                    <div class="form-group">
                        <label class="w-100 text-right text-small font-weight-bolder">
                            ملف 
                            <span class="text-danger">*</span>
                        </label>
                        <!-- [accept]="acceptedFiles" -->
                        <div class="custom-file cursor-pointer" dir="ltr">
                        <input #file (change)="onFileChange($event, index)" type="file" class="custom-file-input cursor-pointer" id="inputGroupFile04">
                            <label class="custom-file-label cursor-pointer" for="inputGroupFile04">
                                {{ item?.filename }}
                            </label>
                        </div>
                    </div>
                    <!-- end:: upload file form  -->
                    <div *ngIf="!item?.file" class="action d-flex justify-content-end text-small">
                        <button (click)="openFile(item)" type="button" class="btn btn-primary bg-primary py-2 font-weight-bolder">
                            استعراض
                        </button>
                    </div>
                    <auk-message-area 
                        #messageArea 
                        class="d-block"
                        [required]="false"
                        label="وصف الملف:"
                        [showTemplates]="false"
                        [isWhatsapp]="true"
                        [showNotes]="false"
                        [initValue]="item?.caption"
                        (messageChange)="item.caption = $event?.message"
                    ></auk-message-area>
                </div>
            </ng-container>


            <ng-container *ngSwitchCase="'link'">
                <div class="section px-8 pt-8 pb-4 mb-10 bg-light rounded urk-msg">
                    <div class="form-group">
                        <label class="w-100 text-right text-small font-weight-bolder">
                            رابط الملف 
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" name="f_name">
                    </div>
                    <div class="action d-flex justify-content-end text-small">
                        <button type="button" class="btn btn-primary bg-primary py-2 font-weight-bolder">
                            استعراض
                        </button>
                    </div>
                    <auk-message-area [initValue]="item?.caption" 
                    [showTemplates]="false"
                    [isWhatsapp]="true"
                    [showNotes]="false"
                    [required]="false" #messageArea 
                    (messageChange)="message = $event" label="وصف الرابط:" class="d-block"></auk-message-area>
                </div>
            </ng-container>


        </ng-container>

    </ng-container>
</mat-dialog-content>


<mat-dialog-actions align="end" class="px-8 py-3 border-top m-0">
   <div class="d-flex justify-content-between w-100">
        <div class="end">
            <button (click)="onBack()" class="btn font-weight-bolder px-0 py-1" mat-button>
                رجوع            
            </button>
        </div>
        <div class="start">
            <button mat-dialog-close class="btn font-weight-bolder px-10 py-1" mat-button *ngIf="!inAddMode">
                إلغاء            
            </button>
            <button (click)="submit()" class="btn btn-primary font-weight-bolder px-15 py-1" mat-button>
                تعديل        
            </button>
        </div>
   </div>
</mat-dialog-actions>