import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Inject, Injectable } from '@angular/core';
import { filter, map, mergeMap } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class DynamicServiceService {

  fullTitle: string = '4Jawaly ';
  title: string = '4Jawaly '

  constructor(
    private _Router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private dom,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * @param {string} title 
   * @description A function that updates page title
   */
  updatePageTitle(title: string) {
    this.titleService.setTitle(title ? title + ' | ' + this.title : this.title);
  }

  /**
   * @description Updating Page title dynamically
   */
  UpdatingPageTitleDynamically(title?: string) {
    if (!title) {
      this._Router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) { route = route.firstChild; }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
            this.titleService.setTitle(event['pageTitle'] ? event['pageTitle'] : this.fullTitle);
            // this.titleService.setTitle(event['pageTitle'] ? this.title + ' | ' + event['pageTitle'] : this.fullTitle);
          });
    } else {
      this.titleService.setTitle(this.fullTitle);
    }
  }
}
