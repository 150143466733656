import { Component, Input, OnInit } from '@angular/core';
import { TemplateButtonsTypes } from "src/app/modules/whats-app/enums/whats-templates";

@Component({
  selector: 'app-official-buttons-preview',
  templateUrl: './official-buttons-preview.component.html',
  styleUrls: ['./official-buttons-preview.component.scss']
})
export class OfficialButtonsPreviewComponent implements OnInit {
  btnTypes = TemplateButtonsTypes;
  @Input() buttons: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
