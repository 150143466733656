// Routes
export enum SettingsRoutingEnum {
  Settings = "settings",
  SettingsHome = "home",
  UserBills = "user-bills",
  SmsBalanceMovement = "sms-balance-movement",
  PaymentSettings = "payment-settings",
  SubAccountsSettings = "sub-accounts-settings",
  SubAccountsNeeds = "sub-accounts-needs",
}

export enum AuthRoute {
  Login = "login",
  ActiveCode = "active-code",
  ForgotPassword = "forgot-password",
  LoggingInIssues = "logging-in-issues",
  GoogleAuth = "google-auth",
  AddDevice = "add-new-device",
  AddDeviceActiveCode = "add-new-device-active",
}

export enum MainLayoutRoutingEnum {
  Dashboard = "dashboard",
  Auk = "auk",
  Senders = "senders",
  Whats = "whats",
  CartStore = "cart-store",
  StartYourProject = "start-your-project",
  GoogleSheet = "google-sheet",
  WordPress = "wordpress",
  Make = "make",
  CRM = "crm",
  Settings = "settings",
  ERP = "erp",
  Host = "host",
  BuildInPrograms = "build-in-programs",
  FinancialSystem = "financial-system",
  User = "user",
  ComingSoon = "coming-soon",
  NotValidIP = "not-valid-ip",
  Notifications ="notifications"
}

export enum SmsRoutingEnum {
  SmsHome = "home",
  SmsHomeService = "services",
  List = "list",
  ListAddSender = "list/add-sender",
  ListAddSenderById = "list/add-sender/:id",
  Support = "support",
  AddSupport = "add-support",
  StripeSuccess = "stripe-success",
  StripeError = "stripe-error",
  Packages = "packages",
  SendMessage = "send-message",
  SendTrialMessage = "send-trial-message",
  ExpectedCustomers = "expected-customers",
  ExpectedCustomersAddCustomerById = "expected-customers/add-customer/:id",
  MessagesArchives = "messages-archives",
  MessagesArchivesNeedConfirm = "messages-archives-need-confirm",
  MessageDetailedArchivesById = "message-detailed-archives/:id",
  SalesRepresentatives = "sales-representatives",
  SubUsersSettings = "sub-accounts-settings",
  MessagesAndAlerts = "messages-and-alerts",
  SMSSettings = "sms-settings",
  News = "news",
  SpecialOffers = "special-offers",
}

export enum WhatsAppPageURLs {
  Home = "home",
  WhatsHomeService = "services",
  AddNumber = "add-number",
  BuyProducts = "buy-products",
  MessageArchive = "message-archive",
  Spooling = "spooling",
  ConsumptionReport = "consumption-report",
  NumberGroups = "number-groups",
  Packages = "packages",
  List = "list",
  AddPackageNumber = "add-package-number",
  PaymentMethods = "payment-methods",
  Pay = "pay",
  ConfirmPay = "confirm-pay",
  StripeSuccess = "stripe-success",
  StripeError = "stripe-error",
  SendMessage = "number-settings",
  SendMessageV2 = "send-message-v2",
  SendMessageById = "number-settings/:id",
  SendMessageNew = "send-message-new",
  AddNumberNewProject = "add-number/new-project",
  Empty = "",
  ExpectedCustomers = "expected-customers",
  support = "support",
  contactOwner = "whats/home/contact-owner",
  ExpectedCustomersAddCustomer = "expected-customers/add-customer",
  ExpectedCustomersAddCustomerById = "expected-customers/add-customer/:id",
  MessageDetailedArchivesById = "message-detailed-archives/:id",
  ServicesSettings = "services-settings",
}

export enum UserPageURLs {
  TelegramNotification = "notifications",
  UserInformation = "user-information",
  ApiToken = "api-token",
  UserSecurity = "user-security",
  UserRecords = "user-records",
  AdsSettings = "ads-settings",
  UserDevices = "devices",
}

export enum GuestPageURLs {
  NewUser = "new_user",
  NewUserSuccess = "new_user/success",
  GuestAddContract = "add-contract",
}

export enum MakePageURLs {
  home = "home",
  support = "support",
  start = "start",
}

// Paths

export enum RoutingPathsEnum {
  Dashboard = "/dashboard",
  // Auth
  Auth = "/auth",
  Login = "/auth/login",
  ActiveCode = "/active-code",
  ForgotPassword = "/forgot-password",
  LoggingInIssues = "/auth/logging-in-issues",
  GoogleAuth = "/google-auth",
  // Gust Routes
  Gust = "/guest",
  GustHome = "/gust/home",
  // Settings
  Settings = "/settings",
  SettingsHome = "/settings/home",
  UserBills = "/settings/home/user-bills",
  SmsBalanceMovement = "/settings/home/sms-balance-movement",
  PaymentSettings = "/settings/home/payment-settings",
  SubAccountsSettings = "/settings/home/sub-accounts-settings",
  SettingsSubAccountsNeeds = "/settings/home/sub-accounts-needs",
  // SMS
  SmsSenders = "/senders/home/services",
  SmsHome = "/senders/home",
  SmsList = "/senders/home/list",
  SmsListAddSender = "/senders/home/list/add-sender/:id",
  SmsListAddNewSender = "/senders/home/list/add-sender",
  SmsSupport = "/senders/home/support",
  SmsAddSupport = "/senders/home/support/add-support",
  SmsStripeSuccess = "/senders/home/support/stripe-success",
  SmsStripeError = "/senders/home/support/stripe-error",
  SmsPackages = "/senders/home/packages",
  SmsSendMessage = "/senders/home/send-message",
  SmsSendTrialMessage = "/senders/home/send-trial-message",
  SmsExpectedCustomers = "/senders/home/expected-customers",
  SmsExpectedCustomersAddCustomerById = "/senders/home/expected-customers/add-customer/:id",
  SmsMessagesArchives = "/senders/home/messages-archives",
  SmsMessagesArchivesNeedConfirm = "/senders/home/messages-archives-need-confirm",
  SmsMessageDetailedArchivesById = "/senders/home/message-detailed-archives/:id",
  SmsSalesRepresentatives = "/senders/home/sales-representatives",
  SmsMessagesAndAlerts = "/senders/home/messages-and-alerts",
  SmsSMSSettings = "/senders/home/sms-settings",
  SmsUbUsersSettings = "/senders/home/sub-accounts-settings",
  SmsNews = "/senders/home/news",
  SmsSpecialOffers = "/senders/home/special-offers",
  // Whats
  WhatsHome = "/whats",
  WhatsHomeServices = "/whats/home/services",
  WhatsTickets = "/whats/home/support",
  WhatsAddTicket = "/whats/home/support/add-support",
  // Make
  MakeHome = "/make",
  MakeStart = "/make/home/start",
  MakeTickets = "/make/home/support",
  MakeAddTicket = "/make/home/support/add-support",

  // User
  UserTelegramNotification = "/user/notifications",
  UserInformation = "/user/user-information",
  UserApiToken = "/user/api-token",
  UserSecurity = "/user/user-security",
  UserRecordsAndContracts = "/user/user-records",
  UserAdsSettings = "/user/ads-settings",
  UserDevices = "/user/devices",
  // Sallah
  SallahHome = "/cart-store",
  // Google Sheet
  GoogleSheetHome = "/google-sheet",
  GoogleSheetAddFile = "/google-sheet/home/add-permission",
  // WordPress
  WordPressHome = "/wordpress",
  wordPressSupport = "/wordpress/support",
  wordPressSupportAddTicket = "/wordpress/support/add-support",
  hostSupportAddTicket = "/host/home/support/add-support",

  // Guest
  Guest = "/guest",
  GuestAddInvitation = "/guest/new_user",
  GuestAddInvitationSuccess = "/guest/new_user/success",
}

//  Routing Paths Data
export enum RoutingData {
  // SMS
  NeedConfirm = "needConfirm",
}
